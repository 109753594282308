<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" name="lancamentoFinanceiro" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando Dados...</p>
</ngx-spinner>

<div *ngIf="!telaFormulario" class="content d-flex justify-content-center align-items-center">
  <form class="" #telainicial="ngForm" [formGroup]="formTelaInicial">
    <div class="card bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0"><i class="fa fa-pencil-square-o 2x mr-1"></i>Lançamento Financeiro</h5>
        </div>
        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label class="justify-content-start">Matrícula</label>
          <input
            type="text"
            placeholder="Matrícula"
            class="form-control"
            mask="0*"
            formControlName="matricula"
            maxlength="10"
            [ngClass]="
              formTelaInicial.controls['matricula'].status == 'INVALID' && validaTelaInicial
                ? 'is-invalid'
                : null
            "
          />
          <span
            class="form-text text-danger"
            *ngIf="formTelaInicial.get('matricula').invalid && validaTelaInicial"
          >
            Campo Obrigatório
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label>Tipo de Processamento </label>
          <select
            class="form-control form-control-uniform"
            (change)="validarProcessamentoRescisao($event)"
            (keydown)="buscarEnter($event)"
            formControlName="tipoProcessamento"
            name="tipoProcessamento"
            required
          >
            <option value="">Selecione...</option>
            <option *ngFor="let tp of tipoProcessamento" value="{{ tp.Codigo }}">
              {{ tp.Descricao }} - {{ tp.DtComp | converterData }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="formTelaInicial.get('tipoProcessamento').invalid && validaTelaInicial"
          >
            Campo Obrigatório
          </span>
        </div>

        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group" *ngIf="exibeData">
          <label class="justify-content-start">Competência</label>
          <input
            type="text"
            placeholder="mm/aaaa"
            class="form-control"
            mask="00/0000"
            formControlName="competenciaRS"
            [ngStyle]="
              formTelaInicial.controls['competenciaRS'].status == 'INVALID' && validaTelaInicial
                ? { 'border-color': 'red' }
                : null
            "
          />
          <span
            class="form-text text-danger"
            *ngIf="formTelaInicial.get('competenciaRS').invalid && validaTelaInicial"
          >
            Campo Obrigatório
          </span>
          <span
            class="form-text text-danger"
            *ngIf="
              formTelaInicial.value.competenciaRS.length > 0 &&
              formTelaInicial.get('competenciaRS').hasError('competenciaformRSError')
            "
          >
            Competência inválido
          </span>
        </div>
        <div class="text-center">
          <button type="button" (click)="buscarCompetencia()" class="btn btn-primary">
            Buscar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="content clearfix">
  <div *ngIf="telaFormulario" class="card form-group">
    <form
      #lancamentoFina="ngForm"
      [formGroup]="formLancamentoFin"
      novalidade
      action=""
      autocomplete="off"
      class="form-group"
    >
      <div class="card-body">
        <!-- <div class="row my-2"> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <h3 class="font-size-md">
            <i
              class="fa fa-arrow-left pr-3 btn"
              aria-hidden="true"
              (click)="retornarTelaInicial()"
              style="cursor: pointer; bottom: 2px"
            ></i
            >Lançamento Financeiro
          </h3>
        </div>
        <div class="row align-items-end">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 form-group">
            <label class="justify-content-start">Matrícula</label>
            <input
              type="text"
              [(ngModel)]="matricula"
              class="form-control"
              readonly
              formControlName="matricula"
              name="matricula"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-8 form-group">
            <label class="justify-content-start">Nome</label>
            <input type="text" class="form-control" readonly formControlName="nome" name="nome" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 form-group">
            <label class="justify-content-start">Vínculo</label>
            <input
              type="text"
              class="form-control"
              readonly
              formControlName="vinculo"
              name="vinculo"
            />
          </div>
          <div
            class="col-xs-12 col-sm-12 form-group"
            [ngClass]="modoEdicao ? ' col-md-2 col-lg-2' : 'col-md-4 col-lg-4'"
          >
            <label class="justify-content-start">Situação</label>
            <input
              type="text"
              [(ngModel)]="situacao"
              class="form-control"
              readonly
              formControlName="situacao"
              name="situacao"
            />
          </div>
          <div
            class="col-xs-12 col-sm-12 form-group"
            [ngClass]="modoEdicao ? ' col-md-2 col-lg-2' : 'col-md-4 col-lg-4'"
          >
            <label class="justify-content-start">Competência</label>
            <input
              type="text"
              [(ngModel)]="competenciaTela"
              class="form-control"
              readonly
              formControlName="competencia"
              name="competencia"
            />
          </div>
          <div
            class="col-xs-12 col-sm-12 form-group mb-3"
            [ngClass]="modoEdicao ? ' col-md-2 col-lg-2' : 'col-md-4 col-lg-4'"
          >
            <label class="justify-content-start">Tipo de processamento</label>
            <input
              type="text"
              [(ngModel)]="processamento"
              class="form-control"
              readonly
              formControlName="tpProcessamento"
              name="tpProcessamento"
            />
          </div>
          <div *ngIf="modoEdicao" class="col-xs-12 col-sm-12 col-md-4 col-lg-5 form-group">
            <label>Verba</label>
            <small class="ml-2 text-muted" *ngIf="lancamentoFin.Referencia">
              {{ lancamentoFin.Referencia }}
            </small>
            <select
              class="form-control form-control-uniform"
              [ngClass]="
                (formLancamentoFin.get('verba').touched || validaLancamentoFin) &&
                formLancamentoFin.controls['verba'].status == 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              [attr.disabled]="modoEdicao == true ? true : null"
              formControlName="verba"
              [(ngModel)]="lancamentoFin.CodigoVerba"
              name="verba"
              required
            >
              <option value="">Selecione...</option>
              <option *ngFor="let vrb of verbas" value="{{ vrb.Codigo }}">
                {{ vrb.Codigo }} - {{ vrb.Nome }} -
                {{ pegarTipoVerba(vrb.Vantagem) }}
              </option>
            </select>

            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('verba').touched || validaLancamentoFin) &&
                formLancamentoFin.get('verba').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div *ngIf="modoEdicao" class="col-xs-12 col-sm-12 col-md-1 col-lg-1 form-group mb-3">
            <label class="justify-content-start">Transação</label>
            <input
              [ngClass]="
                (formLancamentoFin.get('transacao').touched || validaLancamentoFin) &&
                formLancamentoFin.get('transacao').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              (change)="verificaTransacaoValida()"
              type="text"
              mask="0*"
              maxlength="2"
              placeholder="00"
              type="text"
              class="form-control"
              [(ngModel)]="lancamentoFin.Transacao"
              formControlName="transacao"
              name="transacao"
              [readonly]="modoEdicao"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('transacao').touched || validaLancamentoFin) &&
                formLancamentoFin.get('transacao').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
        <div class="row mt-3 pt-3 border-top align-items-start">
          <div *ngIf="!modoEdicao" class="col-xs-12 col-sm-12 col-md-4 col-lg-3 form-group">
            <label>Verba</label>
            <select
              class="form-control form-control-uniform"
              [ngClass]="
                (formLancamentoFin.get('verba').touched || validaLancamentoFin) &&
                formLancamentoFin.controls['verba'].status == 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              [attr.disabled]="modoEdicao == true ? true : null"
              formControlName="verba"
              [(ngModel)]="lancamentoFin.CodigoVerba"
              name="verba"
              required
            >
              <option value="">Selecione...</option>
              <option *ngFor="let vrb of verbas" value="{{ vrb.Codigo }}">
                {{ vrb.Codigo }} - {{ vrb.Nome }} -
                {{ pegarTipoVerba(vrb.Vantagem) }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('verba').touched || validaLancamentoFin) &&
                formLancamentoFin.get('verba').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div *ngIf="!modoEdicao" class="col-xs-12 col-sm-12 col-md-4 col-lg-3 form-group">
            <label class="justify-content-start">Transação</label>
            <input
              [ngClass]="
                (formLancamentoFin.get('transacao').touched || validaLancamentoFin) &&
                formLancamentoFin.get('transacao').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              (change)="verificaTransacaoValida()"
              type="text"
              mask="0*"
              maxlength="2"
              placeholder="00"
              type="text"
              class="form-control"
              [(ngModel)]="lancamentoFin.Transacao"
              formControlName="transacao"
              name="transacao"
              [readonly]="modoEdicao"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('transacao').touched || validaLancamentoFin) &&
                formLancamentoFin.get('transacao').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-3 form-group">
            <label class="justify-content-start">Parcelas </label>
            <input
              [ngClass]="
                (formLancamentoFin.get('parcelas').touched || validaLancamentoFin) &&
                formLancamentoFin.get('parcelas').status == 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              type="text"
              mask="0*"
              maxlength="3"
              class="form-control"
              placeholder="000"
              [(ngModel)]="lancamentoFin.Parcelas"
              formControlName="parcelas"
              name="parcelas"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('parcelas').touched || validaLancamentoFin) &&
                formLancamentoFin.get('parcelas').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-3 form-group">
            <label class="justify-content-start">Pagas</label>
            <input
              [ngClass]="
                (formLancamentoFin.get('pagas').touched || validaLancamentoFin) &&
                formLancamentoFin.get('pagas').status == 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              type="text"
              mask="0*"
              maxlength="3"
              class="form-control"
              placeholder="000"
              formControlName="pagas"
              name="pagas"
              [(ngModel)]="lancamentoFin.ParcelasOcorridas"
            />
          </div>

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group">
            <label class="justify-content-start">Valor </label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">R$</span>
              </div>
              <input
                [ngClass]="
                  (formLancamentoFin.get('valor').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('valor').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                currencyMask
                [options]="{
                  prefix: '',
                  thousands: '.',
                  decimal: ',',
                  align: 'left'
                }"
                type="text"
                maxlength="10"
                class="form-control"
                [(ngModel)]="lancamentoFin.ValorInformado"
                formControlName="valor"
                name="valor"
                placeholder="0,00"
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('valor').touched || validaLancamentoFin) &&
                formLancamentoFin.get('valor').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 form-group">
            <label class="justify-content-start">Tipo de Referência </label>
            <select
              class="form-control form-control-uniform"
              [ngClass]="
                (formLancamentoFin.get('tipoDeReferencia').touched || validaLancamentoFin) &&
                formLancamentoFin.get('tipoDeReferencia').status == 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              formControlName="tipoDeReferencia"
              [(ngModel)]="lancamentoFin.TipoReferencia"
              name="tipoDeReferencia"
              (change)="onTipoRefChange()"
              required
            >
              <option value="undefined">Selecione...</option>
              <option value="0">SEM REFERÊNCIA</option>
              <option value="1">VALOR DE EXCEÇÃO</option>
              <option value="2">NÍVEL</option>
              <option value="3">HORA</option>
              <option value="4">QUANTIDADE</option>
              <option value="5">DIAS</option>
              <option value="6">VALOR</option>
              <option value="7">DEVOLUÇÃO</option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('tipoDeReferencia').touched || validaLancamentoFin) &&
                formLancamentoFin.get('tipoDeReferencia').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 form-group">
            <label class="justify-content-start">
              Empréstimo Programado <small>(opcional)</small></label
            >
            <input
              type="text"
              class="form-control"
              mask="0*"
              [(ngModel)]="lancamentoFin.Devolucao"
              maxlength="1"
              formControlName="devolucao"
              name="devolucao"
              placeholder="0"
            />
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 form-group">
            <label>Nível </label>
            <select
              class="form-control form-control-uniform"
              [ngClass]="
                (formLancamentoFin.get('tipoDeReferencia').touched || validaLancamentoFin) &&
                formLancamentoFin.get('nivel').status == 'INVALID'
                  ? 'is-invalid'
                  : null
              "
              formControlName="nivel"
              [(ngModel)]="lancamentoFin.CodigoNivel"
              name="nivel"
              required
            >
              <option value="">Selecione...</option>
              <option *ngFor="let niv of niveis" value="{{ niv.CodigoNivel }}">
                {{ niv.CodigoNivel }}-{{ niv.DescricaoNivel }} -
                {{ niv.ValorNivel }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('nivel').touched || validaLancamentoFin) &&
                formLancamentoFin.get('nivel').getError('required')
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div
            [ngClass]="modoEdicao ? ' col-md-12' : 'col-md-6'"
            class="col-xs-12 col-sm-12 form-group mb-3"
          >
            <label class="justify-content-start"> Transação Sobreposta </label>
            <input
              [ngClass]="
                (formLancamentoFin.get('transacaoSobreposta').touched || validaLancamentoFin) &&
                formLancamentoFin.get('transacaoSobreposta').status == 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
              mask="0*"
              type="text"
              class="form-control"
              [(ngModel)]="lancamentoFin.TransacaoSobreposta"
              maxlength="2"
              formControlName="transacaoSobreposta"
              name="transacaoSobreposta"
              placeholder="00"
              (change)="verificaTransacaoSobreposta($event)"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (formLancamentoFin.get('transacaoSobreposta').touched || validaLancamentoFin) &&
                formLancamentoFin.get('transacaoSobreposta').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <ng-container *ngIf="f07 == '1'">
            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start"> Exercício </label>
              <input
                [ngClass]="
                  (formLancamentoFin.get('exercicio').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('exercicio').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                type="text"
                class="form-control"
                [(ngModel)]="lancamentoFin.RRA.Exercicio"
                maxlength="4"
                formControlName="exercicio"
                name="exercicio"
                placeholder="Exercício"
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('exercicio').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('exercicio').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start"> Nº Processo </label>
              <input
                [ngClass]="
                  (formLancamentoFin.get('numProcesso').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('numProcesso').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                type="text"
                class="form-control"
                [(ngModel)]="lancamentoFin.RRA.NumProcesso"
                formControlName="numProcesso"
                style="text-transform: uppercase"
                name="numProcesso"
                placeholder="000"
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('numProcesso').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('numProcesso').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start" for="natRendimento"> Rendimento </label>
              <select
                [ngClass]="
                  (formLancamentoFin.get('natRendimento').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('natRendimento').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                class="form-control form-control-uniform"
                name="natRendimento"
                id="natRendimento"
                formControlName="natRendimento"
                [(ngModel)]="lancamentoFin.RRA.NatRendimento"
              >
                <option value="">Selecione...</option>
                <option *ngFor="let rend of listaRendimentos" [value]="rend">
                  {{ rend }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('natRendimento').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('natRendimento').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start"> Percentual Mês Inicial </label>
              <input
                [ngClass]="
                  (formLancamentoFin.get('percDirMesInicial').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('percDirMesInicial').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                type="text"
                currencyMask
                [options]="{
                  prefix: '',
                  suffix: '%',
                  thousands: '',
                  decimal: ',',
                  align: 'left'
                }"
                maxlength="7"
                (keyup)="limitePorcentagem('PercDirMesInicial')"
                class="form-control"
                [(ngModel)]="lancamentoFin.RRA.PercDirMesInicial"
                formControlName="percDirMesInicial"
                name="percDirMesInicial"
                placeholder="00,00%"
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('percDirMesInicial').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('percDirMesInicial').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start"> Percentual Informe </label>
              <input
                [ngClass]="
                  (formLancamentoFin.get('percInforme').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('percInforme').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                type="text"
                currencyMask
                [options]="{
                  prefix: '',
                  suffix: '%',
                  thousands: '',
                  decimal: ',',
                  align: 'left'
                }"
                maxlength="7"
                (keyup)="limitePorcentagem('PercInforme')"
                class="form-control"
                [(ngModel)]="lancamentoFin.RRA.PercInforme"
                formControlName="percInforme"
                name="percInforme"
                placeholder="00,00%"
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('percInforme').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('percInforme').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start"> Quantidade Mês Informe </label>
              <input
                [ngClass]="
                  (formLancamentoFin.get('qtdMesInforme').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('qtdMesInforme').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                type="text"
                class="form-control"
                [(ngModel)]="lancamentoFin.RRA.QtdMesInforme"
                formControlName="qtdMesInforme"
                name="qtdMesInforme"
                placeholder="000"
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('qtdMesInforme').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('qtdMesInforme').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start">Valor Global</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">R$</span>
                </div>
                <input
                  [ngClass]="
                    (formLancamentoFin.get('valorGlobal').touched || validaLancamentoFin) &&
                    formLancamentoFin.get('valorGlobal').status == 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                  type="text"
                  currencyMask
                  [options]="{
                    prefix: '',
                    thousands: '.',
                    decimal: ',',
                    align: 'left'
                  }"
                  maxlength="10"
                  class="form-control"
                  [(ngModel)]="lancamentoFin.RRA.ValorGlobal"
                  formControlName="valorGlobal"
                  name="valorGlobal"
                  placeholder="0,00"
                />
              </div>
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('valorGlobal').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('valorGlobal').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start"> Objeto Processo </label>
              <input
                [ngClass]="
                  (formLancamentoFin.get('objetoProcesso').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('objetoProcesso').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                type="text"
                class="form-control"
                [(ngModel)]="lancamentoFin.RRA.ObjetoProcesso"
                formControlName="objetoProcesso"
                style="text-transform: uppercase"
                name="objetoProcesso"
                placeholder="Objeto"
                maxlength="16"
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('objetoProcesso').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('objetoProcesso').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 form-group mb-3">
              <label class="justify-content-start"> Origem Processo </label>
              <select
                [ngClass]="
                  (formLancamentoFin.get('origemProcesso').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('origemProcesso').status == 'INVALID'
                    ? 'is-invalid'
                    : ''
                "
                class="form-control form-control-uniform"
                name="origemProcesso"
                id="origemProcesso"
                formControlName="origemProcesso"
                [(ngModel)]="lancamentoFin.RRA.OrigemProcesso"
              >
                <option value="">Selecione...</option>
                <option *ngFor="let origem of listaOrigemProcesso" [value]="origem.Codigo">
                  {{ origem.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  (formLancamentoFin.get('origemProcesso').touched || validaLancamentoFin) &&
                  formLancamentoFin.get('origemProcesso').hasError('required')
                "
              >
                Campo Obrigatório
              </span>
            </div>
          </ng-container>
        </div>

        <div class="d-flex justify-content-end">
          <div *ngIf="incluido" class="form-group text-right mb-2">
            <button type="button" (click)="salvarLancamento()" class="mr-2 btn btn-primary">
              Incluir
            </button>
          </div>
          <div *ngIf="substituido" class="form-group text-right mb-2">
            <button type="button" (click)="cancelar()" class="btn btn-danger mr-2">Cancelar</button>
            <button type="button" (click)="atualizarLancamentoFinanceiro()" class="btn btn-primary">
              Alterar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="telaFormulario" class="card">
    <div class="card-header">
      <div class="form-group header-elements-inline">
        <h3 class="card-title">Lista de Lançamentos</h3>
        <div class="header-elements"></div>
      </div>
    </div>
    <div class="input-group col-lg-12 mb-2">
      <input
        type="text"
        class="form-control"
        mask="0*"
        id="idSearchText"
        name="searchText"
        value=""
        placeholder="Pesquisar por verba"
        (keypress)="somenteNumero($event)"
        (keyup)="filter = $event"
      />
      <span class="input-group-append">
        <span class="input-group-text"><i class="fa fa-search"></i></span>
      </span>
    </div>
    <div class="col-lg-12 form-group text-center mt-3">
      <div class="table-responsive" style="max-height: 45vh">
        <table class="table table-striped table-hover mb-0 col-lg-12">
          <thead>
            <tr>
              <th>Sequencial</th>
              <th>Matrícula</th>
              <th>Verba</th>
              <th>Transação</th>
              <th>Parcelas/Pagas</th>
              <th>Valor</th>
              <th>Referência</th>
              <th>Editar/Excluir</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let lan of objetolancamento | filtrarVerba : filter; let i = index"
              class="text-center"
              style="white-space: nowrap"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ lan.Matricula }}</td>
              <td>{{ lan.CodigoVerba }}</td>
              <td>{{ lan.Transacao }}</td>
              <td>{{ lan.Parcelas }}/{{ lan.ParcelasOcorridas }}</td>
              <td>{{ lan.ValorInformado }}</td>
              <td>{{ lan.Referencia ? lan.Referencia : 'Não informada' }}</td>

              <td>
                <div>
                  <button
                    type="button"
                    class="btn bg-teal-400 mr-2"
                    (click)="editarLancamento(lan)"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                    *ngIf="lan.RRA"
                    type="button"
                    [ngClass]="{
                      'btn-outline-primary': lan.Obs === 'N',
                      'btn-primary': lan.Obs === 'S'
                    }"
                    class="btn mr-2"
                    data-toggle="modal"
                    data-target="#observacoesLancamentoFinanceiro"
                    (click)="modalObservacoes.abrirModal(lan)"
                  >
                    <i class="fa-solid fa-book fa-lg"></i>
                  </button>
                  <button
                    type="button"
                    [ngClass]="{
                      'btn-outline-primary': lan.Documento === 'N',
                      'btn-primary': lan.Documento === 'S'
                    }"
                    class="btn mr-2"
                    data-toggle="modal"
                    data-target="#anexoLancamentoFinanceiroModal"
                    (click)="modalAnexo.abrirModal(lan)"
                  >
                    <i class="fa fa-file"></i>
                  </button>
                  <button type="button" class="btn btn-danger" (click)="removerLancamento(i, lan)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="observacoesLancamentoFinanceiro" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        (gatilhoAtivado)="buscarLancamentos(matricula)"
        [colaborador]="{ Matricula: matricula }"
        tipo="050"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>

<div class="modal fade" id="anexoLancamentoFinanceiroModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-anexo
        (gatilhoAtivado)="buscarLancamentos(matricula)"
        pagina="208"
        [matricula]="+matricula"
        [enviarSeparadamente]="true"
        [modal]="true"
      ></app-anexo>
    </div>
  </div>
</div>
