// config.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private data: any;
  private path = '../../../config.json';
  constructor(private httpClient: HttpClient) {
    this.getJSON().subscribe((data) => {
      console.log(data);
    });
  }
  public getJSON(): Observable<any> {
    return this.httpClient.get(this.path);
  }
  setConfig(): Observable<any> {
    return this.httpClient.get(this.path).pipe(
      tap((response: any) => {
        this.data = response;
      })
    );
  }

  getData(): any {
    return this.data;
  }
}
