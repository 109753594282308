import { ConfigService } from './../config/config.service';
import { Injectable } from '@angular/core';

// const url = require('../config/config');

@Injectable({
  providedIn: 'root',
})
export class AcessoService {
  url_link: string = window.location.href;
  url: { homolog: string; localhost: string; api: string };

  constructor(private configService: ConfigService) {
    this.url = this.configService.getData();
  }

  public validarUrl() {
    if (this.url_link.indexOf('homologa') != -1) {
      return this.url.homolog;
    } else if (this.url_link.indexOf('localhost') != -1) {
      return this.url.localhost;
    } else {
      return this.url.api;
    }
  }

  public verificaMobile() {
    var userAgent = navigator.userAgent.toLowerCase();
    var devices = new Array(
      'nokia',
      'iphone',
      'blackberry',
      'sony',
      'lg',
      'htc_tattoo',
      'samsung',
      'symbian',
      'SymbianOS',
      'elaine',
      'palm',
      'series60',
      'windows ce',
      'android',
      'obigo',
      'netfront',
      'openwave',
      'mobilexplorer',
      'operamini'
    );

    for (var i = 0; i < devices.length; i++) {
      if (userAgent.search(devices[i]) > 0) {
        return true;
      }
    }
    return false;
  }
}
