import { EsocialComponent } from './componentes/esocial/esocial/esocial.component';
import { PensaoAlimenticiaComponent } from './componentes/pensao-alimenticia/pensao-alimenticia.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxEchartsModule } from 'ngx-echarts';
import { OrderModule } from 'ngx-order-pipe';
import { SearchFilterPipe } from './componentes/profile/filter-pipe';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoginComponent } from './componentes/login/login.component';
import { CadastroComponent } from './componentes/cadastro/cadastro.component';
import { ProfileComponent } from './componentes/profile/profile.component';
import { SideMenuComponent } from './componentes/side-menu/side-menu.component';
import { TopMenuComponent } from './componentes/top-menu/top-menu.component';
import { MainComponent } from './componentes/main/main.component';
import { TransferenciaFuncionarioComponent } from './componentes/transferencia-funcionario/transferencia-funcionario.component';
import { ColaboradoresComponent } from './componentes/colaboradores/colaboradores.component';
import { ColaboradoresFiltroPipe } from './componentes/colaboradores/colaboradores-filtro.pipe';
import { ParametroFiltroPipe } from './componentes/parametros/parametro-filtro.pipe';
import { PesquisaPipe } from './componentes/processos/processos-pipe';
import { ErroComponent } from './componentes/erro/erro.component';
import { ScatComponent } from './componentes/scat/scat.component';
import { FeriasComponent } from './componentes/ferias/ferias.component';
import { RelatoriosComponent } from './componentes/relatorios/relatorios.component';
import { GerencialComponent } from './componentes/gerencial/gerencial.component';
import { RecrutamentoComponent } from './componentes/recrutamento/recrutamento.component';
import { RescisaoComponent } from './componentes/rescisao/rescisao.component';
// import { ModalDuvidasComponent } from './componentes/modal-duvidas/modal-duvidas.component';
import { UploadComponent } from './componentes/upload/upload.component';
import { CadastroPendenteComponent } from './componentes/cadastro-pendente/cadastro-pendente.component';
import { ExcelService } from './service/arquivo/excel.service';
import { ContinuacaoCadastroComponent } from './componentes/continuacao-cadastro/continuacao-cadastro.component';
import { CancelarRescisaoComponent } from './componentes/cancelar-rescisao/cancelar-rescisao.component';
import { QuadroBeneficiosComponent } from './componentes/beneficios/quadro-beneficios/quadro-beneficios.component';
import { RegrasBeneficioComponent } from './componentes/beneficios/regras-beneficio/regras-beneficio.component';
import { EdicaoBeneficioComponent } from './componentes/beneficios/regras-beneficio/edicao-beneficio/edicao-beneficio.component';
import { TipoBeneficiarioComponent } from './componentes/beneficios/tipo-beneficiario/tipo-beneficiario.component';
import { GrupoBeneficioComponent } from './componentes/beneficios/grupo-beneficio/grupo-beneficio.component';
import { CadastroBeneficioComponent } from './componentes/beneficios/quadro-beneficios/cadastro-beneficio/cadastro-beneficio.component';
import { RelatorioSinteticoComponent } from './componentes/beneficios/relatorio-sintetico/relatorio-sintetico.component';
import { LancamentoFinanceiroComponent } from './componentes/lancamento-financeiro/lancamento-financeiro.component';
import { FiltroPipe } from './componentes/beneficios/regras-beneficio/filtro.pipe';
import { CadastroPublicoComponent } from './componentes/cadastro-publico/cadastro-publico.component';
import { SalarioComponent } from './componentes/profile/componentes/salario/salario.component';
import { EnderecoComponent } from './componentes/profile/componentes/endereco/endereco.component';
import { FeriasPerfilComponent } from './componentes/profile/componentes/ferias-perfil/ferias-perfil.component';
import { DadosPessoaisComponent } from './componentes/profile/componentes/dados-pessoais/dados-pessoais.component';
import { SituacaoFuncionalComponent } from './componentes/situacao-funcional/situacao-funcional.component';
import { ProcessosComponent } from './componentes/processos/processos.component';
import { AnexoComponent } from './componentes/anexo/anexo.component';
import { RelatoriosPerfilComponent } from './componentes/profile/componentes/relatorios-perfil/relatorios-perfil.component';
import { ContrachequeComponent } from './componentes/contracheque/contracheque.component';
import { MigracaoComponent } from './componentes/migracao/migracao.component';
import { PensaoPrevidenciariaComponent } from './componentes/pensao-previdenciaria/pensao-previdenciaria.component';
import { RepresentanteLegalComponent } from './componentes/representante-legal/representante-legal.component';
import { TelefoneMaskDirective } from './service/mask/telefoneCelular';
import { DependentesComponent } from './componentes/dependentes/dependentes.component';
import { ParametrosComponent } from './componentes/parametros/parametros.component';
import { SituacaoFuncionalFalecimentoComponent } from './componentes/situacao-funcional-falecimento/situacao-funcional-falecimento.component';
import { CadastroTipoUsuarioComponent } from './componentes/pse/cadastro-tipo-usuario/cadastro-tipo-usuario.component';
import { situacaoFeriasFiltroPipe } from './componentes/ferias/situacao-ferias.pipe';
import { CpfPipe } from './service/pipes/cpf.pipe';
import { CnpjPipe } from './service/pipes/cnpj.pipe';
import { FiltroTextPipe } from './service/pipes/filtroTexto.pipe';
import { pesquisaFeriasPipe } from './componentes/ferias/pesquisa-ferias.pipe';
import { PseColaboradoresComponent } from './componentes/pse/pse-colaboradores/pse-colaboradores.component';
import { CadastroCessaoComponent } from './componentes/complemento-cadastral/cadastro-cessao/cadastro-cessao.component';
import { CadastroEstagioComponent } from './componentes/complemento-cadastral/cadastro-estagio/cadastro-estagio.component';
import { CadastroContratoExperienciaComponent } from './componentes/complemento-cadastral/cadastro-contrato-experiencia/cadastro-contrato-experiencia.component';
import { PsePermisaoEmpresaFilialComponent } from './componentes/pse/pse-permisao-empresa-filial/pse-permisao-empresa-filial.component';
import { FeriasSetorComponent } from './componentes/ferias/Componentes/relatorio-ferias-setor/relatorio-ferias-setor.component';
import { PermissaoEmpftComponent } from './componentes/pse/permissao-empft/permissao-empft.component';
import { CadastroClienteComponent } from './componentes/pse/cadastro-cliente/cadastro-cliente.component';
import { TarefasComponent } from './componentes/pse/tarefas/tarefas.component';
import { ManutencaoServicosClienteComponent } from './componentes/pse/cadastro-cliente/servicos-cliente/manutencao-servicos-cliente/manutencao-servicos-cliente.component';
import { ConfiguracaoClienteComponent } from './componentes/pse/configuracao/configuracao-cliente/configuracao-cliente.component';
import { ManutencaoBdLogicosComponent } from './componentes/pse/cadastro-cliente/servicos-cliente/manutencao-bd-logicos/manutencao-bd-logicos.component';
import { ManutencaoBdFisicosComponent } from './componentes/pse/cadastro-cliente/servicos-cliente/manutencao-bd-fisicos/manutencao-bd-fisicos.component';
import { ServicosComponent } from './componentes/pse/servicos/servicos.component';
import { filtrarServico } from './componentes/pse/servicos/filtrar-servico.pipe';
import { filtrarCliente } from './componentes/pse/cadastro-cliente/filtrar-cliente.pipe';
import { CadastroUsuarioComponent } from './componentes/pse/usuario/cadastro-usuario/cadastro-usuario.component';
import { filtrarUsuario } from './componentes/pse/usuario/cadastro-usuario/filtrar-usuario.pipe';
import { ItemMenuComponent } from './componentes/pse/item-menu/item-menu.component';
import { filtrarTarefa } from './componentes/pse/tarefas/filtrar-tarefa.pipe';
import { filtrarItemMenu } from './componentes/pse/item-menu/item-menu.component.pipe';
import { filtroTipoUsuario } from './componentes/pse/cadastro-tipo-usuario/filtro-tipo-usuario.pipe';
import { AssinaturaAnexoComponent } from './componentes/assinatura-anexo/assinatura-anexo.component';
import { ControleColaboradorComponent } from './componentes/controle-ponto/controle-colaborador/controle-colaborador.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { filtrarVerba } from './componentes/lancamento-financeiro/filtrar-verba.pipe';
import { GraficoBeneficioComponent } from './componentes/beneficios/grafico-beneficio/grafico-beneficio.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AvisosComponent } from './componentes/ficha-funcional/avisos/avisos.component';
import { TransferenciaHistoricoComponent } from './componentes/transferencia-historico/transferencia-historico.component';
import { AvisosPipe } from './componentes/ficha-funcional/avisos/avisos.pipe';
import { FiltroHistoricoTransferenciaPipe } from './componentes/transferencia-historico/classes/filtro-historico-transferencia.pipe';
import { ElogiosEPenalidadesComponent } from './componentes/ficha-funcional/elogios-e-penalidades/elogios-e-penalidades.component';
import { filtrarElogioEPenalidade } from './componentes/ficha-funcional/elogios-e-penalidades/filtrar-elogiosEPenalidades.pipe';
import { ServicosClienteComponent } from './componentes/pse/cadastro-cliente/servicos-cliente/servicos-cliente.component';
import { DatePickerComponent } from './componentes/componentes-funcionais/date-picker/date-picker.component';
import { PesquisarMatriculaComponent } from './componentes/componentes-funcionais/pesquisar-matricula/pesquisar-matricula.component';
import { RecuperarSenhaComponent } from './componentes/login/recuperar-senha/recuperar-senha.component';
import { AlterarSenhaComponent } from './componentes/login/alterar-senha/alterar-senha.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ColaboradoresModule } from './componentes/colaboradores/colaboradores.module';
import { AlertaModalComponent } from './componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { HomePageComponent } from './componentes/home-page/home-page.component';
import { CaracteristicasPessoaisComponent } from './componentes/caracteristicas-pessoais/caracteristicas-pessoais.component';
import { SaudeComponent } from './componentes/saude/saude.component';
import { AtualizarComplementoCadastralComponent } from './componentes/atualizar-complemento-cadastral/atualizar-complemento-cadastral.component';
import { DependentesPerfilComponent } from './componentes/profile/componentes/dependentes-perfil/dependentes-perfil.component';
import { ScrollTopComponent } from './componentes/scroll-top/scroll-top.component';
import { CensoConvocacaoComponent } from './componentes/censo/censo-convocacao/censo-convocacao.component';
import { ListagemCensoComponent } from './componentes/censo/listagem-censo/listagem-censo.component';
import { FiltroFiliaisPipe } from './componentes/censo/censo-convocacao/filtro-filiais';
import { CensoPendentesComponent } from './componentes/censo/censo-pendentes/censo-pendentes.component';
import { FiltroFuncionalPipe } from './componentes/situacao-funcional/filtro-funcional';
import { ComplementoCadastralComponent } from './componentes/complemento-cadastral/complemento-cadastral.component';
import { CadastroAprendizComponent } from './componentes/complemento-cadastral/cadastro-aprendiz/cadastro-aprendiz.component';
import { FaltasComponent } from './componentes/faltas/faltas.component';
import { FaltasCadastroComponent } from './componentes/faltas/faltas-cadastro/faltas-cadastro.component';
import { PromocoesComponent } from './componentes/promocoes/promocoes.component';
import { PromocoesCadastroComponent } from './componentes/promocoes/promocoes-cadastro/promocoes-cadastro.component';
import { TempoServicoComponent } from './componentes/tempo-servico/tempo-servico.component';
import { TempoServicoCadastroComponent } from './componentes/tempo-servico/tempo-servico-cadastro/tempo-servico-cadastro.component';
import { ObservacoesDiversasComponent } from './componentes/observacoes-diversas/observacoes-diversas.component';
import { ObservacoesDiversasCadastroComponent } from './componentes/observacoes-diversas/observacoes-diversas-cadastro/observacoes-diversas-cadastro.component';
import { ExperienciaProfissionalComponent } from './componentes/experiencia-profissional/experiencia-profissional.component';
import { ExperienciaProfissionalCadastroComponent } from './componentes/experiencia-profissional/experiencia-profissional-cadastro/experiencia-profissional-cadastro.component';
import { FormacaoBasicaComponent } from './componentes/formacao-basica/formacao-basica.component';
import { FormacaoBasicaCadastroComponent } from './componentes/formacao-basica/formacao-basica-cadastro/formacao-basica-cadastro.component';
import { ComissaoComponent } from './componentes/comissao/comissao.component';
import { ComissaoCadastroComponent } from './componentes/comissao/comissao-cadastro/comissao-cadastro.component';
import { LicencaPremioComponent } from './componentes/licenca-premio/licenca-premio.component';
import { LicencaPremioCadastroComponent } from './componentes/licenca-premio/licenca-premio-cadastro/licenca-premio-cadastro.component';
import { ConverterDataPipe } from './componentes/lancamento-financeiro/converter-data.pipe';
import { ObservacoesFichaFuncionalComponent } from './componentes/componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { ProntuarioMedicoComponent } from './componentes/prontuario-medico/prontuario-medico.component';
import { ProntuarioMedicoCadastroComponent } from './componentes/prontuario-medico/prontuario-medico-cadastro/prontuario-medico-cadastro.component';
import { DocumentosPerfilComponent } from './componentes/profile/componentes/documentos-perfil/documentos-perfil.component';
import { AnexoPerfilComponent } from './componentes/profile/componentes/anexo-perfil/anexo-perfil.component';
import { CadastroComplementarComponent } from './componentes/informacoes-complementares/cadastro-complementar/cadastro-complementar.component';
import { DadosBeneficioComponent } from './componentes/informacoes-complementares/dados-beneficio/dados-beneficio.component';
import { InformacoesConcursoComponent } from './componentes/informacoes-complementares/informacoes-concurso/informacoes-concurso.component';
import { InformacoesSaudeComponent } from './componentes/informacoes-complementares/informacoes-saude/informacoes-saude.component';
import { NomeSocialComponent } from './componentes/informacoes-complementares/nome-social/nome-social.component';
import { ProfissionalEducacaoComponent } from './componentes/informacoes-complementares/profissional-educacao/profissional-educacao.component';
import { CadastroSaudeComponent } from './componentes/informacoes-complementares/informacoes-saude/cadastro-saude/cadastro-saude.component';
import { OutrasDocumentacoesComponent } from './componentes/outras-documentacoes/outras-documentacoes.component';
import { TransferenciaInativoComponent } from './componentes/transferencia-inativo/transferencia-inativo.component';
import { ColunaVaziaPipe } from './service/pipes/coluna-vazia.pipe';
import { ProcessosCadastroComponent } from './componentes/processos/processos-cadastro/processos-cadastro.component';
import { CadastroWhatsappComponent } from './componentes/cadastro-whatsapp/cadastro-whatsapp.component';
import { EstrangeiroComponent } from './componentes/informacoes-complementares/estrangeiro/estrangeiro.component';
import { ConfigService } from './service/config/config.service';
import { initializeApp } from './service/config/initialize.app';

// import { ControlePontoComponent } from './componentes/controle-ponto/controle-ponto.component';
// import { ConfiguracaoServicoComponent } from './componentes/pse/configuracao/configuracao-servico/configuracao-servico.component';
// import { ConfiguracaoUsuarioComponent } from './componentes/pse/configuracao/configuracao-usuario/configuracao-usuario.component';
// import { ListaUsuarioComponent } from './componentes/pse/usuario/lista-usuario/lista-usuario.component';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { MatFormFieldModule, MatSelectModule } from '@angular/material'
// import { CurrencyMaskModule } from 'ng2-currency-ma'; // Danilo
// import { filtroLista } from './componentes/pse/usuario/lista-usuario/teste.pipe';
// import { ComplementoCadastroComponent } from './componentes/complemento-cadastral/';
//import { PermissaoEmpftComponent } from './compon./componentes/pse/permissao-empresa-filia/permissao-empresa-filial.component

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CadastroComponent,
    ProfileComponent,
    SideMenuComponent,
    TopMenuComponent,
    MainComponent,
    TransferenciaFuncionarioComponent,
    ColaboradoresComponent,
    ColaboradoresFiltroPipe,
    situacaoFeriasFiltroPipe,
    ParametroFiltroPipe,
    PesquisaPipe,
    ErroComponent,
    ScatComponent,
    FeriasComponent,
    RelatoriosComponent,
    EsocialComponent,
    GerencialComponent,
    RecrutamentoComponent,
    RescisaoComponent,
    // ModalDuvidasComponent,
    SearchFilterPipe,
    UploadComponent,
    CancelarRescisaoComponent,
    CadastroPendenteComponent,
    ContinuacaoCadastroComponent,
    CancelarRescisaoComponent,
    QuadroBeneficiosComponent,
    RegrasBeneficioComponent,
    TipoBeneficiarioComponent,
    GrupoBeneficioComponent,
    CadastroBeneficioComponent,
    EdicaoBeneficioComponent,
    RelatorioSinteticoComponent,
    LancamentoFinanceiroComponent,
    FiltroPipe,
    SalarioComponent,
    EnderecoComponent,
    CadastroPublicoComponent,
    FeriasPerfilComponent,
    AnexoPerfilComponent,
    DadosPessoaisComponent,
    SituacaoFuncionalComponent,
    ProcessosComponent,
    AnexoComponent,
    RelatoriosPerfilComponent,
    ContrachequeComponent,
    MigracaoComponent,
    DependentesComponent,
    PensaoAlimenticiaComponent,
    PensaoPrevidenciariaComponent,
    RepresentanteLegalComponent,
    SituacaoFuncionalFalecimentoComponent,
    TelefoneMaskDirective,
    CadastroTipoUsuarioComponent,
    PermissaoEmpftComponent,
    ParametrosComponent,
    pesquisaFeriasPipe,
    PseColaboradoresComponent,
    CpfPipe,
    CnpjPipe,
    FiltroTextPipe,
    CadastroCessaoComponent,
    CadastroEstagioComponent,
    CadastroAprendizComponent,
    CadastroContratoExperienciaComponent,
    PsePermisaoEmpresaFilialComponent,
    FeriasSetorComponent,
    CadastroClienteComponent,
    TarefasComponent,
    ConfiguracaoClienteComponent,
    ManutencaoServicosClienteComponent,
    ManutencaoBdLogicosComponent,
    ManutencaoBdFisicosComponent,
    ServicosComponent,
    CadastroUsuarioComponent,
    filtrarServico,
    filtrarUsuario,
    filtrarCliente,
    filtrarTarefa,
    ItemMenuComponent,
    filtrarTarefa,
    filtrarItemMenu,
    ControleColaboradorComponent,
    filtroTipoUsuario,
    AssinaturaAnexoComponent,
    filtrarVerba,
    GraficoBeneficioComponent,
    TransferenciaHistoricoComponent,
    FiltroHistoricoTransferenciaPipe,
    ElogiosEPenalidadesComponent,
    filtrarElogioEPenalidade,
    ServicosClienteComponent,
    DatePickerComponent,
    PesquisarMatriculaComponent,
    RecuperarSenhaComponent,
    AlterarSenhaComponent,
    AlertaModalComponent,
    HomePageComponent,
    AvisosComponent,
    AvisosPipe,
    AtualizarComplementoCadastralComponent,
    CaracteristicasPessoaisComponent,
    SaudeComponent,
    DependentesPerfilComponent,
    ScrollTopComponent,
    CensoConvocacaoComponent,
    ListagemCensoComponent,
    FiltroFiliaisPipe,
    ColunaVaziaPipe,
    CensoPendentesComponent,
    FiltroFuncionalPipe,
    ComplementoCadastralComponent,
    FaltasComponent,
    FaltasCadastroComponent,
    PromocoesComponent,
    PromocoesCadastroComponent,
    TempoServicoComponent,
    TempoServicoCadastroComponent,
    ObservacoesDiversasComponent,
    ObservacoesDiversasCadastroComponent,
    ExperienciaProfissionalComponent,
    ExperienciaProfissionalCadastroComponent,
    FormacaoBasicaComponent,
    FormacaoBasicaCadastroComponent,
    ComissaoComponent,
    ComissaoCadastroComponent,
    LicencaPremioComponent,
    LicencaPremioCadastroComponent,
    ConverterDataPipe,
    ObservacoesFichaFuncionalComponent,
    ProntuarioMedicoComponent,
    ProntuarioMedicoCadastroComponent,
    DocumentosPerfilComponent,
    CadastroComplementarComponent,
    DadosBeneficioComponent,
    InformacoesConcursoComponent,
    InformacoesSaudeComponent,
    NomeSocialComponent,
    ProfissionalEducacaoComponent,
    CadastroSaudeComponent,
    OutrasDocumentacoesComponent,
    TransferenciaInativoComponent,
    ProcessosCadastroComponent,
    CadastroWhatsappComponent,
    EstrangeiroComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    NgxEchartsModule,
    OrderModule,
    RecaptchaModule,
    RecaptchaFormsModule, // leandro
    CurrencyMaskModule, // Danilo,
    ScrollingModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    BrowserAnimationsModule,
    ColaboradoresModule,

    // NgxMatSelectSearchModule,
    // filtroLista,
    //  ConfiguracaoUsuarioComponent,
    // MatSelectModule,//vinicius
    // ItemMenuComponent,
    // ControlePontoComponent,
    //PseEmpresaFilialComponent,
    // ListaUsuarioComponent,
    // ComplementoCadastroComponent,
  ],

  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    AuthService,
    AuthGuard,
    PesquisaPipe,
    ColaboradoresFiltroPipe,
    SearchFilterPipe,
    ExcelService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
