import { ConfigService } from './config.service';

export function initializeApp(configService: ConfigService) {
  return () =>
    configService
      .setConfig()
      .toPromise()
      .then((config) => {
        configService.getData();
      })
      .catch((err) => alert("Erro ao tentar ler o arquivo config.json"));
}
