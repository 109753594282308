import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  Validator,
} from '@angular/forms';
import { celularValidator } from '../../service/Validators/celularValidator';
import { telefoneValidator } from '../../service/Validators/telefoneValidator';
import { cpfValidator } from '../../service/Validators/cpfValidator';
import { pisValidator } from '../../service/Validators/pisValidator';
import { dataValidator } from '../../service/Validators/dataValidator';
import { CadastroPublicoService } from './cadastro-publico.service';
import { Data } from 'src/app/service/interfaces/Data';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import Swal from 'sweetalert';
import { Anexo, AnexoComponent } from '../anexo/anexo.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Route, Router } from '@angular/router';
import { ParametrosComponent } from '../parametros/parametros.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { type } from 'os';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { error } from 'console';
import {
  Horario,
  ContatosSecundarios,
  DadosPessoais,
  Identidade,
  CarteiraDeTrabalho,
  Cnh,
  Profissao,
  TituloEleitor,
  Pis,
  CertificadoMilitar,
  DadosBasicos,
  Emprego,
  Contato,
  Adts,
  Nomeacao,
  DescontosCompulsorios,
  ContaBancaria,
  Flags,
  ContratoTemporario,
  PeriodoExperiencia,
  Nacionalidade,
  EnderecoViaCep,
  Endereco,
  Cadastro,
  Usuario,
  BuscarColaboradorInativo,
} from './models/interfaces';
import { ProfileService } from '../profile/profile.service';
import { url } from '@angular-devkit/schematics';
import { async } from 'rxjs';
import { tituloValidator } from 'src/app/service/Validators/tituloValidator';
import { DocumentosService } from '../profile/componentes/documentos-perfil/documentos-perfil.service';
import { DataAdmissao } from './../cadastro/interface/data-admissao';
import { DependentesComponent } from '../dependentes/dependentes.component';

// import { DadosPessoais } from './../../service/interfaces/DadosPessoais';

@Component({
  selector: 'app-cadastro-publico',
  templateUrl: './cadastro-publico.component.html',
  styleUrls: ['./cadastro-publico.component.css'],
})
export class CadastroPublicoComponent implements OnInit {
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  @ViewChild(DependentesComponent, { static: false }) dependenteComponent: DependentesComponent;
  @ViewChild(ParametrosComponent, { static: false })
  parametrosComponent: ParametrosComponent;

  localStorage: LocalStorage = this.decryptLocalStorage();

  formCadastro: FormGroup;
  anexosDefault = {
    documentosAnexados: {
      nomeacaoAnexo: 1,
      fotoAnexo: 1,
      identidadeAnexo: 1,
      carteiraProfAnexo: 1,
      cpfAnexo: 1,
      pisPasepAnexo: 1,
      certificadoMilitarAnexo: 1,
      carteiraDeMotoristaAnexo: 1,
      certidaoDeNascimentoAnexo: 1,
      certidaoDeCasamentoAnexo: 1,
      comprovanteDeResidenciaAnexo: 1,
    },
    descricaoEmail: 'Documentos inexistentes',
  };
  anexoCarteira: number = 0;
  horarioEscolhido: Horario[];
  listaDiasSemana: string[] = [
    'Segunda Feira',
    'Terça Feira',
    'Quarta Feira',
    'Quinta Feira',
    'Sexta Feira',
    'Sábado',
    'Domingo',
  ];
  listaBancos: any;
  listaAgencias: any;
  listaOperacoes: any;
  listaCnh: Parametro[];
  listaCorPele: Parametro[];
  listaEstadoCivil: Parametro[];
  listaNacionalidade: Parametro[];
  listaSexo: Parametro[];
  listaTipoSanquineo: Parametro[];
  listaUf: Parametro[];
  listaGrauInstrucao: Parametro[];
  listaTipoFolha: Parametro[];
  listaMunicipiosTrabalho: Parametro[];
  listaSindicato: Parametro[] = [];
  listaEstabilidade: Parametro[];
  listaTipoFrequencia: Parametro[];
  ListaFuncao: Parametro[];
  listaCargo: Parametro[];
  listaSetor: Parametro[];
  listaClassificacaoAto: Parametro[];
  listaDiasUteis: Parametro[];
  listaEtnias: Parametro[];
  listaOpcaoFgts: Parametro[];
  listaVinculo: any[];
  listaNivel: any[];
  listaHorario: any[];
  listaMunicipios: any[];
  listaContatosSecundarios: any = [];
  tipoValeTranporte: string[] = [''];
  listaAnexo: Anexo[];
  listaCargaHoraria: any[] = [];
  contaMask: string;
  tipoVinculo: string;
  matricula: string;
  numeroDeOrdem: string = '';
  matriculaRota: string;
  step: number = 1;
  indicadoresCalculo: any;

  isCadastro: boolean;
  temErro: boolean = false;
  loadingCep: boolean = false;
  loadingCargo: boolean = false;
  loadingUf: boolean = false;
  carregarInativo: boolean = false;
  boolMatricula: boolean = true;
  matriculaNull: boolean = true;
  tipoNomeacaoObrigatoria: boolean = false;
  idadeMinima: number = 14;
  enviarEmail: boolean = false;
  formEmail: FormGroup;
  camposMilitarObrigatorios: boolean;

  existeCredencial: boolean = true;
  emailCredencial: string = '';

  listaDependente: any;
  telaDependente: boolean = true;
  matriculaAux: string;

  nomeInicio: string;
  matInicio: string;
  cpfInicio: string;
  vinculoInicio: string;
  vinculoInicioCodigo: string;

  constructor(
    public fb: FormBuilder,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private cadastroPublicoService: CadastroPublicoService,
    private route: Router,
    private documentosService: DocumentosService,
    private profileService: ProfileService
  ) {}

  public get nacionalidade(): typeof Nacionalidade {
    return Nacionalidade;
  }

  ngOnInit() {
    this.formCadastro = this.fb.group({
      matricula: [''],
      dadosPessoais: this.fb.group({
        nome: ['', Validators.required],
        cpf: ['', [Validators.required, cpfValidator]],
        dataNascimento: ['', [Validators.required]],
        setor: ['', Validators.required],
        dataAdmissao: ['', [Validators.required]],
        vinculo: [{ value: '', disabled: true }, Validators.required],
        sindicato: [{ value: '', disabled: true }],
        cargo: [{ value: '', disabled: true }],
        funcao: [{ value: '', disabled: true }],
        nivel: [{ value: '', disabled: true }],
        referencia: [{ value: '', disabled: true }],
        cargaHoraria: ['', Validators.required],
        tipoFolha: ['', Validators.required],
        horario: ['', Validators.required],
        diasUteis: ['', Validators.required],
        repouso: ['', Validators.required],
      }),
      estrangeiro: this.fb.group({
        dataChegada: [''],
        naturalizado: [''],
        casadoComBrasileiro: [''],
        filhoBrasileiro: [''],
      }),
      emprego: this.fb.group({
        primeiroEmprego: [''],
        outroEmprego: [''],
        pagaContribuicao: [''],
        cargaHoraria: [''],
        dataExameMedico: [''],
      }),
      documentacao: this.fb.group({
        identidade: ['', Validators.required],
        idDataExpedicao: ['', [Validators.required]],
        idOrgaoExpedido: ['', [Validators.required]],
        idUf: ['', [Validators.required]],

        carteiraProfissional: ['', Validators.required],
        serieCtps: ['', Validators.required],
        ufCarteiraTrabalho: ['', Validators.required],

        tituloEleitor: ['', [Validators.required, Validators.minLength(12), tituloValidator()]],
        zonaEleitoral: ['', Validators.required],
        secaoEleitoral: ['', Validators.required],
        ufEleitoral: ['', Validators.required],

        numeroCertificadoMilitar: [''],
        csm: [''],
        serieCertificadoMilitar: [''],

        profissaoRegistro: [''],
        profissaoOrgExpedidor: [''],
        profissaoUf: [''],

        cnhNumero: [''],
        cnhCategoria: [''],
        cnhDataValidade: [''],

        numeroPis: ['', [Validators.required, pisValidator]],
        // dataEmissaoPis: ['', [Validators.required]],
        // agenciaPis: ['', [Validators.required]],
      }),
      endereco: this.fb.group({
        cep: ['', Validators.required],
        logradouro: [{ value: '', disabled: true }, Validators.required],
        numero: ['', Validators.required],
        complemento: [''],
        bairro: [{ value: '', disabled: true }, Validators.required],
        cidade: [{ value: '', disabled: true }, Validators.required],
        uf: [{ value: '', disabled: true }, Validators.required],
      }),
      contato: this.fb.group({
        emailPrincipal: ['', [Validators.email, Validators.required]],
        emailSecundario: ['', [Validators.email]],
        celular: ['', [celularValidator, Validators.required]],
        telefone: ['', [telefoneValidator]],
      }),
      // pegando os valores digitados para usarmos quando o usuário clicar em adicicionar.
      contatosSecundarios: this.fb.group({
        celularSecundario: [''],
        tipoSecundario: [''],
        descSecundario: [''],
      }),
      profissao: this.fb.group({
        sexo: ['', Validators.required],
        nomeMae: ['', Validators.required],
        nomePai: [''],
        tipoSangue: [''],
        corPele: ['', Validators.required],
        estadoCivil: ['', Validators.required],
        grauInstrucao: ['', Validators.required],
        nacionalidade: ['', Validators.required],
        uf: [''],
        municipio: [{ value: '', disabled: true }],
        // estabilidade: ['', Validators.required],
        // tipoFrequencia: ['', Validators.required],
      }),
      dadosPagamento: this.fb.group({
        contaCorrenteCredito: this.fb.group({
          agencia: ['', Validators.required],
          banco: ['', Validators.required],
          operacao: ['', Validators.required],
          contaCorrente: ['', Validators.required],
        }),
        adts: this.fb.group({
          dataExercicio: ['', Validators.required],
          opcao: ['', Validators.required],
        }),
        contratoTemporario: this.fb.group({
          dataInicio: [{ value: '', disabled: true }],
          dataFim: [{ value: '', disabled: true }],
        }),
        periodoExperiencia: this.fb.group({
          dataInicio: [''],
          dataFim: [''],
        }),
        nomeacao: this.fb.group({
          classificacaoAto: ['', Validators.required],
          numeroProcesso: ['', Validators.required],
        }),
        descontosCompulsorios: this.fb.group({
          impostoRenda: ['', Validators.required],
          previdencia: ['', Validators.required],
          pensao: ['', Validators.required],
        }),
        diarioOficial: this.fb.group({
          numero: ['', Validators.required],
          data: ['', [Validators.required]],
        }),
        ato: this.fb.group({
          numero: ['', Validators.required],
          ano: [{ value: '', disabled: true }, Validators.required],
          data: ['', [Validators.required]],
        }),
        bloqueadoConsignado: ['', Validators.required],
        estabilidade: ['', Validators.required],
        tipoFrequencia: ['', Validators.required],
      }),
    });

    this.formEmail = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });

    this.isCadastro = true;
    this.buscarParametros();
    this.buscarBancos();
    this.formCadastro.get('matricula').disable();
    
    if (this.localStorage.TipoUsuario == '10') {
      this.formCadastro.get('dadosPessoais').get('cpf').setValue(this.localStorage.Cpf);
      this.buscarColaboradorInativo();
      // this.formCadastro.get('profissao').get('estabilidade').clearValidators();
      // this.formCadastro.get('profissao').get('estabilidade').updateValueAndValidity();
      // this.formCadastro.get('profissao').get('tipoFrequencia').clearValidators();
      // this.formCadastro.get('profissao').get('tipoFrequencia').updateValueAndValidity();
    }
    this.retornarMatricula();
  }
  retornarMatricula() {
    this.profileService.retornarMatricula().subscribe((matricula) => {
      this.matricula = matricula;
    });
  }
  requiredIfVinculoNotEquals(vinculoValue: string) {
    return (control) => {
      if (
        this.formCadastro &&
        this.formCadastro.get('dadosPessoais.vinculo').value !== vinculoValue
      ) {
        return Validators.required(control);
      } else {
        return null;
      }
    };
  }

  requiredIfVinculoEquals(vinculoValue: string) {
    return (control) => {
      if (
        this.formCadastro &&
        this.formCadastro.get('dadosPessoais.vinculo').value === vinculoValue
      ) {
        return Validators.required(control);
      } else {
        return null;
      }
    };
  }

  buscarBancos(): void {
    // this.spinner.show();
    this.cadastroPublicoService.buscarBancos().subscribe(
      (data) => {
        this.listaBancos = data;
        // this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarAgencias(event): void {
    this.spinner.show();
    const nomeBanco = event.target.value;
    this.cadastroPublicoService.buscarAgencias(nomeBanco).subscribe(
      (data) => {
        this.listaAgencias = data;
        this.buscarOperacoes(nomeBanco);
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarOperacoes(nomeBanco: string): void {
    this.spinner.show();
    this.cadastroPublicoService.buscarOperacoes(nomeBanco).subscribe(
      (data) => {
        this.listaOperacoes = data;
        this.spinner.hide();
        console.log(this.listaOperacoes);
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  // não está sendo usada
  async buscarColaborador(colaborador: any): Promise<void> {
    // this.cadastroPublicoService.buscarColaborador(colaborador)
    //   .subscribe(
    //     (data: any) => {
    //       this.carregarInativo = false;
    //       this.carregarCampos(data, colaborador.Etapa);
    //     },
    //     error => this.validaErro.retornoErro(error)
    //   );
    // comentário temporário enquanto se ajeita a rota de busca de colaborador no back - o retorno do json
    try {
      const cpf = colaborador.Cpf;
      const data: any = await this.cadastroPublicoService.buscarColaboradorInativo(cpf).toPromise();
      console.log('data ao buscar colab', data);
      this.carregarInativo = true;

      if (data['Afastamento'] && data['Afastamento']['Flag18'] == 1) {
        this.carregarCampos(data, data['Etapa']);
        if (data['Etapa'] >= 2) this.onSexoChange();
      } else {
        this.spinner.hide();
        const result = await Swal({
          title: 'Colaborador já cadastrado',
          text: 'O colaborador pesquisado já concluiu as 5 etapas, caso deseje editar alguma informação dirija-se ao perfil.',
          icon: 'success',
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: ['Ficar aqui', 'Ir para perfil'],
        });
        if (result) this.route.navigate([`/perfil/${data.NumeroDeOrdem}`]);
      }

      this.step = Number(data['Etapa']) + 1;
      // if (this.step > 1) {
      // }
      // if (this.step === 2) document.getElementById("btEnviarEmail").click();
    } catch (error) {
      this.validaErro.retornoErro(error);
      this.spinner.hide();
    }
  }

  buscarDependentes(matricula) {
    this.profileService.buscarDependentes(matricula).subscribe(
      (data) => {
        this.listaDependente = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
        //Swal.fire('Erro', 'Não foi possível obter as informações das filiais.', 'error');
      }
    );
  }

  verificarCadastroExistente() {
    const parametroBuscarColaborador = this.montarBuscarColaboradorInativo();
    console.log(parametroBuscarColaborador);
    this.spinner.show();
    this.cadastroPublicoService.verificarCadastroExistente(parametroBuscarColaborador).subscribe(
      (data: any) => {
        this.carregarInativo = true;
        if (this.localStorage.TipoUsuario == '10') {
          this.localStorage.MatriculaUsuario = data.Matricula;
        }
        if (data['Etapa'] !== '0') {
          console.log('data', data['Afastamento']['Flag18']);
          if (data['Afastamento'] && data['Afastamento']['Flag18'] == 1) {
            this.spinner.hide();
            Swal({
              title: 'Cadastro pendente',
              text: 'O colaborador pesquisado está com o cadastro pendente. Deseja dar continuidade ao cadastro?',
              icon: 'success',
              closeOnClickOutside: false,
              closeOnEsc: false,
              buttons: ['Ficar aqui', 'Continuar cadastro'],
            }).then((result) => {
              if (result) {
                this.carregarCampos(data, data['Etapa']);
                this.matriculaAux = data.Matricula;
                this.buscarDependentes(data.Matricula);
                this.temErro = false;
                this.spinner.hide();
                this.step = Number(data['Etapa']) + 1;
                if (data['Etapa'] >= 2) this.onSexoChange();
                console.log('cadastro usuario', data);
                console.log(
                  'aviso inicio verificar cadastro existente',
                  data.Etapa1.DadosPessoais.NomeCompleto,
                  data.Matricula,
                  data.Etapa1.DadosPessoais.Cpf
                );
                this.nomeInicio = data.Etapa1.DadosPessoais.NomeCompleto;
                this.matInicio = data.Matricula;
                this.cpfInicio = this.formatarCPF(data.Etapa1.DadosPessoais.Cpf);
                this.vinculoInicioCodigo = data.Etapa1.DadosPessoais.Ocupacao.Vinculo;
                if (this.listaVinculo) {
                  let vinculoAtual = this.listaVinculo.find(
                    (vinculo) => vinculo.Codigo == this.vinculoInicioCodigo
                  );
                  if (vinculoAtual) {
                    this.vinculoInicio = `${vinculoAtual.Codigo} - ${vinculoAtual.Descricao}`;
                  }
                } else {
                  this.buscarVinculo();
                }
              }
            });
          } else {
            this.spinner.hide();
            Swal({
              title: 'Colaborador já cadastrado',
              text: 'O colaborador pesquisado já concluiu as 5 etapas, caso deseje editar alguma informação dirija-se ao perfil.',
              icon: 'success',
              closeOnClickOutside: false,
              closeOnEsc: false,
              buttons: ['Ficar aqui', 'Ir para perfil'],
            }).then((result) => {
              if (result) this.route.navigate([`/perfil/${data.NumeroDeOrdem}`]);
            });
          }
        }
        this.spinner.hide();
        // if (this.step == 2) document.getElementById("btEnviarEmail").click();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarColaboradorInativo() {
    const parametroBuscarColaborador = this.montarBuscarColaboradorInativo();
    console.log(parametroBuscarColaborador);
    this.spinner.show();
    this.cadastroPublicoService.buscarColaboradorInativo(parametroBuscarColaborador).subscribe(
      async (data: any) => {
        this.carregarInativo = true;
        if (this.localStorage.TipoUsuario == '10') {
          this.localStorage.MatriculaUsuario = data.Matricula;
        }
        
        if (data['Afastamento'] && data['Afastamento']['Flag18'] == 1) {
          this.carregarCampos(data, data['Etapa']);
          this.matriculaAux = data.Matricula;
          this.buscarDependentes(data.Matricula);

          if (data['Etapa'] >= 2) this.onSexoChange();
          console.log('cadastro usuario', data);
          console.log(
            'aviso inicio inativo',
            data.Etapa1.DadosPessoais.NomeCompleto,
            data.Matricula,
            data.Etapa1.DadosPessoais.Cpf,
            data.Etapa1.DadosPessoais.Ocupacao.Vinculo,
            this.listaVinculo
          );
          this.nomeInicio = data.Etapa1.DadosPessoais.NomeCompleto;
          this.matInicio = data.Matricula;
          this.cpfInicio = this.formatarCPF(data.Etapa1.DadosPessoais.Cpf);
          this.vinculoInicioCodigo = data.Etapa1.DadosPessoais.Ocupacao.Vinculo;
          if (this.listaVinculo) {
            let vinculoAtual = this.listaVinculo.find(
              (vinculo) => vinculo.Codigo == this.vinculoInicioCodigo
            );
            if (vinculoAtual) {
              this.vinculoInicio = `${vinculoAtual.Codigo} - ${vinculoAtual.Descricao}`;
            }
          } else {
            console.log('entrei buscar vinculo');
            this.buscarVinculo();
          }
        } else {
          this.spinner.hide();
          return await Swal({
            title: 'Colaborador já cadastrado',
            text: 'O colaborador pesquisado já concluiu as 5 etapas, caso deseje editar alguma informação dirija-se ao perfil.',
            icon: 'success',
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ['Ficar aqui', 'Ir para perfil'],
          }).then((result) => {
            if (result) this.route.navigate([`/perfil/${data.NumeroDeOrdem}`]);
          });
        }

        this.temErro = false;
        this.spinner.hide();
        this.step = Number(data['Etapa']) + 1;
        // if (this.step == 2) document.getElementById("btEnviarEmail").click();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  emissao(evento) {
    console.log('evento emissao', evento);
    this.telaDependente = evento;
  }

  matriculaNula(): boolean {
    if (this.formCadastro.get('matricula').value == '') this.matriculaNull = true;
    else this.matriculaNull = false;
    return this.matriculaNull;
  }

  criarCredenciais(): void {
    const emailFornecido = this.formEmail.value.email;
    if (emailFornecido == null) {
      Swal('Atenção', 'Este colaborador não possui um email informado.', 'warning');
    } else {
      document.getElementById('btEnviarEmail').click();
      this.spinner.show();

      const dados = {
        Email: emailFornecido.trim(),
        Cpf: this.formCadastro.get('dadosPessoais').get('cpf').value.replace(/[.-]/g, ''),
        Nome: this.formCadastro.get('dadosPessoais').get('nome').value,
        EmpresaFilial: this.localStorage.FilialAtual,
        NomeEmpresaFilial: this.localStorage.NomeFilialAtual,
        NumeroDeOrdem: this.numeroDeOrdem,
        CodigoCliente: this.localStorage.ClienteId,
        Tipo: '10',
      };

      this.cadastroPublicoService.criarCredenciais(dados).subscribe(
        (data) => {
          // document.getElementById('fecharModalCredencial').click();
          Swal('Sucesso', 'Credenciais enviado para o email.', 'success');
          this.existeCredencial = true;
          // this.enviarNovoEmail = null;
          this.emailCredencial = '';
          this.spinner.hide();
          this.route.navigate(['home']);
        },
        (error) => {
          this.existeCredencial = false;
          // this.enviarNovoEmail = null;
          this.emailCredencial = '';
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
    }
  }

  sendEmail() {
    document.getElementById('btEnviarEmail').click();
    this.spinner.show();
    const EmailDestinatario = this.formEmail.value.email;
    const numeroDeOrdem = this.numeroDeOrdem;
    const Url = window.location.href.split('/')[2];

    this.cadastroPublicoService.gerarLink({ EmailDestinatario, numeroDeOrdem, Url }).subscribe(
      (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Email com link para cadastro foi enviado.', 'p');
        this.route.navigate(['home']);
      },
      (error) => {
        console.log('Error sendEmail', error);
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
    return false;
  }

  adicionarContatoSecundario() {
    const { celularSecundario, descSecundario } =
      this.formCadastro.get('contatosSecundarios').value;
    if (celularSecundario && descSecundario && celularSecundario.length >= 10) {
      const dadosContatoSecundario: ContatosSecundarios = {
        Numord: this.numeroDeOrdem,
        DDDSecundario: celularSecundario.substring(0, 2),
        CelularSecundario: celularSecundario,
        TipoSecundario: '',
        DescSecundario: descSecundario,
      };
      this.listaContatosSecundarios.push(dadosContatoSecundario);
      this.formCadastro.get('contatosSecundarios').patchValue({
        celularSecundario: '',
        tipoSecundario: '',
        descSecundario: '',
      });
    }
  }

  removerContatoSecundario(index) {
    this.listaContatosSecundarios.splice(index, 1);
  }

  formatarNumero(numero) {
    let pt1 = numero.substring(0, 2);
    let pt2 = numero.substring(2, numero.length - 4);
    let pt3 = numero.substring(numero.length - 4);
    return `(${pt1}) ${pt2}-${pt3}`;
  }

  formatarCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');

    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return cpf;
  }

  removerCampoNumero(numero) {
    let index = this.listaContatosSecundarios.indexOf(numero);
    this.listaContatosSecundarios.splice(index, 1);
  }

  buscaMatricula(matricula): void {
    this.cadastroPublicoService.buscarColaboradorMatricula(matricula).subscribe(
      () => {
        this.formCadastro.get('matricula').setValue('');
        Swal('Atenção!', 'Matrícula existente', 'warning');
        this.spinner.hide();
      },
      () => {
        Swal('Sucesso', 'Matrícula válida', 'success');
        this.spinner.hide();
      }
    );
  }

  desabilitaInput() {
    if (this.boolMatricula == false) {
      this.boolMatricula = true;
      this.formCadastro.get('matricula').disable();
      this.formCadastro.get('matricula').setValue('');
    } else {
      this.boolMatricula = false;
      this.formCadastro.get('matricula').enable();
    }
  }

  verificarMatricula(): void {
    this.buscaMatricula(this.formCadastro.get('matricula').value);
  }

  carregarCampos(colaborador: any, etapa: number): void {
    const { Etapa1, Etapa2, Etapa3, Etapa4, Etapa5 } = colaborador;
    // this.spinner.show();
    this.matricula = colaborador.Matricula;
    this.numeroDeOrdem = colaborador.NumeroDeOrdem;
    this.formCadastro.setValue({
      matricula: colaborador.Matricula,
      dadosPessoais: {
        nome: Etapa1 ? Etapa1.DadosPessoais.NomeCompleto : '',
        cpf: Etapa1 ? Etapa1.DadosPessoais.Cpf.padStart(11, '0') : '',
        dataNascimento: Etapa1
          ? this.formataDataMontar(Etapa1.DadosPessoais.DataNascimento.ToDate)
          : '',
        setor: Etapa1 ? Etapa1.DadosPessoais.EnderecoEmpresa.Setor : '',
        dataAdmissao: Etapa1
          ? this.formataDataMontar(Etapa1.DadosPessoais.Ocupacao.DataAdmissao.ToDate)
          : '',
        vinculo: Etapa1 ? Etapa1.DadosPessoais.Ocupacao.Vinculo : '',
        sindicato: Etapa1 ? Etapa1.DadosPessoais.Sindicato : '',
        cargo: Etapa1 ? Etapa1.DadosPessoais.Ocupacao.Cargo : '',
        nivel: Etapa1 ? Etapa1.DadosPessoais.Ocupacao.Nivel : '',
        funcao: Etapa1 ? Etapa1.DadosPessoais.Ocupacao.Funcao : '',
        referencia: Etapa1 ? Etapa1.DadosPessoais.Ocupacao.Referencia : '',
        cargaHoraria: Etapa1 ? Etapa1.DadosPessoais.JornadaDeTrabalho.CargaHoraria : '',
        horario: Etapa1 ? Etapa1.DadosPessoais.JornadaDeTrabalho.Horario : '',
        repouso: Etapa1 ? Etapa1.DadosPessoais.JornadaDeTrabalho.Descanso : '',
        tipoFolha: Etapa1 ? Etapa1.DadosPessoais.TipoFolha : '',
        diasUteis: Etapa1 ? Etapa1.CodigoDiasUteis : '',
      },
      estrangeiro: {
        dataChegada: Etapa2
          ? this.formataDataMontar(Etapa2.DadosEstrangeiro.DataChegada.ToDate)
          : '',
        naturalizado: Etapa2 ? Etapa2.DadosEstrangeiro.Naturalizado.toString() : '',
        casadoComBrasileiro: Etapa2 ? Etapa2.DadosEstrangeiro.CasadoComBrasileiro.toString() : '',
        filhoBrasileiro: Etapa2 ? Etapa2.DadosEstrangeiro.FilhoBrasileiro.toString() : '',
      },
      emprego: {
        primeiroEmprego: !Etapa2
          ? ''
          : etapa == 1
          ? ''
          : Etapa2.Emprego.PrimeiroEmprego
          ? '1'
          : '0',
        outroEmprego: !Etapa2
          ? ''
          : etapa == 1
          ? ''
          : Etapa2.Emprego.PrimeiroEmprego
          ? ''
          : Etapa2.Emprego.OutroEmprego
          ? '1'
          : '0',
        pagaContribuicao: !Etapa2
          ? ''
          : etapa == 1
          ? ''
          : Etapa2.Emprego.PrimeiroEmprego
          ? ''
          : Etapa2.Emprego.PagaContribuicao.toString(),
        cargaHoraria: !Etapa2
          ? ''
          : Etapa2.Emprego.PrimeiroEmprego
          ? ''
          : Etapa2.Emprego.CargaHoraria,
        dataExameMedico: !Etapa2
          ? ''
          : Etapa2.Emprego.PrimeiroEmprego
          ? ''
          : this.formataDataMontar(Etapa2.Emprego.DataExameMedico.ToDate),
      },
      profissao: {
        nomeMae: Etapa1 ? Etapa1.DadosPessoais.NomeDaMae : '',
        nomePai: Etapa1 ? Etapa1.DadosPessoais.NomeDoPai : '',
        tipoSangue: Etapa1 ? Etapa1.DadosPessoais.TipoSanguineo : '',
        corPele: Etapa1 ? Etapa1.DadosPessoais.CorDePele : '',
        estadoCivil: Etapa1
          ? Etapa1.DadosPessoais.EstadoCivil == 0
            ? ''
            : Etapa1.DadosPessoais.EstadoCivil
          : '',
        grauInstrucao: Etapa1
          ? Etapa1.DadosPessoais.GrauDeInstrucao == 0
            ? ''
            : Etapa1.DadosPessoais.GrauDeInstrucao
          : '',
        nacionalidade: Etapa1
          ? Etapa1.DadosPessoais.Nacionalidade == 0
            ? ''
            : Etapa1.DadosPessoais.Nacionalidade
          : '',
        uf: Etapa1 ? Etapa1.DadosPessoais.UfNaturalidade : '',
        municipio: Etapa1 ? Etapa1.DadosPessoais.MunicipioNaturalidade : '',
        // estabilidade: Etapa2 ? Etapa2.Estabilidade : '',
        // tipoFrequencia: Etapa2 ? (Etapa2.TipoFrequencia == '0' ? '' : Etapa2.TipoFrequencia) : '',
        sexo: Etapa1 ? '' : Etapa1.DadosPessoais.Sexo.toString(),
      },
      documentacao: {
        identidade: Etapa4
          ? Etapa4.Documentos.Identidade.RegistroGeral == 0
            ? ''
            : Etapa4.Documentos.Identidade.RegistroGeral
          : '',
        idDataExpedicao: Etapa4
          ? this.formataDataMontar(Etapa4.Documentos.Identidade.DataDeExpedicao.ToDate)
          : '',
        idOrgaoExpedido: Etapa4 ? Etapa4.Documentos.Identidade.OrgaoEmissor : '',
        idUf: Etapa4
          ? Etapa4.Documentos.Identidade.UF == '0'
            ? ''
            : Etapa4.Documentos.Identidade.UF
          : '',
        carteiraProfissional: Etapa4
          ? Etapa4.Documentos.CarteiraTrabalhista.Numero == '0'
            ? ''
            : Etapa4.Documentos.CarteiraTrabalhista.Numero
          : '',
        serieCtps: Etapa4
          ? Etapa4.Documentos.CarteiraTrabalhista.SerieCTPS == '0'
            ? ''
            : Etapa4.Documentos.CarteiraTrabalhista.SerieCTPS
          : '',
        ufCarteiraTrabalho: Etapa4
          ? Etapa4.Documentos.CarteiraTrabalhista.UFCTPS == '0'
            ? ''
            : Etapa4.Documentos.CarteiraTrabalhista.UFCTPS
          : '',
        tituloEleitor: Etapa4
          ? Etapa4.Documentos.TituloEleitor.Numero == '0'
            ? ''
            : Etapa4.Documentos.TituloEleitor.Numero
          : '',
        zonaEleitoral: Etapa4
          ? Etapa4.Documentos.TituloEleitor.ZonaEleitoral == '0'
            ? ''
            : Etapa4.Documentos.TituloEleitor.ZonaEleitoral
          : '',
        secaoEleitoral: Etapa4
          ? Etapa4.Documentos.TituloEleitor.SecaoEleitoral == '0'
            ? ''
            : Etapa4.Documentos.TituloEleitor.SecaoEleitoral
          : '',
        ufEleitoral: Etapa4
          ? Etapa4.Documentos.TituloEleitor.UfEleitoral == '0'
            ? ''
            : Etapa4.Documentos.TituloEleitor.UfEleitoral
          : '',
        numeroCertificadoMilitar: Etapa4
          ? Etapa4.Documentos.CertificadoMilitar.Numero == '0'
            ? ''
            : Etapa4.Documentos.CertificadoMilitar.Numero
          : '',
        csm: Etapa4
          ? Etapa4.Documentos.CertificadoMilitar.Csm == '0'
            ? ''
            : Etapa4.Documentos.CertificadoMilitar.Csm
          : '',
        serieCertificadoMilitar: Etapa4
          ? Etapa4.Documentos.CertificadoMilitar.Serie == '0'
            ? ''
            : Etapa4.Documentos.CertificadoMilitar.Serie
          : '',
        profissaoRegistro: Etapa4
          ? Etapa4.Documentos.Profissao.Registro == '0'
            ? ''
            : Etapa4.Documentos.Profissao.Registro
          : '',
        profissaoOrgExpedidor: Etapa4
          ? Etapa4.Documentos.Profissao.OrgaoExpedidor == '0'
            ? ''
            : Etapa4.Documentos.Profissao.OrgaoExpedidor
          : '',
        profissaoUf: Etapa4
          ? Etapa4.Documentos.Profissao.UF == '0'
            ? ''
            : Etapa4.Documentos.Profissao.UF
          : '',
        cnhNumero: Etapa4 ? Etapa4.Documentos.CarteiraDeHabilitacao.Numero.trim() : '',
        cnhCategoria: Etapa4 ? Etapa4.Documentos.CarteiraDeHabilitacao.CategoriaCnh.trim() : '',
        cnhDataValidade: Etapa4
          ? Etapa4.Documentos.CarteiraDeHabilitacao.DataValidadeCnh.ToDate.trim() == ''
            ? ''
            : this.formataDataMontar(
                Etapa4.Documentos.CarteiraDeHabilitacao.DataValidadeCnh.ToDate.trim()
              )
          : '',
        numeroPis: Etapa4
          ? Etapa4.Documentos.Pis.Numero == 0
            ? ''
            : Etapa4.Documentos.Pis.Numero.toString().padStart(11, '0')
          : '',
        // dataEmissaoPis: Etapa4
        //   ? this.formataDataMontar(Etapa4.Documentos.Pis.DataDeEmissao.ToDate)
        //   : '',
        // agenciaPis: Etapa4 ? Etapa4.Documentos.Pis.Agencia : '',
      },
      endereco: {
        cep: Etapa3 ? (Etapa3.Endereco.Cep == '0' ? '' : Etapa3.Endereco.Cep) : '',
        logradouro: Etapa3 ? Etapa3.Endereco.Logradouro : '',
        numero: Etapa3 ? (Etapa3.Endereco.Numero == 0 ? '' : Etapa3.Endereco.Numero) : '',
        complemento: Etapa3 ? Etapa3.Endereco.Complemento : '',
        bairro: Etapa3 ? Etapa3.Endereco.Bairro : '',
        cidade: Etapa3 ? Etapa3.Endereco.Cidade : '',
        uf: Etapa3 ? Etapa3.Endereco.UF : '',
      },
      contato: {
        emailPrincipal: Etapa3 ? Etapa3.Contato.Email1 : '',
        emailSecundario: Etapa3 ? Etapa3.Contato.Email2 : '',
        telefone: Etapa3
          ? `${Etapa3.Contato.DDDTelefone === 0 ? '' : Etapa3.Contato.DDDTelefone}${
              Etapa3.Contato.Telefone === 0 ? '' : Etapa3.Contato.Telefone
            }`
          : '',
        celular: Etapa3
          ? `${Etapa3.Contato.DDDCelular === 0 ? '' : Etapa3.Contato.DDDCelular}${
              Etapa3.Contato.Celular === 0 ? '' : Etapa3.Contato.Celular
            }`
          : '',
      },
      contatosSecundarios: {
        celularSecundario: '',
        descSecundario: '',
        tipoSecundario: '',
      },
      dadosPagamento: {
        contaCorrenteCredito: {
          agencia: '',
          banco: '',
          operacao: '',
          contaCorrente: '',
        },
        adts: {
          dataExercicio: '',
          opcao: '',
        },
        contratoTemporario: {
          dataInicio: '',
          dataFim: '',
        },
        periodoExperiencia: {
          dataInicio: '',
          dataFim: '',
        },
        nomeacao: {
          classificacaoAto: '',
          numeroProcesso: '',
        },
        descontosCompulsorios: {
          impostoRenda: '',
          previdencia: '',
          pensao: '',
        },
        diarioOficial: {
          numero: '',
          data: '',
        },
        ato: {
          numero: '',
          ano: '',
          data: '',
        },
        bloqueadoConsignado: '',
        estabilidade: '',
        tipoFrequencia: '',
      },
    });
    this.buscarVinculo();
    if (Etapa2) {
      this.buscarSindicato();
      this.onCargoChange();
    }
    if (etapa >= 2) this.carregarClassificacaoAto();
    this.buscarMunicipio(Etapa1.DadosPessoais.UfNaturalidade);
    // this.spinner.hide();
  }

  montarBuscarColaboradorInativo(): BuscarColaboradorInativo {
    return {
      cpf: this.formCadastro.get('dadosPessoais').get('cpf').value.replace(/[.-]/g, ''),
      empfil: String(this.localStorage.FilialAtual),
       matricula: +this.matriculaAux,
    };
  }

  buscarParametros(): void {
    this.spinner.show();
    this.cadastroPublicoService.buscarParametros().subscribe(
      async (data: any) => {
        const filialPrevidenciaria = this.localStorage.FilialPrevidenciaria;
        if (filialPrevidenciaria) this.buscarSetorPrevidencia();
        this.listaCargo = data.Cargos.filter((cargo) => cargo.Tipo == '1');
        this.ListaFuncao = data.Cargos.filter((funcao) => funcao.Tipo != '1');
        this.listaCnh = [...data.CategoriasCnh, ...data.CategoriasCnhDeficiente];
        this.listaOpcaoFgts = data.OpcoesFGTS;
        this.listaDiasUteis = data.DiasUteis;
        this.listaEstabilidade = data.Estabilidades;
        this.listaUf = data.Estados;
        this.listaEstadoCivil = data.EstadosCivil;
        this.listaCorPele = data.Etnias;
        this.listaGrauInstrucao = data.GrausInstrucao;
        this.listaHorario = data.Horarios;
        this.listaSetor = data.Setores;
        this.listaNacionalidade = data.Nacionalidades;
        this.listaSexo = data.Sexos;
        this.listaTipoFolha = data.TiposFolha;
        this.listaTipoFrequencia = data.TiposFrequencia;
        this.listaTipoSanquineo = data.TiposSanguineo;
        if (history.state.colaborador) {
          this.matriculaAux = history.state.colaborador.Matricula;
          this.formCadastro.get('dadosPessoais').get('cpf').setValue(history.state.colaborador.Cpf);
          await this.buscarColaboradorInativo();
        }
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarSetorPrevidencia(): void {
    this.cadastroPublicoService.buscarSetorPrevidencia().subscribe(
      (data: Parametro[]) => (this.listaSetor = data),
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarSindicato(): void {
    this.cadastroPublicoService.buscarSindicato().subscribe(
      (data: Parametro[]) => {
        this.listaSindicato = data;
        const formSindicato = this.formCadastro.get('dadosPessoais').get('sindicato');
        this.formCadastro.get('dadosPessoais').get('sindicato').enable();
        if (data.length > 0) {
          formSindicato.setValidators(Validators.required);
        } else {
          formSindicato.setValidators(null);
        }
        formSindicato.updateValueAndValidity();
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  verificaTamanhoData(campo: string): void {
    const data = this.formCadastro.value.dadosPessoais[campo];
    if (data.length > 10) {
      this.formCadastro.get('dadosPessoais').get(campo).setErrors({ data: true });
      Swal('Data inválida', 'Por favor insira um formato de data válido - dd/mm/aaaa', 'warning');
    }
  }

  onDataNascimentoChange(): void {
    this.formCadastro.get('dadosPessoais').get('vinculo').disable();
    const { sexo } = this.formCadastro.value.profissao;
    const { dataNascimento } = this.formCadastro.value.dadosPessoais;
    const { idDataExpedicao } = this.formCadastro.value.documentacao;
    if (dataNascimento.length == 10) {
      this.buscarVinculo();
      if (sexo === '1' && this.maiorIdade()) this.setMilitarObrigatorio();
      else this.setMilitarOpcional();
    }
    if (dataNascimento.length == 8 && idDataExpedicao.length == 8) {
      this.verificaData(
        this.formCadastro.get('dadosPessoais').get('dataNascimento'),
        this.formCadastro.get('documentacao').get('idDataExpedicao')
      );
    }
  }

  verificarMenor(data: any) {
    if (!this.ValidarValoresDatas2(data.target.value.replaceAll('-', ''))) {
      this.formCadastro.get('dadosPessoais').get('dataNascimento').setValue('');
      return;
    }
  }

  ValidarValoresDatas2(data) {
    let dia, mes, ano;
    let complementoData = 'T00:00:00';
    dia = data.substring(6, 8);
    mes = data.substring(4, 6);
    ano = data.substring(0, 4);
    let dateAtual: Date = new Date();
    let time = new Date(ano + '-' + mes + '-' + dia + complementoData);
    let auxTime = time.getFullYear();
    let auxDate = dateAtual.getFullYear();
    if (!this.validarData(dia + '/' + mes + '/' + ano)) {
      return true;
    }
    if (auxDate - this.idadeMinima >= auxTime) {
      return true;
    } else {
      Swal(
        'Não é Possível cadastrar menores de 16 anos',
        'Por favor, preencha uma data válida.',
        'warning'
      );
      return false;
    }
  }

  mascaraCPF(valor) {
    var valorAlterado = valor.target.value;
    valorAlterado = valorAlterado.replace(/\D/g, ''); // Remove todos os não dígitos
    valorAlterado = valorAlterado.replace(/^0+/, '');
    if (valorAlterado.length > 11) {
      valorAlterado = valorAlterado.slice(0, -1);
    }
    valorAlterado = valorAlterado.padStart(11, '0'); //adiciona 0 iniciais
    valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, '$1-$2'); // Adiciona a parte de centavos
    valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // Adiciona pontos a cada três dígitos
    this.formCadastro.get('dadosPessoais').get('cpf').setValue(valorAlterado);
  }

  validarData(data) {
    let reg = /[^\d\/\.]/gi; // Mascara = dd/mm/aaaa | dd.mm.aaaa
    let valida = data.replace(reg, ''); // aplica mascara e valida sÃ³ numeros
    if (valida && valida.length == 10) {
      // Ã© vÃ¡lida, entÃ£o ;)
      let ano = data.substr(6),
        mes = data.substr(3, 2),
        dia = data.substr(0, 2),
        M30 = ['04', '06', '09', '11'],
        v_mes = /(0[1-9])|(1[0-2])/.test(mes),
        v_ano = /(19[1-9]\d)|(20\d\d)|2100/.test(ano),
        rexpr = new RegExp(mes),
        fev29 = ano % 4 ? 28 : 29;

      if (v_mes && v_ano) {
        if (mes == '02') return dia >= 1 && dia <= fev29;
        else if (rexpr.test(M30.toString())) return /((0[1-9])|([1-2]\d)|30)/.test(dia);
        else return /((0[1-9])|([1-2]\d)|3[0-1])/.test(dia);
      }
    }
    return false;
  }

  buscarVinculo(): void {
    let { dataNascimento } = this.formCadastro.value.dadosPessoais;
    console.log('data nascimento vinculo', dataNascimento);
    this.cadastroPublicoService.buscaVinculo(this.formataData(dataNascimento, 1)).subscribe(
      (data: any) => {
        this.listaVinculo = data;
        this.formCadastro.get('dadosPessoais').get('vinculo').enable();
        if (this.vinculoInicioCodigo) {
          console.log('lista de vinculos', this.listaVinculo);
          let vinculoAtual = this.listaVinculo.find(
            (vinculo) => vinculo.Codigo == this.vinculoInicioCodigo.toString()
          );
          if (vinculoAtual) {
            this.vinculoInicio = `${vinculoAtual.Codigo} - ${vinculoAtual.Descricao}`;
          }
        }
      },
      (error) => {
        this.formCadastro.get('dadosPessoais').get('vinculo').setValue('');
        this.listaVinculo = [];
        this.validaErro.retornoErro(error);
      }
    );
  }

  onNacionalidadeChange(): void {
    const { nacionalidade } = this.formCadastro.value.dadosPessoais;
    if (nacionalidade == this.nacionalidade.Brasileiro) {
      this.setCamposObrigatoriosBrasileiro();
    } else {
      this.setCamposObrigatoriosEstrangeiro();
    }
  }

  getFormsProfissaoEstrangeiro(): any {
    return {
      formProfissao: this.formCadastro.get('profissao'),
      formEstrangeiro: this.formCadastro.get('estrangeiro'),
    };
  }

  setCamposObrigatoriosBrasileiro(): void {
    const { formProfissao, formEstrangeiro } = this.getFormsProfissaoEstrangeiro();
    formProfissao.get('uf').setValidators([Validators.required]);
    formProfissao.get('municipio').setValidators([Validators.required]);
    formEstrangeiro.setValidators(null);
    this.updateValidacaoNacionalidade();
  }

  setCamposObrigatoriosEstrangeiro(): void {
    const { formProfissao, formEstrangeiro } = this.getFormsProfissaoEstrangeiro();
    formProfissao.get('uf').setValidators(null);
    formProfissao.get('municipio').setValidators(null);
    formEstrangeiro.setValidators([Validators.required]);
    this.updateValidacaoNacionalidade();
  }

  updateValidacaoNacionalidade(): void {
    const { formProfissao, formEstrangeiro } = this.getFormsProfissaoEstrangeiro();
    formProfissao.get('uf').updateValueAndValidity();
    formProfissao.get('municipio').updateValueAndValidity();
    formEstrangeiro.updateValueAndValidity();
  }

  onPrimeiroEmpregoChange(): void {
    const { primeiroEmprego } = this.formCadastro.get('emprego').value;
    const formEmprego = this.formCadastro.get('emprego');
    if (primeiroEmprego == '0') {
      formEmprego.get('outroEmprego').setValidators([Validators.required]);
      formEmprego.get('pagaContribuicao').setValidators([Validators.required]);
      formEmprego.get('dataExameMedico').setValidators([Validators.required]);
    } else {
      formEmprego.patchValue({
        outroEmprego: '',
        pagaContribuicao: '',
        cargaHoraria: '',
        dataExameMedico: '',
      });
      formEmprego.setValidators(null);
    }
    formEmprego.updateValueAndValidity();
  }

  onOutroEmpregoChange(): void {
    const { outroEmprego } = this.formCadastro.get('emprego').value;
    const formEmprego = this.formCadastro.get('emprego');
    if (outroEmprego == '1') {
      formEmprego.get('cargaHoraria').setValidators([Validators.required]);
    } else {
      formEmprego.get('cargaHoraria').setValue('');
      formEmprego.get('cargaHoraria').setValidators(null);
    }
    formEmprego.get('cargaHoraria').updateValueAndValidity();
  }

  onCepChange(): void {
    const { cep } = this.formCadastro.value.endereco;
    if (cep.length == 8) {
      this.loadingCep = true;
      this.cadastroPublicoService.buscarCep(cep).subscribe(
        (data: EnderecoViaCep) => {
          this.carregarEndereco(data);
          this.loadingCep = false;
          this.formCadastro
            .get('endereco')
            .get('cep')
            .setErrors(data.Valido ? null : { invalid: true });
        },
        () => {
          this.loadingCep = false;
        }
      );
    }
  }

  onCargoChange(): void {
    this.formCadastro
      .get('dadosPessoais')
      .get(this.tipoVinculo == '1' ? 'nivel' : 'referencia')
      .disable();
    this.loadingCargo = true;
    this.formCadastro.get('dadosPessoais').get('cargo').enable();
    this.formCadastro.get('dadosPessoais').get('funcao').enable();
    const { cargo, funcao } = this.formCadastro.value.dadosPessoais;
    let cargoFuncao: string = '';
    if (funcao != '') cargoFuncao = funcao;
    else if (cargo != '') cargoFuncao = cargo;
    this.buscarCargaHoraria(cargoFuncao);
    console.log(cargoFuncao);
    this.buscarNivel(cargoFuncao);
  }

  buscarCargaHoraria(funcao: string) {
    this.cadastroPublicoService.buscarCargaHoraria(funcao).subscribe({
      next: (data: any) => {
        this.listaCargaHoraria = data;
        if (this.listaCargaHoraria.length === 1) {
          this.formCadastro
            .get('dadosPessoais')
            .get('cargaHoraria')
            .setValue(this.listaCargaHoraria[0].Codigo);
        }
        console.log(this.listaCargaHoraria);
      },
      error: () => {},
    });
  }

  buscarNivel(cargoFuncao: string) {
    this.cadastroPublicoService.buscarNivel(cargoFuncao).subscribe(
      (data: any) => {
        this.listaNivel = data;
        this.formCadastro.get('dadosPessoais').get('nivel').enable();
        this.formCadastro.get('dadosPessoais').get('referencia').enable();
        this.loadingCargo = false;
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.loadingCargo = false;
      }
    );
  }
  onContratoChange(): void {
    const formContratoTemporario = this.formCadastro
      .get('dadosPagamento')
      .get('contratoTemporario');
    const formPeriodoExperiencia = this.formCadastro
      .get('dadosPagamento')
      .get('periodoExperiencia');
    const { dataInicio, dataFim } = formContratoTemporario.value;
    if (dataInicio != '' || dataFim != '') {
      formContratoTemporario.setValidators([Validators.required]);
      formPeriodoExperiencia.disable();
    } else {
      formContratoTemporario.setValidators(null);
      formPeriodoExperiencia.enable();
    }
    formContratoTemporario.updateValueAndValidity();
  }

  onPeriodoChange(): void {
    const formPeriodoExperiencia = this.formCadastro
      .get('dadosPagamento')
      .get('periodoExperiencia');
    const formContratoTemporario = this.formCadastro
      .get('dadosPagamento')
      .get('contratoTemporario');
    const { dataInicio, dataFim } = this.formCadastro.value.dadosPagamento.periodoExperiencia;
    if (dataInicio != '' || dataFim != '') {
      formPeriodoExperiencia.setValidators([Validators.required]);
      formContratoTemporario.disable();
    } else {
      formPeriodoExperiencia.setValidators(null);
      formContratoTemporario.enable();
    }
    formPeriodoExperiencia.updateValueAndValidity();
  }

  onCnhChange(): void {
    const formDadosPessoais = this.formCadastro.get('documentacao');
    const { cnhNumero, cnhCategoria, cnhDataValidade } = this.formCadastro.value.documentacao;
    if (cnhNumero != '' || cnhCategoria != '' || cnhDataValidade != '') {
      this.anexoCarteira = 1;
      formDadosPessoais.get('cnhNumero').setValidators([Validators.required]);
      formDadosPessoais.get('cnhCategoria').setValidators([Validators.required]);
      formDadosPessoais.get('cnhDataValidade').setValidators([Validators.required]);
    } else {
      formDadosPessoais.get('cnhNumero').setValidators(null);
      formDadosPessoais.get('cnhCategoria').setValidators(null);
      formDadosPessoais.get('cnhDataValidade').setValidators(null);
      this.temErro = false;
    }
    formDadosPessoais.get('cnhNumero').updateValueAndValidity();
    formDadosPessoais.get('cnhCategoria').updateValueAndValidity();
    formDadosPessoais.get('cnhDataValidade').updateValueAndValidity();
  }

  onRegistroChange(): void {
    const formDadosPessoais = this.formCadastro.get('documentacao');
    const { profissaoRegistro, profissaoOrgExpedidor, profissaoUf } =
      this.formCadastro.value.documentacao;
    if (profissaoRegistro != '' || profissaoOrgExpedidor != '' || profissaoUf != '') {
      formDadosPessoais.get('profissaoRegistro').setValidators([Validators.required]);
      formDadosPessoais
        .get('profissaoOrgExpedidor')
        .setValidators([
          Validators.required,
          Validators.pattern('[A-Za-záãâéêíóôõúçÁÃÂÉÊÍÓÔÕÚÇs]+$'),
        ]);
      formDadosPessoais.get('profissaoUf').setValidators([Validators.required]);
    } else {
      formDadosPessoais.get('profissaoRegistro').setValidators(null);
      formDadosPessoais.get('profissaoOrgExpedidor').setValidators(null);
      formDadosPessoais.get('profissaoUf').setValidators(null);
      this.temErro = false;
    }
    formDadosPessoais.get('profissaoRegistro').updateValueAndValidity();
    formDadosPessoais.get('profissaoOrgExpedidor').updateValueAndValidity();
    formDadosPessoais.get('profissaoUf').updateValueAndValidity();
  }

  onCertificadoMilitarChange(): void {
    const formDadosPessoais = this.formCadastro.get('documentacao');
    const { numeroCertificadoMilitar, csm, serieCertificadoMilitar } =
      this.formCadastro.value.documentacao;
    if (numeroCertificadoMilitar != '' || csm != '' || serieCertificadoMilitar != '') {
      formDadosPessoais.get('numeroCertificadoMilitar').setValidators([Validators.required]);
      formDadosPessoais.get('csm').setValidators([Validators.required]);
      formDadosPessoais.get('serieCertificadoMilitar').setValidators([Validators.required]);
    } else {
      formDadosPessoais.get('numeroCertificadoMilitar').setValidators(null);
      formDadosPessoais.get('csm').setValidators(null);
      formDadosPessoais.get('serieCertificadoMilitar').setValidators(null);
    }
    formDadosPessoais.get('numeroCertificadoMilitar').updateValueAndValidity();
    formDadosPessoais.get('csm').updateValueAndValidity();
    formDadosPessoais.get('serieCertificadoMilitar').updateValueAndValidity();
  }

  carregarEndereco(endereco: Endereco): void {
    const formEndereco = this.formCadastro.get('endereco');
    formEndereco.patchValue({
      logradouro: endereco.logradouro,
      cidade: endereco.cidade,
      bairro: endereco.bairro,
      complemento: endereco.complemento,
      numero: endereco.numero == 0 ? '' : endereco.numero,
      uf: endereco.uf,
    });
  }

  onVinculoChange(): void {
    this.formCadastro.get('dadosPessoais').get('funcao').disable();
    this.formCadastro.get('dadosPessoais').get('cargo').disable();
    this.formCadastro.get('dadosPessoais').get('sindicato').disable();
    const formDadosPessoais = this.formCadastro.get('dadosPessoais');
    const { vinculo } = this.formCadastro.value.dadosPessoais;
    const vinculoSelecionado: any[] = this.listaVinculo.filter((vin: any) => vin.Codigo == vinculo);
    console.log('vinculo selecionado', vinculoSelecionado);
    this.tipoVinculo = vinculoSelecionado[0].Tipo;
    if (vinculo !== '') {
      this.formCadastro.get('dadosPessoais').get('cargo').enable();
      this.formCadastro.get('dadosPessoais').get('funcao').enable();
    }

    this.atualizarValidacaoNomeacao(vinculoSelecionado[0].Grupo);
    this.buscarSindicato();
    this.carregarClassificacaoAto();
    this.atualizarValidacaoVinculo();
    formDadosPessoais.patchValue({
      cargo: '',
      nivel: '',
      funcao: '',
      referencia: '',
    });
  }

  atualizarValidacaoNomeacao(grupo: string) {
    const formNomeacao = this.formCadastro.get('dadosPagamento').get('nomeacao');
    if (grupo == '7' || grupo == '8') {
      formNomeacao.get('numeroProcesso').setValidators(null);
      this.tipoNomeacaoObrigatoria = true;
    } else {
      formNomeacao.get('numeroProcesso').setValidators([Validators.required]);
      this.tipoNomeacaoObrigatoria = false;
    }
    formNomeacao.get('numeroProcesso').updateValueAndValidity();
  }

  atualizarValidacaoVinculo(): void {
    const formProfissao = this.formCadastro.get('dadosPessoais');
    if (this.tipoVinculo == '1') {
      formProfissao.get('cargo').setValidators([Validators.required]);
      formProfissao.get('nivel').setValidators([Validators.required]);
      formProfissao.get('funcao').setValidators(null);
      formProfissao.get('referencia').setValidators(null);
      formProfissao.patchValue({ funcao: '', referencia: '' });
    } else if (this.tipoVinculo != '1') {
      formProfissao.get('cargo').setValidators(null);
      formProfissao.get('nivel').setValidators(null);
      formProfissao.get('funcao').setValidators([Validators.required]);
      formProfissao.get('referencia').setValidators([Validators.required]);
      formProfissao.patchValue({ cargo: '', nivel: '' });
    }
    formProfissao.get('cargo').updateValueAndValidity();
    formProfissao.get('nivel').updateValueAndValidity();
    formProfissao.get('funcao').updateValueAndValidity();
    formProfissao.get('referencia').updateValueAndValidity();
  }

  carregarClassificacaoAto(): void {
    console.log(this.formCadastro.value);
    this.formCadastro.get('dadosPessoais').get('vinculo').enable();
    const { vinculo } = this.formCadastro.value.dadosPessoais;
    this.cadastroPublicoService.buscaClassificacaoAto(vinculo).subscribe(
      (data: any) => (this.listaClassificacaoAto = data),
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  onUfChange(): void {
    const { uf } = this.formCadastro.value.profissao;
    this.loadingUf = true;
    this.formCadastro.get('profissao').get('municipio').disable();
    this.buscarMunicipio(uf);
  }

  buscarMunicipio(uf: string) {
    if (uf != '' && this.listaUf != undefined) {
      const codigoUf = this.listaUf.filter((elemento) => elemento.Descricao == uf)[0].Codigo;
      this.cadastroPublicoService.buscarMunicipio(codigoUf).subscribe(
        (data: any) => {
          this.listaMunicipios = data;
          this.loadingUf = false;
          this.formCadastro.get('profissao').get('municipio').enable();
        },
        (error) => {
          this.loadingUf = false;
          this.validaErro.retornoErro(error);
        }
      );
    }
  }

  onUfTrabalhoChange(): void {
    const { ufTrabalho } = this.formCadastro.value.documentacao;
    if (ufTrabalho != '') {
      this.cadastroPublicoService.buscarMunicipio(ufTrabalho).subscribe(
        (data: any) => (this.listaMunicipiosTrabalho = data),
        (error) => this.validaErro.retornoErro(error)
      );
    }
  }

  onHorarioChange(): void {
    const codigoHorarioSelecionado = this.formCadastro.value.dadosPessoais.horario;
    if (codigoHorarioSelecionado != '') document.getElementById('btHorarioModal').click();
    let { Dias: dias } = this.listaHorario.filter(
      (horario: any) => horario.Codigo === codigoHorarioSelecionado
    )[0] as any;
    dias = dias.map((dia) => {
      dia.EntradaAntesAlmoco = this.formatarHorario(dia.EntradaAntesAlmoco);
      dia.EntradaDepoisAlmoco = this.formatarHorario(dia.EntradaDepoisAlmoco);
      dia.SaidaAntesAlmoco = this.formatarHorario(dia.SaidaAntesAlmoco);
      dia.SaidaDepoisAlmoco = this.formatarHorario(dia.SaidaDepoisAlmoco);
      return dia;
    });
    this.horarioEscolhido = dias;

    let repouso = this.listaHorario.filter(
      (horario: any) => horario.Codigo === codigoHorarioSelecionado
    )[0] as any;
    this.formCadastro.get('dadosPessoais').get('repouso').setValue(repouso.Descanso);
  }

  onDataAtoChange(): void {
    const { data } = this.formCadastro.value.dadosPagamento.ato;
    if (data.length == 10) {
      this.formCadastro.get('dadosPagamento').get('ato').get('ano').setValue(data.substring(0, 4));
      this.formCadastro.get('dadosPagamento').get('ato').get('ano').updateValueAndValidity();
    }
  }

  formatarSeteDias(arr: any[]): any[] {
    const seteDias = new Array(this.listaDiasSemana.length).fill(null);
    for (let i = 0; i < 7 && i < arr.length; i++) {
      seteDias[i] = arr[i];
    }
    return seteDias;
  }

  formatarHorario(horario: string) {
    if (horario.length === 5) return horario;
    if (horario.trim() == '0') return horario.trim();
    horario = horario.trim().padStart(4, '0');
    return `${horario.substring(0, 2)}:${horario.substring(2, 4)}`;
  }

  limpaHorario(): void {
    this.formCadastro.get('dadosPessoais').get('horario').setValue('');
    this.formCadastro.get('dadosPessoais').get('horario').updateValueAndValidity();
  }

  alterarInput(): void {
    this.formCadastro.get('matricula').setValue(this.matricula);
  }

  formataData(data: string, formato?: number): string {
    const formta = data.split('-');
    let formatado = `${formta[2]}${formta[1]}${formta[0]}`;
    if (data == '') {
      return '';
    } else {
      if (formato == 1) {
        return `${formatado.substring(4, 8)}${formatado.substring(2, 4)}${formatado.substring(
          0,
          2
        )}`;
      }
      return `${formatado.substring(4, 8)}/${formatado.substring(2, 4)}/${formatado.substring(
        0,
        2
      )}`;
    }
  }

  formataDataEnvio(data: string): string {
    const formta = data.split('-');
    if (data == '') return '';
    else return `${formta[0]}${formta[1]}${formta[2]}`;
  }

  formataDataMontar(data: string): string {
    let format = data.split('/');
    return `${format[2]}-${format[1]}-${format[0]}`;
  }

  async submitCadastro(): Promise<void> {
    this.matricula = this.formCadastro.get('matricula').value;
    console.log(this.matricula);
    console.log(this.formCadastro.value);
    const etapa = this.step;
    const etapaValida = this.step == 5 ? true : this[`validaEtapa${etapa}`]();
    if (etapa === 5 && this.dependenteComponent.formDependente.dirty) {
      try {
        const swalalert = await Swal({
          title: 'Atenção',
          text: 'Existem dados não salvos no formulário de dependentes. Deseja prosseguir?',
          icon: 'warning',
          buttons: ['Não', 'Sim'],
        });
        if (swalalert) {
        } else {
          return;
        }
      } catch (error) {
        console.log('CATCH', error);
      }
    }

    if (etapa === 6) {
      const abrirIndicadores = await this.verificarIndicadoresCalculo();
      if (abrirIndicadores) return;
    }
    if (etapaValida) {
      const cadastro: Cadastro = this.montaCadastro();
      const matriculaFuncional = this.matriculaRota ? this.matriculaRota : this.matricula;
      if (etapa > 1) {
        cadastro.matricula = matriculaFuncional;
      }

      this.spinner.show();
      if (this.step != 5) {
        this.cadastroPublicoService[`inserirEtapa${etapa}`](cadastro, matriculaFuncional).subscribe(
          async (data) => {
            this.buscarVinculo();
            if (etapa === 1) {
              this.matriculaRota = data['Matricula'];
            } else {
              this.matriculaRota = data;
              console.log('data o clicar proximo', data);
            }
            this.matInicio = this.matriculaRota;
            this.spinner.hide();
            this.isCadastro = false;
            this.boolMatricula = true;
            if (etapa == 1) {
              this.matricula = !this.boolMatricula ? this.formCadastro.get('matricula').value : '0';
              this.numeroDeOrdem = data['NumeroDeOrdem'];
              this.documentosService
                .salvarAnexosMarcados({
                  EmailDestinatario: '',
                  NumeroDeOrdem: this.numeroDeOrdem,
                  EnviarEmail: false,
                })
                .subscribe(
                  (data: any) => {
                    console.log('data salvarAnexosMarcados', data);
                  },
                  (error) => {
                    new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
                  }
                );
              document.getElementById('btEnviarEmail').click();
            } else if (etapa >= 6) {
              this.spinner.hide();
              const filialPrevidenciaria = this.localStorage.FilialPrevidenciaria;
              if (filialPrevidenciaria) {
                this.route.navigate(['situacao-funcional/falecimento']);
              }
              this.matricula = data;
              console.log('matricula', data);
              return await Swal({
                title: 'Sucesso - Cadastro Finalizado',
                text: `
                    Colaborador: ${this.formCadastro.get('dadosPessoais').get('nome').value}
                    Matrícula: ${matriculaFuncional}
                    Número de Ordem: ${this.numeroDeOrdem}
                  `,
                icon: 'success',
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ['Ficar aqui', 'Ir para perfil'],
              }).then((result) => {
                if (result) this.route.navigate([`/perfil/${this.numeroDeOrdem}`]);
                // this.limparCampos();
              });
            } else if (etapa < 6) {
              this.matricula = data;

              console.log('matricula menor que 6', etapa);
              if (etapa == 4) {
                //validações anexos
                console.log('matricula igual a 4 ');
                if (this.listaAnexo) {
                  this.verificarEAtualizarAnexo('NOMEACAO', 'nomeacaoAnexo');
                  this.verificarEAtualizarAnexo('FOTO', 'fotoAnexo');
                  this.verificarEAtualizarAnexo('IDENTIDADE', 'identidadeAnexo');
                  this.verificarEAtualizarAnexo('CARTEIRA PROFISSIONAL', 'carteiraProfAnexo');
                  this.verificarEAtualizarAnexo('CPF', 'cpfAnexo');
                  this.verificarEAtualizarAnexo('PIS/PASEP', 'pisPasepAnexo');
                  this.verificarEAtualizarAnexo(
                    'CERTIDAO DE NASCIMENTO',
                    'certidaoDeNascimentoAnexo'
                  );
                  this.verificarEAtualizarAnexo(
                    'COMPROVANTE DE RESIDENCIA',
                    'comprovanteDeResidenciaAnexo'
                  );
                  if (cadastro.dadosBasicos.sexo != 2) {
                    this.verificarEAtualizarAnexo('CERTIFICADO MILITAR', 'certificadoMilitarAnexo');
                  }
                  if (cadastro.CarteiraDeHabilitacao.numero) {
                    this.verificarEAtualizarAnexo(
                      'CARTEIRA DE MOTORISTA',
                      'carteiraDeMotoristaAnexo'
                    );
                  }
                  if (cadastro.dadosBasicos.estadoCivil == 2) {
                    this.verificarEAtualizarAnexo(
                      'CERTIDAO DE CASAMENTO',
                      'certidaoDeCasamentoAnexo'
                    );
                  }
                }
                const EmailDestinatario = cadastro.contato.Email1;
                const correcoesSolicitadas = this.anexosDefault;
                const NumeroDeOrdem = cadastro.numeroDeOrdem;
                this.documentosService
                  .salvarAnexosMarcados({
                    EmailDestinatario,
                    correcoesSolicitadas,
                    NumeroDeOrdem,
                  })
                  .subscribe(
                    (data) => {
                      console.log('objeto pendencias atualizado no cadastro');
                    },
                    (error) => {
                      new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
                      this.spinner.hide('email');
                    }
                  );
                this.matriculaAux = this.matricula;
                this.buscarDependentes(this.matricula);
              }
            } else {
              this.anexoComponent.buscarAnexos();
            }
            this.step++;
            scrollTo(0, 0);
          },
          (error) => {
            this.validaErro.retornoErro(error);
            this.spinner.hide();
          }
        );
      } else {
        this.step++;
        scrollTo(0, 0);
        this.spinner.hide();
      }
    } else Swal('Atenção', 'Verifique os campos inválidos.', 'warning');
  }

  abiriModalIndicadores(): void {
    this.parametrosComponent.abrirModal();
  }
  async verificarIndicadoresCalculo(): Promise<boolean> {
    const flags = this.parametrosComponent.retornarValores();
    let flagSelecionada: boolean = false;

    Object.entries(flags).forEach((flags: any[]) => {
      if (flags[1] !== '0') flagSelecionada = true;
    });

    if (!flagSelecionada) {
      const abrirModal: boolean = await Swal({
        title: 'Atenção ',
        text: 'Deseja enviar o cadastro sem indicadores de cálculo?',
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ['Não', 'Sim'],
      });
      if (!abrirModal) this.parametrosComponent.abrirModal();
      else
        this.indicadoresCalculo = {
          ...this.parametrosComponent.retornarValores(),
        };
      return !abrirModal;
    } else {
      this.indicadoresCalculo = {
        ...this.parametrosComponent.retornarValores(),
      };
      return false;
    }
  }

  limparCampos(): void {
    this.formCadastro.markAsPristine();
    this.step = 1;
    this.matricula = '';
    this.matriculaRota = '';
    this.numeroDeOrdem = '';
    this.isCadastro = true;

    //ETAPA1
    this.formCadastro.get('matricula').setValue('');
    this.formCadastro.get('dadosPessoais').get('nome').setValue('');
    this.formCadastro.get('dadosPessoais').get('cpf').setValue('');
    this.formCadastro.get('dadosPessoais').get('dataNascimento').setValue('');
    this.formCadastro.get('dadosPessoais').get('setor').setValue('');
    this.formCadastro.get('dadosPessoais').get('dataAdmissao').setValue('');
    this.formCadastro.get('dadosPessoais').get('vinculo').setValue('');
    this.formCadastro.get('dadosPessoais').get('cargo').setValue('');
    this.formCadastro.get('dadosPessoais').get('nivel').setValue('');
    this.formCadastro.get('dadosPessoais').get('funcao').setValue('');
    this.formCadastro.get('dadosPessoais').get('referencia').setValue('');
    this.formCadastro.get('dadosPessoais').get('sindicato').setValue('');
    this.formCadastro.get('dadosPessoais').get('cargaHoraria').setValue('');
    this.formCadastro.get('dadosPessoais').get('tipoFolha').setValue('');
    this.formCadastro.get('dadosPessoais').get('horario').setValue('');
    this.formCadastro.get('dadosPessoais').get('diasUteis').setValue('');
    this.formCadastro.get('dadosPessoais').get('repouso').setValue('');

    //ETAPA2
    this.formCadastro.get('emprego').get('primeiroEmprego').setValue('');
    this.formCadastro.get('emprego').get('outroEmprego').setValue('');
    this.formCadastro.get('emprego').get('pagaContribuicao').setValue('');
    this.formCadastro.get('emprego').get('cargaHoraria').setValue('');
    this.formCadastro.get('emprego').get('dataExameMedico').setValue('');
    this.formCadastro.get('emprego').get('cargaHoraria').setValue('');
    this.formCadastro.get('documentacao').get('identidade').setValue('');
    this.formCadastro.get('documentacao').get('idDataExpedicao').setValue('');
    this.formCadastro.get('documentacao').get('idOrgaoExpedido').setValue('');
    this.formCadastro.get('documentacao').get('idUf').setValue('');
    this.formCadastro.get('documentacao').get('carteiraProfissional').setValue('');
    this.formCadastro.get('documentacao').get('serieCtps').setValue('');
    this.formCadastro.get('documentacao').get('ufCarteiraTrabalho').setValue('');
    this.formCadastro.get('documentacao').get('tituloEleitor').setValue('');
    this.formCadastro.get('documentacao').get('zonaEleitoral').setValue('');
    this.formCadastro.get('documentacao').get('secaoEleitoral').setValue('');
    this.formCadastro.get('documentacao').get('ufEleitoral').setValue('');
    this.formCadastro.get('documentacao').get('numeroCertificadoMilitar').setValue('');
    this.formCadastro.get('documentacao').get('csm').setValue('');
    this.formCadastro.get('documentacao').get('serieCertificadoMilitar').setValue('');
    this.formCadastro.get('documentacao').get('profissaoRegistro').setValue('');
    this.formCadastro.get('documentacao').get('profissaoOrgExpedidor').setValue('');
    this.formCadastro.get('documentacao').get('profissaoUf').setValue('');
    this.formCadastro.get('documentacao').get('cnhNumero').setValue('');
    this.formCadastro.get('documentacao').get('cnhCategoria').setValue('');
    this.formCadastro.get('documentacao').get('cnhDataValidade').setValue('');
    this.formCadastro.get('documentacao').get('numeroPis').setValue('');
    // this.formCadastro.get('documentacao').get('dataEmissaoPis').setValue('');
    // this.formCadastro.get('documentacao').get('agenciaPis').setValue('');

    //ETAPA3
    this.formCadastro.get('endereco').get('cep').setValue('');
    this.formCadastro.get('endereco').get('logradouro').setValue('');
    this.formCadastro.get('endereco').get('numero').setValue('');
    this.formCadastro.get('endereco').get('complemento').setValue('');
    this.formCadastro.get('endereco').get('bairro').setValue('');
    this.formCadastro.get('endereco').get('cidade').setValue('');
    this.formCadastro.get('endereco').get('uf').setValue('');
    this.formCadastro.get('contato').get('emailPrincipal').setValue('');
    this.formCadastro.get('contato').get('emailSecundario').setValue('');
    this.formCadastro.get('contato').get('celular').setValue('');
    this.formCadastro.get('contato').get('telefone').setValue('');
    this.formCadastro.get('contatosSecundarios').get('celularSecundario').setValue('');
    this.formCadastro.get('contatosSecundarios').get('tipoSecundario').setValue('');
    this.formCadastro.get('contatosSecundarios').get('descSecundario').setValue('');

    //ETAPA4
    this.formCadastro.get('profissao').get('sexo').setValue('');
    this.formCadastro.get('profissao').get('nomeMae').setValue('');
    this.formCadastro.get('profissao').get('nomePai').setValue('');
    this.formCadastro.get('profissao').get('tipoSangue').setValue('');
    this.formCadastro.get('profissao').get('corPele').setValue('');
    this.formCadastro.get('profissao').get('estadoCivil').setValue('');
    this.formCadastro.get('profissao').get('grauInstrucao').setValue('');
    this.formCadastro.get('profissao').get('nacionalidade').setValue('');
    this.formCadastro.get('profissao').get('uf').setValue('');
    this.formCadastro.get('profissao').get('municipio').setValue('');
    // this.formCadastro.get('profissao').get('estabilidade').setValue('');
    // this.formCadastro.get('profissao').get('tipoFrequencia').setValue('');

    //ETAPA5
    this.formCadastro.get('dadosPagamento').get('contaCorrenteCredito').get('agencia').setValue('');
    this.formCadastro.get('dadosPagamento').get('contaCorrenteCredito').get('banco').setValue('');
    this.formCadastro
      .get('dadosPagamento')
      .get('contaCorrenteCredito')
      .get('operacao')
      .setValue('');
    this.formCadastro
      .get('dadosPagamento')
      .get('contaCorrenteCredito')
      .get('contaCorrente')
      .setValue('');
    this.formCadastro.get('dadosPagamento').get('adts').get('dataExercicio').setValue('');
    this.formCadastro.get('dadosPagamento').get('adts').get('opcao').setValue('');
    this.formCadastro
      .get('dadosPagamento')
      .get('contratoTemporario')
      .get('dataInicio')
      .setValue('');
    this.formCadastro.get('dadosPagamento').get('contratoTemporario').get('dataFim').setValue('');
    this.formCadastro
      .get('dadosPagamento')
      .get('periodoExperiencia')
      .get('dataInicio')
      .setValue('');
    this.formCadastro.get('dadosPagamento').get('periodoExperiencia').get('dataFim').setValue('');
    this.formCadastro.get('dadosPagamento').get('nomeacao').get('classificacaoAto').setValue('');
    this.formCadastro.get('dadosPagamento').get('nomeacao').get('numeroProcesso').setValue('');
    this.formCadastro
      .get('dadosPagamento')
      .get('descontosCompulsorios')
      .get('impostoRenda')
      .setValue('');
    this.formCadastro
      .get('dadosPagamento')
      .get('descontosCompulsorios')
      .get('previdencia')
      .setValue('');
    this.formCadastro.get('dadosPagamento').get('descontosCompulsorios').get('pensao').setValue('');
    this.formCadastro.get('dadosPagamento').get('diarioOficial').get('numero').setValue('');
    this.formCadastro.get('dadosPagamento').get('diarioOficial').get('data').setValue('');
    this.formCadastro.get('dadosPagamento').get('ato').get('numero').setValue('');
    this.formCadastro.get('dadosPagamento').get('ato').get('ano').setValue('');
    this.formCadastro.get('dadosPagamento').get('ato').get('data').setValue('');
    this.formCadastro.get('dadosPagamento').get('bloqueadoConsignado').setValue('');
    this.formCadastro.get('dadosPagamento').get('estabilidade').setValue('');
    this.formCadastro.get('dadosPagamento').get('tipoFrequencia').setValue('');
    this.temErro = false;
  }

  validaEtapa1(): boolean {
    const dadosPessoaisStatus = this.formCadastro.get('dadosPessoais').valid;
    if (dadosPessoaisStatus) {
      this.temErro = false;
      return true;
    }
    this.temErro = true;
    return false;
  }

  validaEtapa4(): boolean {
    const documentooStatus = this.formCadastro.get('documentacao').valid;
    if (documentooStatus) return true;
    this.temErro = true;
    return false;
  }

  validaEtapa3(): boolean {
    const enderecoStatus = this.formCadastro.get('endereco').valid;
    const contatoStatus = this.formCadastro.get('contato').valid;
    if (enderecoStatus && contatoStatus) {
      this.temErro = false;
      return true;
    }
    this.temErro = true;
    return false;
  }

  validaEtapa2(): boolean {
    const profissaoStatus = this.formCadastro.get('profissao').valid;
    this.temErro = !profissaoStatus;
    return profissaoStatus;
  }

  validaEtapa5() {
    if (this.dependenteComponent) {
      return this.dependenteComponent.verificaTipoDependente();
    }
    return false;
  }

  validaEtapa6(): boolean {
    const dadosPagamentoStatus = this.formCadastro.get('dadosPagamento').valid;
    this.temErro = !dadosPagamentoStatus;
    return dadosPagamentoStatus;
  }

  montaCadastro() {
    const {
      dadosPessoais,
      estrangeiro,
      emprego,
      documentacao,
      dadosPagamento,
      profissao,
      endereco,
      contato,
    } = this.formCadastro.getRawValue();
    this.nomeInicio = dadosPessoais.nome;
    this.matInicio = this.matricula;
    this.cpfInicio = dadosPessoais.cpf;
    this.vinculoInicioCodigo = dadosPessoais.vinculo;
    if (this.listaVinculo) {
      let vinculoAtual = this.listaVinculo.find(
        (vinculo) => vinculo.Codigo == dadosPessoais.vinculo
      );
      if (vinculoAtual) {
        this.vinculoInicio = `${vinculoAtual.Codigo} - ${vinculoAtual.Descricao}`;
      }
    } else {
      this.buscarVinculo();
    }
    return {
      matricula: this.matricula,
      numeroDeOrdem: this.numeroDeOrdem,
      IsCadastro: this.isCadastro,
      CelularesSecundarios: this.listaContatosSecundarios,
      codigoDiasUteis: dadosPessoais.diasUteis,
      dadosPessoais: {
        nomeCompleto: dadosPessoais.nome,
        cpf: dadosPessoais.cpf,
        dataNascimento: new Data(dadosPessoais.dataNascimento),
        // {
        //   SetData: dadosPessoais.dataNascimento,
        //   ToDate: this.formataData(dadosPessoais.dataNascimento),
        //   Empty: false,
        //   IsDate: true,
        // },
        enderecoEmpresa: {
          Setor: dadosPessoais.setor,
          CentroDeCusto: 'Centro de custo', //setado manualmente
        },
        jornadaDeTrabalho: {
          cargaHoraria: dadosPessoais.cargaHoraria,
          descanso: dadosPessoais.repouso,
          horario: dadosPessoais.horario,
          turno: '',
        },
        sindicato: dadosPessoais.sindicato,
        ocupacao: {
          dataAdmissao: new Data(dadosPessoais.dataAdmissao),
          // {
          //   SetData: this.formataDataMontar(this.formataData(dadosPessoais.dataAdmissao)),
          //   ToDate: dadosPessoais.dataAdmissao,
          //   Empty: false,
          //   IsDate: true,
          // },
          cargo: dadosPessoais.cargo,
          nivel: dadosPessoais.nivel.replace(/\s/g, ''),
          funcao: dadosPessoais.funcao,
          referencia: dadosPessoais.referencia.trim(),
          vinculo: dadosPessoais.vinculo,
        },
        tipoFolha: dadosPessoais.tipoFolha,
      },
      dadosBasicos: {
        sexo: profissao.sexo,
        nomeDaMae: profissao.nomeMae,
        nomeDoPai: profissao.nomePai,
        tipoSanguineo: profissao.tipoSangue,
        corDePele: profissao.corPele,
        estadoCivil: profissao.estadoCivil,
        grauDeInstrucao: profissao.grauInstrucao,
        nacionalidade: profissao.nacionalidade,
        ufNaturalidade: profissao.uf,
        municipioNaturalidade: profissao.municipio,
        funcao: dadosPessoais.funcao,
      },
      dadosEstrangeiro: {
        ...estrangeiro,
        dataChegada: new Data(estrangeiro.dataChegada),
      },
      CarteiraDeHabilitacao: {
        categoriaCnh: documentacao.cnhCategoria,
        dataValidadeCnh: new Data(documentacao.cnhDataValidade),
        numero: documentacao.cnhNumero,
        serie: '',
      },
      CarteiraTrabalhista: {
        numero: documentacao.carteiraProfissional,
        serieCTPS: documentacao.serieCtps,
        ufCTPS: documentacao.ufCarteiraTrabalho,
      },
      CertificadoMilitar: {
        numero: documentacao.numeroCertificadoMilitar,
        csm: documentacao.csm,
        serie: documentacao.serieCertificadoMilitar,
      },
      Identidade: {
        dataDeExpedicao: new Data(documentacao.idDataExpedicao),
        orgaoEmissor: documentacao.idOrgaoExpedido,
        registroGeral: documentacao.identidade,
        uF: documentacao.idUf,
      },
      Pis: {
        // dataDeEmissao: new Data(this.formataData(documentacao.dataEmissaoPis)),
        numero: documentacao.numeroPis,
        // agencia: documentacao.agenciaPis,
      },
      Profissao: {
        orgaoExpedidor: documentacao.profissaoOrgExpedidor,
        registro: documentacao.profissaoRegistro,
        uF: documentacao.profissaoUf,
      },
      TituloEleitor: {
        secaoEleitoral: documentacao.secaoEleitoral,
        numero: documentacao.tituloEleitor,
        ufEleitoral: documentacao.ufEleitoral,
        zonaEleitoral: documentacao.zonaEleitoral,
      },
      // emprego: {
      //   cargaHoraria: emprego.cargaHoraria,
      //   dataExameMedico:
      //     emprego.dataExameMedico != ""
      //       ? new Data(this.formataData(emprego.dataExameMedico))
      //       : null,
      //   outroEmprego: emprego.outroEmprego == "1" ? true : false,
      //   pagaContribuicao: emprego.pagaContribuicao === "1" ? 1 : 0,
      //   primeiroEmprego: emprego.primeiroEmprego == "1" ? true : false,
      // },
      estabilidade: dadosPagamento.estabilidade,
      tipoFrequencia: dadosPagamento.tipoFrequencia,
      contato: {
        DDDTelefone: contato.telefone.substring(0, 2),
        Telefone: contato.telefone.substring(2, 11),
        DDDCelular: contato.celular.substring(0, 2),
        Celular: contato.celular.substring(2, 11),
        Email1: contato.emailPrincipal,
        Email2: contato.emailSecundario,
      },
      endereco: {
        bairro: endereco.bairro,
        cep: endereco.cep,
        cidade: endereco.cidade,
        complemento: endereco.complemento,
        logradouro: endereco.logradouro,
        numero: Number(endereco.numero),
        uf: endereco.uf,
      },
      adts: {
        dataUltimoBeneficioAdquirido: new Data(dadosPagamento.adts.dataExercicio),
        opcao: dadosPagamento.adts.opcao,
      },
      nomeacao: {
        numeroProcesso: dadosPagamento.nomeacao.numeroProcesso,
        classificaoAto: dadosPagamento.nomeacao.classificacaoAto,
        ato: {
          numero: dadosPagamento.ato.numero,
          anoAto: dadosPagamento.ato.ano,
          data: new Data(dadosPagamento.ato.data),
        },
        diarioOficial: {
          numero: dadosPagamento.diarioOficial.numero,
          dataDiarioOficial: new Data(dadosPagamento.diarioOficial.data),
        },
      },
      descontosCompulsorios: {
        previdencia: dadosPagamento.descontosCompulsorios.previdencia == 0 ? false : true,
        impostoRenda: dadosPagamento.descontosCompulsorios.impostoRenda == 0 ? false : true,
        pensao: dadosPagamento.descontosCompulsorios.pensao == 0 ? false : true,
      },
      contaBancaria: {
        agencia: dadosPagamento.contaCorrenteCredito.agencia,
        banco: dadosPagamento.contaCorrenteCredito.banco,
        operacao: dadosPagamento.contaCorrenteCredito.operacao,
        numeroConta: dadosPagamento.contaCorrenteCredito.contaCorrente,
      },
      contratoTemporario:
        dadosPagamento.contratoTemporario.dataInicio == ''
          ? null
          : {
              inicio: {
                SetData: dadosPagamento.contratoTemporario.dataInicio,
                ToDate: this.formataData(dadosPagamento.contratoTemporario.dataInicio),
                Empty: false,
                IsDate: true,
              },
              final: {
                SetData: dadosPagamento.contratoTemporario.dataFim,
                ToDate: this.formataData(dadosPagamento.contratoTemporario.dataFim),
                Empty: false,
                IsDate: true,
              },
            },
      Flags: this.indicadoresCalculo,
      periodoExperiencia:
        dadosPagamento.periodoExperiencia.dataInicio == ''
          ? null
          : {
              inicio: {
                SetData: dadosPagamento.periodoExperiencia.dataInicio,
                ToDate: this.formataData(dadosPagamento.periodoExperiencia.dataInicio),
                Empty: false,
                IsDate: true,
              },

              final: {
                SetData: dadosPagamento.periodoExperiencia.dataFim,
                ToDate: this.formataData(dadosPagamento.periodoExperiencia.dataFim),
                Empty: false,
                IsDate: true,
              },
            },
      bloquearConsignado: dadosPagamento.bloqueadoConsignado,
      Anexos: this.step == 4 ? this.anexoComponent.retornarValores() : [],
    };
  }
  montaUsuario(numeroDeOrdem: string): Usuario {
    const { dadosPessoais, contato } = this.formCadastro.getRawValue();
    const {
      FilialAtual: filial,
      NomeFilialAtual: nomeFilial,
      ClienteId: codigoCliente,
    } = this.localStorage;
    return {
      Email: contato.emailPrincipal,
      Matricula: parseInt(this.matriculaRota),
      Cpf: dadosPessoais.cpf,
      Nome: dadosPessoais.nome,
      EmpresaFilial: filial.toString(),
      NomeEmpresaFilial: nomeFilial,
      NumeroDeOrdem: Number(numeroDeOrdem),
      CodigoCliente: codigoCliente.toString(),
    };
  }
  uppercase(event) {
    event.target.value = event.target.value.toUpperCase();
  }
  mask(event): void {
    //Colocar no html depois  mask='{{contaMask}}' (keydown)="mask($event)"
    const conta = this.formCadastro.value.dadosPagamento.contaCorrenteCredito.contaCorrente;
    if (conta.length >= 6) {
      if (conta.length == 14) {
        this.contaMask = '0'.repeat(14) + '-0';
      } else {
        this.contaMask = '0'.repeat(conta.length) + '-0';
      }
    } else {
      this.contaMask = '00000-0';
    }
  }

  onSexoChange() {
    const sexo = this.formCadastro.get('profissao').get('sexo').value;
    if (sexo === '1' && this.maiorIdade()) this.setMilitarObrigatorio();
    else this.setMilitarOpcional();
  }

  setMilitar(validator: ValidatorFn[] | null) {
    const campos = ['numeroCertificadoMilitar', 'serieCertificadoMilitar', 'csm'];
    campos.forEach((campo) => {
      this.formCadastro.get('documentacao').get(campo).setValidators(validator);
      this.formCadastro.get('documentacao').get(campo).updateValueAndValidity();
    });
  }

  setMilitarObrigatorio() {
    this.setMilitar([Validators.required]);
    this.camposMilitarObrigatorios = true;
  }

  setMilitarOpcional() {
    this.setMilitar(null);
    this.camposMilitarObrigatorios = false;
  }

  maiorIdade(): boolean {
    const { dataNascimento } = this.formCadastro.get('dadosPessoais').value;
    const hoje = new Date();
    const dataNasc = new Date(dataNascimento);
    const idade = hoje.getFullYear() - dataNasc.getFullYear();
    return (
      idade > 18 ||
      (idade === 18 && hoje.getMonth() > dataNasc.getMonth()) ||
      (idade === 18 &&
        hoje.getMonth() === dataNasc.getMonth() &&
        hoje.getDate() >= dataNasc.getDate())
    );
  }

  verificaData(dataNascimento: AbstractControl, idDataExpedicao: AbstractControl) {
    if (dataNascimento.value.length == 8 && idDataExpedicao.value.length == 8) {
      const dataNascimentoInvertida = Number(
        `${dataNascimento.value.substr(4, 4)}${dataNascimento.value.substr(
          2,
          2
        )}${dataNascimento.value.substr(0, 2)}`
      );
      const idDataExpedicaoInvertida = Number(
        `${idDataExpedicao.value.substr(4, 4)}${idDataExpedicao.value.substr(
          2,
          2
        )}${idDataExpedicao.value.substr(0, 2)}`
      );
      if (dataNascimentoInvertida >= idDataExpedicaoInvertida) {
        idDataExpedicao.setValue('');
        Swal(
          'Atenção',
          'A data de expedição da carteira de identidade deve ser posterior à data de nascimento.',
          'warning'
        );
      }
    }
  }

  navegarPara(url) {
    this.route.navigate([url]);
  }

  verificarEAtualizarAnexo(descricao: string, label: string): void {
    if (this.listaAnexo.findIndex((item) => item.Arquivos[0].DescricaoTRG === descricao) !== -1) {
      this.anexosDefault.documentosAnexados[label] = 0;
    }
  }

  recebeListaAnexo(lista: Array<Anexo>) {
    this.listaAnexo = lista;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
    console.log('local storage', LocalStorage);
  }
}
