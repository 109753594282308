import {
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import Swal from 'sweetalert';
import { cpfValidator } from 'src/app/service/Validators/cpfValidator';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { ErroService } from '../../service/erros/erro.service';
import { PensaoAlimenticiaService } from './pensao-alimenticia.service';
import { Data } from 'src/app/service/interfaces/Data';
import { Endereco } from 'src/app/service/interfaces/Endereco';
import { Telefone } from 'src/app/service/interfaces/Telefone';
import { switchAll, take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  RepresentanteLegalComponent,
  RepresentanteLegal,
} from '../representante-legal/representante-legal.component';
import { Anexo, AnexoComponent } from '../anexo/anexo.component';
import { CampoChave } from 'src/app/service/global/campo-chave';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { maxDateValidator } from 'src/app/service/Validators/dataFormatoValidator';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoService } from '../anexo/anexo.service';
import { zeroValidator } from 'src/app/service/Validators/zeroValidator';

@Component({
  selector: 'app-pensao-alimenticia',
  templateUrl: './pensao-alimenticia.component.html',
  styleUrls: ['./pensao-alimenticia.component.css'],
})
export class PensaoAlimenticiaComponent implements OnInit {
  @Output() emitireventoFilho = new EventEmitter();
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild('listaVaziaVerba', { static: true }) listaVaziaTemplate: any;
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;

  @ViewChild(RepresentanteLegalComponent, { static: false })
  representanteLegalComponent: RepresentanteLegalComponent;
  listaRepresentanteSubject: BehaviorSubject<RepresentanteLegal[]> = new BehaviorSubject<any[]>([]);

  listaRepresentante$: Observable<RepresentanteLegal[]> =
    this.listaRepresentanteSubject.asObservable();

  listaAgencias: any;
  listaOperacoes: any;
  cpfExiste: boolean = false;
  localStorage: LocalStorage;
  modoEdicaoIndex: number = -1;
  formPensaoAlimenticia: FormGroup;
  listaPensionista: PensaoAlimento[];
  listaTipoCalculo: Parametro[];
  listaTipoPensao: Parametro[];
  listaVerba: Parametro[];
  listaAuxVerbasRemovidas: Parametro[];
  listaUf: any[];
  listaMunicipio: any[];
  listaLotacao: any[];
  listaBanco: any[];
  listaOperacao: any[];
  listaAnexo: Anexo[];
  pensaolOutput: informacaoBasicaPensao;
  pensionistaSelecionado: PensaoAlimento = null;
  etapa: number = 0;
  matricula = null;
  verbaNome: string = '';
  verbaDesconto: string;
  contaMask: string;
  tipoUsuario: string;
  marcarTodos: boolean = false;
  loadingCep: boolean = false;
  carregandoPensionista: boolean = false;
  tipoCarregamento: boolean = false;
  temErro: boolean = false;
  abrirModalRepLegal: boolean = false;
  clientePublico: boolean;
  loadingVerbas: boolean = false;
  mostrarVerbas: boolean = false;
  verbaNaBase = false;
  anexosCarregados: boolean = false;
  constructor(
    public fb: FormBuilder,
    private pensaoAlimenticiaService: PensaoAlimenticiaService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private anexoService: AnexoService,
    private route: Router
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.clientePublico = this.localStorage.ClientePublico === true;

    this.formPensaoAlimenticia = this.fb.group({
      matricula: [''],
      nomeFuncionario: [{ value: '', disabled: true }],
      vinculo: [{ value: '', disabled: true }],
      pensionista: [{ value: '', disabled: true }],
      informacaoBasica: this.fb.group({
        codigo: [{ value: '', disabled: true }],
        nomePensionista: ['', [Validators.required]],
        tipoPensao: ['', [Validators.required]], //, Validators.min(1)
        cpf: ['', [cpfValidator, Validators.required]],
        identidade: ['', [Validators.required, Validators.pattern(/^[^@#$%&¨*=_<>?|\\\{}/+_]+$/)]],
        orgaoExpedidor: [
          '',
          [Validators.required, Validators.pattern(/^[^@#$%&¨*=_<>?|\\\{}/+_]+$/)],
        ],
        ufIdentidade: ['', [Validators.required]],
        telefone: ['', [Validators.required, Validators.pattern(/^(\d{2}9\d*)$/)]],
        dataNascimento: [
          '',
          [
            Validators.required,
            maxDateValidator(
              new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            ),
          ],
        ],
        logradouro: [{ value: '', disabled: true }, ,],
        numeroLogradouro: [''],
        complementoEndereco: ['', [Validators.pattern(/^[^@#$%&¨*=_<>?|\\\{}/+_]+$/)]],
        bairro: [{ value: '', disabled: true }],
        cidade: [{ value: '', disabled: true }],
        ufEndereco: [{ value: '', disabled: true }],
        cep: [''],
        ddd: [''],
      }),
      dadosPagamento: this.fb.group({
        dataInicialPgto: ['', [Validators.required, Validators.min(1900 - 0o1 - 0o1)]],
        dataFinalPgto: ['', [, Validators.min(1900 - 0o1 - 0o1)]],
        processoPgto: [
          '',
          [Validators.required, Validators.pattern(/^[^@#$%&¨*=_<>?|\\\{}/+_]+$/)],
        ],

        radioPgto: ['', Validators.required],
        banco: [''],
        operacao: [''],
        agencia: [''],
        conta: ['', [Validators.pattern(/^[^@#$%&¨*=_<>?|\\\{}/+_]+$/)]],

        codigoRepresentanteLegal: [{ value: '', disabled: true }],
        tipoRepresentanteLegal: [{ value: '', disabled: true }],
        nomeRepresentanteLegal: [{ value: '', disabled: true }],
        dataInicioRepLegal: [''],
        dataFimRepLegal: [''],
        dataVencimentoRepLegal: [''],
        processoRepLegal: ['', [Validators.pattern(/^[^@#$%&¨*=_<>?|\\\{}/+_]+$/)]],
        bancoRepLegal: [{ value: '', disabled: true }],
        operacaoRepLegal: [{ value: '', disabled: true }],
        agenciaRepLegal: [{ value: '', disabled: true }],
        contaRepLegal: [{ value: '', disabled: true }],
        verbaDesconto: [''],

        tipoCalculo: ['', Validators.required],
        percentual: ['', Validators.required],
        qtdSalarioFamilia: [''],
        valor: [''],
        radioDecimoTerceiro: ['', Validators.required],
        radioFerias: ['', Validators.required],
        radioRescisao: ['', Validators.required],
        trct: [''],
        fgts: [''],
        verba: [{ value: '', disabled: true }],
        transacao: [''],
        verbasRemovidas: new FormArray([]),
        removerVerbaCheckBox: [false],
      }),
    });

    const navigation = this.route.getCurrentNavigation();
    if (navigation.extras.state) {
      console.log(navigation);
      this.matricula = navigation.extras.state.usuario.matricula;
      this.formPensaoAlimenticia.get('matricula').setValue(this.matricula);
      this.buscarColaborador(this.matricula);
    }
  }
  // @ViewChild( CampoChave ,{ static: false }) campoChave: CampoChave;
  ngOnInit(): void {
    this.localStorage = this.decryptLocalStorage();

    this.formPensaoAlimenticia.get('dadosPagamento').get('operacao').setValue('');

    if (!this.clientePublico) {
      this.formPensaoAlimenticia.get('dadosPagamento').get('processoPgto').setValidators(null);
    }
    this.buscarUf();
    this.buscarBanco();
    this.buscarTipoPensionista();
    this.buscarTipoCalculo();
    this.buscarLotacao();
    this.maskConta();
    console.log('listanexo', this.listaAnexo);
  }

  removerAcentos(evento): void {
    evento.target.value = evento.target.value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[´`'ˆ¨]+/g, '');
  }

  buscarAgencias(event): void {
    this.spinner.show();
    const nomeBanco = event.target.value;
    this.pensaoAlimenticiaService.buscarAgencias(nomeBanco).subscribe(
      (data) => {
        this.listaAgencias = data;
        this.buscarOperacoes(nomeBanco);
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarOperacoes(nomeBanco: string): void {
    this.spinner.show();
    this.pensaoAlimenticiaService.buscarOperacoes(nomeBanco).subscribe(
      (data) => {
        this.listaOperacoes = data;
        this.spinner.hide();
        console.log(this.listaOperacoes);
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

 

  matriculaListener(evento) {
    this.matricula = evento.Matricula;
    this.formPensaoAlimenticia.get('matricula').setValue(this.matricula);
    this.formPensaoAlimenticia.get('matricula').disable();
    this.buscarColaborador(this.matricula);
  }

  buscarColaborador(matricula): void {
    this.novoPensionista();
    if (matricula === '') {
      new AlertaModal('erro', 'Atenção', 'O campo matrícula precisa ser preenchido.', 'p');
    } else {
      this.formPensaoAlimenticia.get('nomeFuncionario').setValue('');
      this.carregandoPensionista = true;
      this.pensaoAlimenticiaService.buscarColaborador(matricula).subscribe(
        (data: any) => {
          this.limparCampos();
          if (data.Nome !== '') {
            console.log('data recebida', data);
            this.formPensaoAlimenticia.get('nomeFuncionario').setValue(data.Nome);
            this.formPensaoAlimenticia.get('vinculo').setValue(data.Vinculo);
            this.buscarPensionistas();
          } else {
            new AlertaModal(
              'erro',
              'Atenção',
              'A matrícula digitada não existe para esta empresa filial.',
              'p'
            );
            this.carregandoPensionista = false;
          }
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.carregandoPensionista = false;
        }
      );
    }
  }

  buscarBanco() {
    this.pensaoAlimenticiaService.buscarBanco().subscribe(
      (data: any) => {
        this.listaBanco = data;
        this.listaBanco.forEach((banco) => {
          banco.Codigo = banco.Codigo.trim();
        });
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  buscarLotacao(): void {
    this.pensaoAlimenticiaService.buscarLotacao().subscribe(
      (data: any) => (this.listaLotacao = data.sort(this.ordenarListas)),
      (error) => this.validaErro.retornoErro(error)
    );
  }

  buscarUf(): void {
    this.pensaoAlimenticiaService.buscarUf().subscribe(
      (data: any[]) => {
        this.listaUf = data;
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarVerbas(): void {
    this.listaVerba = [];
    if (this.verbaNome.trim() != '') {
      this.loadingVerbas = true;
      let verbasRemovidas: FormArray = this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('verbasRemovidas') as FormArray;
      const matricula = this.formPensaoAlimenticia.get('matricula').value;
      let existeListaAux = null;
      this.pensaoAlimenticiaService.buscarVerbas(this.verbaNome, matricula).subscribe(
        (data: any) => {
          if (data.length > 0) {
            const listaVerbasValidas = data.filter(
              (verbaValida) =>
                !verbasRemovidas.value.some((item) => item.Codigo === verbaValida.Codigo)
            );
            this.listaVerba = listaVerbasValidas;
            console.log('lista verba', this.listaVerba);
          } else {
            existeListaAux = this.listaAuxVerbasRemovidas.find(
              (item) => item.Codigo == this.verbaNome
            );
            console.log(existeListaAux);
          }
          this.verbaNaBase = verbasRemovidas.value.some((item) => item.Codigo == this.verbaNome);
          console.log('verba Na Base', this.verbaNaBase);
          if (!this.verbaNaBase && existeListaAux) {
            this.listaVerba.push(existeListaAux);
          }
          this.loadingVerbas = false;
          this.mostrarVerbas = true;
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.loadingVerbas = false;
          this.mostrarVerbas = false;
        }
      );
      console.log('lista verbas', this.listaVerba);
    }
  }

  buscarVerbaDesconto() {
    const matricula = this.formPensaoAlimenticia.get('matricula').value;
    this.pensaoAlimenticiaService.buscarVerbaDesconto(matricula).subscribe(
      (data: Parametro) => {
        this.verbaDesconto = data.Codigo;
        this.formPensaoAlimenticia.get('dadosPagamento').get('verbaDesconto').setValue(data.Codigo);
        this.formPensaoAlimenticia.get('dadosPagamento').get('verbaDesconto').disable();
        if (this.verbaDesconto === '') {
          new AlertaModal(
            'erro',
            'Atenção',
            'Este colaborador não possui verba para criar um novo pensionista!',
            'p'
          );
        }
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  limparVerbas() {
    this.verbaNome = '';
    this.mostrarVerbas = false;
  }

  modoEdicao(index: number, transacao: string) {
    this.modoEdicaoIndex = index === this.modoEdicaoIndex ? -1 : index;

    if (this.modoEdicaoIndex === -1) {
      if (!/^\d{2}$/.test(transacao.trim()) && transacao.trim().toUpperCase() !== 'SS') {
        this.modoEdicaoIndex = index;
        return new AlertaModal(
          'erro',
          'Atenção',
          "O campo Transação aceita apenas dígitos ou 'SS'",
          'p'
        );
      }
    }
  }

  buscarNomeVerba(codigo: string): string {
    const listaVerbaFiltrada = this.listaVerba.filter((verba) => verba.Codigo === codigo)[0];
    if (listaVerbaFiltrada) {
      return listaVerbaFiltrada.Descricao;
    }
    return '';
  }

  buscarMunicipios(uf: string) {
    this.pensaoAlimenticiaService.buscarMunicipios(uf).subscribe(
      (data: any[]) => {
        this.listaMunicipio = data;
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarTipoPensionista() {
    this.pensaoAlimenticiaService.buscarTipoPensionista().subscribe(
      (data: any[]) => {
        this.listaTipoPensao = data;
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarTipoCalculo() {
    this.pensaoAlimenticiaService.buscarTipoCalculo().subscribe(
      (data: Parametro[]) => {
        this.listaTipoCalculo = data.sort(this.ordenarListas);
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  ordenarListas(a: any, b: any): number {
    if (a.Descricao < b.Descricao) {
      return -1;
    } else if (a.Descricao > b.Descricao) {
      return 1;
    } else {
      return 0;
    }
  }

  listaVerbaRemovida(codigo: string): boolean {
    let formArray: FormArray = this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('verbasRemovidas') as FormArray;
    const splitValorEvento = codigo.split('-');
    const valorCodigo = splitValorEvento[0];
    const valorTransacao = splitValorEvento[1];
    const checkboxMarcado = formArray.value.some(
      (item) => String(item.Codigo) === valorCodigo && String(item.Transacao) === valorTransacao
    );
    return checkboxMarcado;
  }

  alterarMatricula(): void {
    const matricula = this.formPensaoAlimenticia.get('matricula').value;
    if (matricula === '') {
      this.formPensaoAlimenticia.get('nomeFuncionario').setValue('');
    }
  }

  buscarListaRepresentantes(lista: any[]) {
    if (this.formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal') {
      this.spinner.show();
      this.listaRepresentanteSubject.next(lista);
      // Assinar o Observable para receber a lista de representantes
      const codRepresentante = this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('codigoRepresentanteLegal').value;
      this.listaRepresentante$
        .pipe(take(1)) // Para garantir que você obtenha apenas a primeira emissão da lista
        .subscribe((listaRepresentantes) => {
          const representanteSelecionado = listaRepresentantes.find(
            (representante) => representante.NumeroRepresentante === Number(codRepresentante)
          );
          if (representanteSelecionado) {
            this.formPensaoAlimenticia
              .get('dadosPagamento')
              .get('bancoRepLegal')
              .setValue(representanteSelecionado.DadosBancarios.Banco);
            this.buscarOperacao().then(() => {
              this.formPensaoAlimenticia
                .get('dadosPagamento')
                .get('operacaoRepLegal')
                .setValue(representanteSelecionado.DadosBancarios.Operacao.replace(/^0/, ''));
              this.spinner.hide();
            });
            this.formPensaoAlimenticia
              .get('dadosPagamento')
              .get('agenciaRepLegal')
              .setValue(representanteSelecionado.DadosBancarios.Agencia);
            this.formPensaoAlimenticia
              .get('dadosPagamento')
              .get('contaRepLegal')
              .setValue(representanteSelecionado.DadosBancarios.ContaCorrente);
          } else this.spinner.hide();
        });
    }
  }

  selecionarRepresentante(representante: RepresentanteLegal) {
    this.abrirModalRepLegal = false;
    this.removerValidacoesTitular();
    this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('codigoRepresentanteLegal')
      .setValue(representante.NumeroRepresentante);
    this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('tipoRepresentanteLegal')
      .setValue(representante.TipoRepresentante);
    this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('nomeRepresentanteLegal')
      .setValue(representante.NomeRepresentante);
    this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('bancoRepLegal')
      .setValue(representante.DadosBancarios.Banco);
    this.buscarOperacao().then(() => {
      this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('operacaoRepLegal')
        .setValue(representante.DadosBancarios.Operacao.replace(/^0/, ''));
    });
    this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('agenciaRepLegal')
      .setValue(representante.DadosBancarios.Agencia);
    this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('contaRepLegal')
      .setValue(representante.DadosBancarios.ContaCorrente);
  }

  buscarPensionistas(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.carregandoPensionista = true;
      const matricula = this.formPensaoAlimenticia.get('matricula').value;
      this.pensaoAlimenticiaService.buscarPensionistas(matricula).subscribe(
        (data: any[]) => {
          this.listaPensionista = data;
          this.carregandoPensionista = false;
          if (data.length > 0) {
            this.formPensaoAlimenticia.get('pensionista').enable();
            if (data.length === 1) {
              this.formPensaoAlimenticia.get('pensionista').setValue(data[0].CPF);
              this.selecionarPensionista();
            } else {
              resolve();
            }
          }
          this.buscarVerbaDesconto();
        },
        (error) => {
          this.carregandoPensionista = false;
          this.validaErro.retornoErro(error);
          reject();
        }
      );
    });
  }

  onVerbaCheckChange(event) {
    const formArray: FormArray = this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('verbasRemovidas') as FormArray;
    const splitValorEvento = event.target.value.split('-');
    const valorCodigo = splitValorEvento[0];
    const valorTransacao = splitValorEvento[1];
    const verba = this.listaVerba.find(
      (verba) => String(verba.Codigo) === valorCodigo && String(verba.Transacao) === valorTransacao
    );
    const arrayIndex = formArray.controls.findIndex((control) => {
      const value = control.value;
      return String(value.Codigo) === valorCodigo && String(value.Transacao) === valorTransacao;
    });
    const valorExisteNoArray = arrayIndex !== -1;
    if (event.target.checked && !valorExisteNoArray) {
      formArray.push(new FormControl(verba));
    } else {
      formArray.removeAt(arrayIndex);
    }
  }

  marcarTodasVerbas(): void {
    this.listaVerba.forEach((empfil) => {
      let element = <HTMLInputElement>document.getElementById(String(empfil.Codigo));
      if (element.checked == false) {
        element.click();
      }
    });
    this.marcarTodos = true;
  }

  desmarcarTodasVerbas(): void {
    this.listaVerba.forEach((verba) => {
      let element = <HTMLInputElement>document.getElementById(String(verba.Codigo));

      if (element.checked == true) {
        element.click();
      }
    });
    // this.formPensaoAlimenticia
    //   .get("dadosPagamento")
    //   .get("verbasRemovidas")
    //   .value.forEach((verba) => {
    //     let verbasRemovidas = <HTMLInputElement>(
    //       document.getElementById(`removido-${verba.Codigo}`)
    //     );
    //     if (verbasRemovidas.checked == true) {
    //       verbasRemovidas.click();
    //     }
    //   });
    this.listaVerba = [];
    this.mostrarVerbas = false;
    this.verbaNome = '';
    this.marcarTodos = false;
  }

  abrirListagemVerba(event) {
    if (event.target.checked) {
      document.getElementById('abrirVerbas').click();
    }
  }

  onCepChange(): void {
    const cep = this.formPensaoAlimenticia.get('informacaoBasica').get('cep').value;

    if (cep.length === 8) {
      this.loadingCep = true;

      this.pensaoAlimenticiaService.buscarCep(cep).subscribe(
        (data: Endereco) => {
          if (!data.Valido) {
            new AlertaModal('erro', 'Atenção', 'CEP inválido', 'p');
            this.formPensaoAlimenticia.get('informacaoBasica').get('cep').setValue('');
            this.formPensaoAlimenticia.get('informacaoBasica').get('cep').updateValueAndValidity();
          } else {
            this.carregarEndereco(data);
          }
          this.loadingCep = false;
        },
        (error) => {
          this.loadingCep = false;
        }
      );
    }
  }

  carregarEndereco(endereco: Endereco): void {
    this.listaMunicipio = [];
    const codigoUf = this.listaUf.filter((uf) => uf.SiglaUf === endereco.Uf)[0].Codigo;
    this.buscarMunicipios(codigoUf);

    this.formPensaoAlimenticia
      .get('informacaoBasica')
      .get('logradouro')
      .setValue(endereco.Logradouro);
    this.formPensaoAlimenticia.get('informacaoBasica').get('bairro').setValue(endereco.Bairro);
    this.formPensaoAlimenticia
      .get('informacaoBasica')
      .get('complementoEndereco')
      .setValue(endereco.Complemento);
    this.formPensaoAlimenticia
      .get('informacaoBasica')
      .get('numeroLogradouro')
      .setValue(endereco.Numero === 0 ? '' : endereco.Numero);
    this.formPensaoAlimenticia.get('informacaoBasica').get('ufEndereco').setValue(endereco.Uf);
    this.formPensaoAlimenticia.get('informacaoBasica').get('cidade').setValue(endereco.Cidade);
  }

  selecionarPensionista(): void {
    this.etapa = 0;
    if (this.formPensaoAlimenticia.get('pensionista').value === '') {
      this.pensionistaSelecionado = null;
      this.novoPensionista();
    } else {
      const pensionistaCpf = this.formPensaoAlimenticia.get('pensionista').value;
      const pensionista = this.listaPensionista.filter(
        (pensionista) => pensionista.CPF === pensionistaCpf
      )[0];
      const pensionistaVinculo = this.formPensaoAlimenticia.get('vinculo').value;
      this.limparCampos();
      this.pensionistaSelecionado = pensionista;
      console.log('pensionista selecionado', this.pensionistaSelecionado);
      this.carregarDados();
      this.formPensaoAlimenticia.get('pensionista').setValue(pensionista.CPF);
      this.anexosCarregados = false;
    }
    this.onTipoCalculoChange();
  }

  verificaData(dataInicial: AbstractControl, dataFinal: AbstractControl) {
    if (dataInicial.value.length === 8 && dataFinal.value.length === 8) {
      const dataInicialInvertida = Number(
        `${dataInicial.value.substr(4, 4)}${dataInicial.value.substr(
          2,
          2
        )}${dataInicial.value.substr(0, 2)}`
      );
      const dataFinalInvertida = Number(
        `${dataFinal.value.substr(4, 4)}${dataFinal.value.substr(2, 2)}${dataFinal.value.substr(
          0,
          2
        )}`
      );

      if (dataInicialInvertida >= dataFinalInvertida) {
        dataFinal.setValue('');
        new AlertaModal('erro', 'Atenção', 'A data final deve ser posterior à inicial.', 'p');
      }
    }
  }

  carregarDados(): void {
    const {
      DadosBancarios,
      DadosPagamento,
      Documentos,
      Endereco,
      Telefone,
      InformacoesPagamento,
      RepresentanteLegal,
      Verbas,
    } = this.pensionistaSelecionado;
    const form = this.formPensaoAlimenticia;
    // form.get("pensionista").setValue(this.pensionistaSelecionado.CPF);

    if (Endereco.UF !== '') {
      const codigoUf = this.listaUf.filter((uf) => uf.SiglaUf === Endereco.UF)[0].Codigo;
      form.get('informacaoBasica').get('logradouro').setValue(Endereco.Logradouro);
      form.get('informacaoBasica').get('ufEndereco').setValue(Endereco.UF);
      this.buscarMunicipios(codigoUf);
      form.get('informacaoBasica').get('numeroLogradouro').setValue(Endereco.Numero);
      form.get('informacaoBasica').get('complementoEndereco').setValue(Endereco.Complemento);
      form.get('informacaoBasica').get('bairro').setValue(Endereco.Bairro);
      form.get('informacaoBasica').get('cidade').setValue(Endereco.Cidade);
      form.get('informacaoBasica').get('cep').setValue(Endereco.CEP);
    }

    form.get('informacaoBasica').get('codigo').setValue(this.pensionistaSelecionado.CodigoPensao);
    form
      .get('informacaoBasica')
      .get('nomePensionista')
      .setValue(this.pensionistaSelecionado.NomePensionista);
    form.get('informacaoBasica').get('tipoPensao').setValue(this.pensionistaSelecionado.TipoPensao);
    form
      .get('informacaoBasica')
      .get('cpf')
      .setValue(this.pensionistaSelecionado.CPF.padStart(11, '0'));
    form.get('informacaoBasica').get('identidade').setValue(Documentos.NumeroRg);
    form.get('informacaoBasica').get('orgaoExpedidor').setValue(Documentos.OrgaoEmissorRg);
    form.get('informacaoBasica').get('ufIdentidade').setValue(Documentos.UfRg);
    form
      .get('informacaoBasica')
      .get('dataNascimento')
      .setValue(this.formatarData(this.pensionistaSelecionado.DataNascimento.ToDate));
    form
      .get('informacaoBasica')
      .get('telefone')
      .setValue(Telefone.DDD.toString() + Telefone.Numero.toString());

    form.get('dadosPagamento').get('verbaDesconto').setValue(InformacoesPagamento.Desconto.Verba);
    form.get('dadosPagamento').get('transacao').setValue(InformacoesPagamento.Desconto.Transacao);

    form
      .get('dadosPagamento')
      .get('dataInicialPgto')
      .setValue(this.formatarData(DadosPagamento.PeriodoDesconto.DataInicioVigencia.ToDate));
    if (typeof DadosPagamento.PeriodoDesconto.DataFimVigencia !== 'string') {
      form
        .get('dadosPagamento')
        .get('dataFinalPgto')
        .setValue(this.formatarData(DadosPagamento.PeriodoDesconto.DataFimVigencia.ToDate));
    }
    form.get('dadosPagamento').get('processoPgto').setValue(DadosPagamento.Processo);

    let dadosBancariosZerados = DadosBancarios;

    for (const prop in dadosBancariosZerados) {
      if (/^0+$/.test(dadosBancariosZerados[prop])) {
        dadosBancariosZerados[prop] = '';
      }
    }

    form
      .get('dadosPagamento')
      .get('radioPgto')
      .setValue(
        !RepresentanteLegal ||
          (RepresentanteLegal &&
            new Date().getTime() >
              new Date(this.formatarData(RepresentanteLegal.DataFim.ToDate)).getTime())
          ? 'titular'
          : RepresentanteLegal
          ? 'representanteLegal'
          : ''
      );
    if (
      !RepresentanteLegal ||
      (RepresentanteLegal &&
        new Date().getTime() >
          new Date(this.formatarData(RepresentanteLegal.DataFim.ToDate)).getTime())
    ) {
      form
        .get('dadosPagamento')
        .get('banco')
        .setValue(dadosBancariosZerados ? dadosBancariosZerados.Banco : '');
      form
        .get('dadosPagamento')
        .get('agencia')
        .setValue(dadosBancariosZerados ? dadosBancariosZerados.Agencia : '');
      this.buscarOperacao().then(() => {});
      form
        .get('dadosPagamento')
        .get('conta')
        .setValue(dadosBancariosZerados ? dadosBancariosZerados.ContaCorrente : '');
      this.tipoCarregamento = true;
      this.maskConta();
      form
        .get('dadosPagamento')
        .get('operacao')
        .setValue(dadosBancariosZerados ? dadosBancariosZerados.Operacao?.replace(/^0/, '') : '');
    } else {
      form
        .get('dadosPagamento')
        .get('codigoRepresentanteLegal')
        .setValue(RepresentanteLegal.NumeroRepresentante);
      form.get('dadosPagamento').get('tipoRepresentanteLegal').setValue(RepresentanteLegal.Tipo);
      form.get('dadosPagamento').get('nomeRepresentanteLegal').setValue(RepresentanteLegal.Nome);
      form
        .get('dadosPagamento')
        .get('dataInicioRepLegal')
        .setValue(this.formatarData(RepresentanteLegal.DataInicio.ToDate));
      form
        .get('dadosPagamento')
        .get('dataFimRepLegal')
        .setValue(this.formatarData(RepresentanteLegal.DataFim.ToDate));
      form
        .get('dadosPagamento')
        .get('dataVencimentoRepLegal')
        .setValue(this.formatarData(RepresentanteLegal.DataVencimento.ToDate));
      if (this.clientePublico) {
        form
          .get('dadosPagamento')
          .get('processoRepLegal')
          .setValue(RepresentanteLegal.NumeroProcesso.trim());
      }
    }

    form.get('dadosPagamento').get('tipoCalculo').setValue(InformacoesPagamento.TipoCalculo);
    form.get('dadosPagamento').get('percentual').setValue(InformacoesPagamento.Percentual);
    form
      .get('dadosPagamento')
      .get('qtdSalarioFamilia')
      .setValue(InformacoesPagamento.QuantidadeSalarioFamilia);
    form.get('dadosPagamento').get('valor').setValue(InformacoesPagamento.Valor);
    form
      .get('dadosPagamento')
      .get('radioDecimoTerceiro')
      .setValue(InformacoesPagamento.Direito13Salario);
    form.get('dadosPagamento').get('radioFerias').setValue(InformacoesPagamento.DireitoFerias);
    form.get('dadosPagamento').get('radioRescisao').setValue(InformacoesPagamento.DireitoRescisao);
    form.get('dadosPagamento').get('trct').setValue(InformacoesPagamento.porcentagemTRCT);
    form.get('dadosPagamento').get('fgts').setValue(InformacoesPagamento.PorcentagemFGTS);

    this.listaVerba = Verbas.map((verba) => {
      return {
        Codigo: verba.CodigoVerba.toString(),
        Descricao: verba.NomeVerba,
        Transacao: verba.Transacao,
      };
    });
    this.listaAuxVerbasRemovidas = [];
    this.listaVerba.forEach((verba: Parametro) => {
      const verbaArray = form.get('dadosPagamento').get('verbasRemovidas') as FormArray;
      verbaArray.push(new FormControl(verba));
      this.listaAuxVerbasRemovidas.push(verba);
    });
    console.log('lista aux', this.listaAuxVerbasRemovidas);
    console.log('listanexo', this.listaAnexo);
  }

  buscarOperacao(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.listaOperacao = [];

      const banco =
        this.formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
          ? this.formPensaoAlimenticia.get('dadosPagamento').get('bancoRepLegal').value
          : this.formPensaoAlimenticia.get('dadosPagamento').get('banco').value;

      if (banco !== '') {
        this.pensaoAlimenticiaService.buscarOperacao(banco).subscribe(
          (data: any[]) => {
            this.listaOperacao = data;
            resolve();
          },
          (error) => {
            this.validaErro.retornoErro(error);
            reject();
          }
        );
      } else {
        this.formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
          ? this.formPensaoAlimenticia.get('dadosPagamento').get('operacaoRepLegal').setValue('')
          : this.formPensaoAlimenticia.get('dadosPagamento').get('operacao').setValue('');
      }
    });
  }

  onTipoCalculoChange(): void {
    const tipoCalculo = this.formPensaoAlimenticia.get('dadosPagamento').get('tipoCalculo').value;

    if (tipoCalculo === 'I') {
      this.formPensaoAlimenticia.get('dadosPagamento').get('percentual').setValidators(null);
      this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('qtdSalarioFamilia')
        .setValidators([Validators.required,zeroValidator()]);
      this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('valor')
        .setValidators([Validators.required, zeroValidator()]);
      this.formPensaoAlimenticia.get('dadosPagamento').get('percentual').updateValueAndValidity();
      this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('qtdSalarioFamilia')
        .updateValueAndValidity();
      this.formPensaoAlimenticia.get('dadosPagamento').get('valor').updateValueAndValidity();
    } else {
      this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('percentual')
        .setValidators(Validators.required);
      this.formPensaoAlimenticia.get('dadosPagamento').get('qtdSalarioFamilia').setValidators(null);
      this.formPensaoAlimenticia.get('dadosPagamento').get('valor').setValidators(null);
      this.formPensaoAlimenticia.get('dadosPagamento').get('percentual').updateValueAndValidity();
      this.formPensaoAlimenticia
        .get('dadosPagamento')
        .get('qtdSalarioFamilia')
        .updateValueAndValidity();
      this.formPensaoAlimenticia.get('dadosPagamento').get('valor').updateValueAndValidity();
    }
  }

  voltarEtapa() {
    this.etapa = this.etapa - 1;
    this.anexosCarregados = false;
  }

  submitPensao(): void {
    const quemRecebe = this.formPensaoAlimenticia.get('dadosPagamento').get('radioPgto').value;
    const valido = this[`validaEtapa${this.etapa}`]();

    if (this.etapa === 0 && valido) {
      this.temErro = false;
      this.listaAnexo = this.anexoComponent.retornarValores();
      if (this.listaAnexo.length < 1 && !this.pensionistaSelecionado) {
        new AlertaModal('erro', 'Atenção', 'Anexo de arquivo é obrigatório', 'p');
        return;
      }
      if (quemRecebe === 'representanteLegal') {
        this.spinner.show();
        this.setRepresentanteObrigatorio();
      } else {
        this.setTitularObrigatorio();
      }

      this.etapa += 1;
    } else if (this.onValidaArquivos()) {
      new AlertaModal(
        'erro',
        'Atenção',
        'Anexo de arquivo obrigatório para esta opção de pagamento',
        'p'
      );
    } else if (this.etapa === 1 && valido) {
      this.temErro = false;
      if (quemRecebe === 'titular') {
        this.limparCamposRepresentante();
      } else if (quemRecebe === 'representanteLegal') {
        this.listaAnexo.push(...this.anexoComponent.retornarValores());
        this.limparCamposTitular();
      }
      if (this.pensionistaSelecionado === null) {
        this.verbaDesconto !== ''
          ? this.salvarPensao()
          : new AlertaModal(
              'Atenção',
              'Este colaborador não possui verba para criar um novo pensionista!',
              'warning'
            );
      } else {
        this.alterarPensao();
      }
    }
  }

  validaEtapa0(): boolean {
    if (this.formPensaoAlimenticia.get('informacaoBasica').valid) {
      return true;
    } else {
      new AlertaModal('erro', 'Atenção', 'Verifique os campos incorretos', 'p');
      this.temErro = true;
      return false;
    }
  }
  validaEtapa1() {
    if (this.formPensaoAlimenticia.get('dadosPagamento').valid) {
      return true;
    } else {
      new AlertaModal('erro', 'Atenção', 'Verifique os campos incorretos', 'p');
      this.temErro = true;
      return false;
    }
  }

  onRadioPgtoChange(): void {
    const quemRecebe = this.formPensaoAlimenticia.get('dadosPagamento').get('radioPgto').value;
    if (quemRecebe === 'titular') {
      this.abrirModalRepLegal = false;
      // this.limparCamposRepresentante();
      this.setTitularObrigatorio();
    } else if (quemRecebe === 'representanteLegal') {
      // this.limparCamposTitular();
      this.setRepresentanteObrigatorio();
      this.abrirModalRepLegal = true;
      this.representanteLegalComponent.abrirModalRepLegal();
    }
  }

  compChavePensionista() {
    const codPensionista = this.formPensaoAlimenticia.get('dadosPagamento').get('codigo').value;
    const nomePensionista = this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('nomePensionista')
      .value.split(' ')[0];
    const compChave = `${codPensionista}-${nomePensionista}`;
    return compChave;
  }

  onValidaArquivos(): boolean {
    const quemRecebe = this.formPensaoAlimenticia.get('dadosPagamento').get('radioPgto').value;
    return quemRecebe === 'representanteLegal'
      ? this.anexoComponent.retornarValores().length > 0
        ? false
        : true
      : false;
  }

  setTitularObrigatorio(): void {
    const form = this.formPensaoAlimenticia;

    this.limparObrigatorioQuemRecebe();
    form.get('dadosPagamento').get('banco').setValidators(Validators.required);
    form.get('dadosPagamento').get('agencia').setValidators(Validators.required);
    form.get('dadosPagamento').get('operacao').setValidators(Validators.required);
    form.get('dadosPagamento').get('conta').setValidators(Validators.required);
    form.get('dadosPagamento').get('banco').updateValueAndValidity();
    form.get('dadosPagamento').get('agencia').updateValueAndValidity();
    form.get('dadosPagamento').get('operacao').updateValueAndValidity();
    form.get('dadosPagamento').get('conta').updateValueAndValidity();
  }

  limparCamposRepresentante(): void {
    const form = this.formPensaoAlimenticia;
    form.get('dadosPagamento').get('codigoRepresentanteLegal').setValue('');
    form.get('dadosPagamento').get('tipoRepresentanteLegal').setValue('');
    form.get('dadosPagamento').get('nomeRepresentanteLegal').setValue('');
    form.get('dadosPagamento').get('dataInicioRepLegal').setValue('');
    form.get('dadosPagamento').get('dataFimRepLegal').setValue('');
    form.get('dadosPagamento').get('dataVencimentoRepLegal').setValue('');
    form.get('dadosPagamento').get('processoRepLegal').setValue('');
    form.get('dadosPagamento').get('bancoRepLegal').setValue('');
    form.get('dadosPagamento').get('operacaoRepLegal').setValue('');
    form.get('dadosPagamento').get('agenciaRepLegal').setValue('');
    form.get('dadosPagamento').get('contaRepLegal').setValue('');
    form.get('dadosPagamento').get('processoRepLegal').markAsUntouched();
  }

  setRepresentanteObrigatorio(): void {
    const form = this.formPensaoAlimenticia;

    this.limparObrigatorioQuemRecebe();
    form.get('dadosPagamento').get('codigoRepresentanteLegal').setValidators(Validators.required);
    form.get('dadosPagamento').get('tipoRepresentanteLegal').setValidators(Validators.required);
    form.get('dadosPagamento').get('nomeRepresentanteLegal').setValidators(Validators.required);
    if (this.clientePublico) {
      form.get('dadosPagamento').get('processoRepLegal').setValidators(Validators.required);
      form
        .get('dadosPagamento')
        .get('processoRepLegal')
        .setValidators(Validators.pattern(/^[^@#$%&¨*=_<>?|\\\{}/+_]+$/));
    }
    form.get('dadosPagamento').get('verbaDesconto').setValidators(Validators.required);
    form
      .get('dadosPagamento')
      .get('dataInicioRepLegal')
      .setValidators([Validators.required, Validators.min(1900 - 0o1 - 0o1)]);
    form
      .get('dadosPagamento')
      .get('dataFimRepLegal')
      .setValidators([Validators.required, Validators.min(1900 - 0o1 - 0o1)]);
    form
      .get('dadosPagamento')
      .get('dataVencimentoRepLegal')
      .setValidators([Validators.required, Validators.min(1900 - 0o1 - 0o1)]);
    form.get('dadosPagamento').get('codigoRepresentanteLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('tipoRepresentanteLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('nomeRepresentanteLegal').updateValueAndValidity();
    if (this.clientePublico) {
      form.get('dadosPagamento').get('processoRepLegal').updateValueAndValidity();
    }
    form.get('dadosPagamento').get('verbaDesconto').updateValueAndValidity();
  }

  limparCamposTitular(): void {
    const form = this.formPensaoAlimenticia;

    form.get('dadosPagamento').get('operacao').setValue('');
    form.get('dadosPagamento').get('banco').setValue('');
    form.get('dadosPagamento').get('agencia').setValue('');
    form.get('dadosPagamento').get('conta').setValue('');
  }

  limparObrigatorioQuemRecebe(): void {
    const form = this.formPensaoAlimenticia;

    form.get('dadosPagamento').get('banco').setValidators(null);
    form.get('dadosPagamento').get('agencia').setValidators(null);
    form.get('dadosPagamento').get('operacao').setValidators(null);
    form.get('dadosPagamento').get('conta').setValidators(null);
    form.get('dadosPagamento').get('codigoRepresentanteLegal').setValidators(null);
    form.get('dadosPagamento').get('tipoRepresentanteLegal').setValidators(null);
    form.get('dadosPagamento').get('nomeRepresentanteLegal').setValidators(null);
    form.get('dadosPagamento').get('dataInicioRepLegal').setValidators(null);
    form.get('dadosPagamento').get('dataFimRepLegal').setValidators(null);
    form.get('dadosPagamento').get('dataVencimentoRepLegal').setValidators(null);
    form.get('dadosPagamento').get('processoRepLegal').setValidators(null);
    form.get('dadosPagamento').get('verbaDesconto').setValidators(null);

    form.get('dadosPagamento').get('banco').updateValueAndValidity();
    form.get('dadosPagamento').get('agencia').updateValueAndValidity();
    form.get('dadosPagamento').get('operacao').updateValueAndValidity();
    form.get('dadosPagamento').get('conta').updateValueAndValidity();
    form.get('dadosPagamento').get('codigoRepresentanteLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('tipoRepresentanteLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('nomeRepresentanteLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('dataInicioRepLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('dataFimRepLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('dataVencimentoRepLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('processoRepLegal').updateValueAndValidity();
    form.get('dadosPagamento').get('verbaDesconto').updateValueAndValidity();
  }

  removerValidacoesTitular() {
    this.formPensaoAlimenticia.get('dadosPagamento').setValidators(null);
    this.formPensaoAlimenticia.get('dadosPagamento').updateValueAndValidity();
  }

  salvarPensao(): void {
    this.spinner.show();
    const pensaoAlimento = this.montarPensao();
    this.pensaoAlimenticiaService.salvarPensao(pensaoAlimento).subscribe(
      (data) => {
        this.etapa = 0;
        this.buscarPensionistas().then(() => {
          this.formPensaoAlimenticia.get('pensionista').setValue(pensaoAlimento.CPF);
          this.selecionarPensionista();
          this.pensionistaSelecionado = this.formPensaoAlimenticia.get('pensionista').value;
        });
        this.abrirModalRepLegal = false;
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso', 'Pensionista criado com sucesso!', 'p');
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  verificaCPFExiste() {
    const cpf = this.formPensaoAlimenticia.get('informacaoBasica').get('cpf').value;
    if (cpf.length >= 11) {
      const CPFCadastrado = this.listaPensionista.find((item) => {
        return item.CPF == cpf;
      });
      console.log('cpf cadastrado', CPFCadastrado);
      CPFCadastrado ? (this.cpfExiste = true) : (this.cpfExiste = false);
    }
  }

  toUppercase(event) {
    event.target.value = event.target.value.toUpperCase();
    this.formPensaoAlimenticia
      .get('informacaoBasica')
      .get('complementoEndereco')
      .setValue(event.target.value);
  }

  alterarPensao(): void {
    this.spinner.show();
    const pensaoAlimento = this.montarPensao();
    this.pensaoAlimenticiaService.alterarPensao(pensaoAlimento).subscribe(
      (data) => {
        this.etapa = 0;
        this.buscarPensionistas().then(() => {
          this.formPensaoAlimenticia.get('pensionista').setValue(pensaoAlimento.CPF.trim());
          this.selecionarPensionista();
          this.pensionistaSelecionado = this.formPensaoAlimenticia.get('pensionista').value;
        });
        this.abrirModalRepLegal = false;
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso', 'Dados do pensionista alterados com sucesso!', 'p');
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  montarPensao(): PensaoAlimento {
    const pensao = this.formPensaoAlimenticia.getRawValue();
    const { informacaoBasica, dadosPagamento } = this.formPensaoAlimenticia.getRawValue();

    return {
      //Sigla: "FAPP",
      NomePensionista: informacaoBasica.nomePensionista,
      Arquivos: this.listaAnexo ? this.listaAnexo : null,
      NomeFuncionario: pensao.nomeFuncionario,
      Matricula: pensao.matricula,
      CodigoPensao: informacaoBasica.codigo,
      EmpresaFilial: Number(this.localStorage.FilialAtual),
      NumeroOrdem: null,
      CPF: informacaoBasica.cpf,
      TipoPensao: informacaoBasica.tipoPensao,
      DataNascimento: new Data(this.formatarDataEnvio(informacaoBasica.dataNascimento)),
      Documentos: {
        NumeroRg: informacaoBasica.identidade.replace(/[.-]/g, ''),
        OrgaoEmissorRg: informacaoBasica.orgaoExpedidor,
        UfRg: informacaoBasica.ufIdentidade,
      },
      DadosBancarios:
        dadosPagamento.radioPgto === 'titular'
          ? {
              BancoAgencia: `${dadosPagamento.banco}${dadosPagamento.agencia}`,
              Banco: dadosPagamento.banco,
              Agencia: dadosPagamento.agencia,
              ContaCorrente: dadosPagamento.conta,
              Operacao: dadosPagamento.operacao,
            }
          : null,
      InformacoesPagamento: {
        TipoCalculo: dadosPagamento.tipoCalculo,
        Percentual: dadosPagamento.percentual,
        QuantidadeSalarioFamilia: dadosPagamento.qtdSalarioFamilia,
        Valor: dadosPagamento.valor,
        Direito13Salario: dadosPagamento.radioDecimoTerceiro,
        DireitoFerias: dadosPagamento.radioFerias,
        DireitoRescisao: dadosPagamento.radioRescisao,
        porcentagemTRCT: dadosPagamento.trct,
        PorcentagemFGTS: dadosPagamento.fgts,
        Transacao: dadosPagamento.transacao,
        Desconto: {
          Transacao: 0,
          Verba: dadosPagamento.verbaDesconto,
        },
      },
      Endereco: {
        Logradouro: informacaoBasica.logradouro,
        EnderecoTipoLogradouro: 0,
        Numero: informacaoBasica.numeroLogradouro,
        Complemento: informacaoBasica.complementoEndereco.toUpperCase(),
        UF: informacaoBasica.UfEndereco,
        Cidade: informacaoBasica.cidade,
        Bairro: informacaoBasica.bairro,
        CEP: informacaoBasica.cep,
      },
      Telefone: {
        DDD: informacaoBasica.telefone.toString().substr(0, 2),
        Numero: informacaoBasica.telefone.toString().substr(2),
      },
      Flags: {
        F01: 1,
        F02: 0,
        F03: 0,
        F04: 0,
        F05: 0,
      },
      DadosPagamento: {
        PeriodoDesconto: {
          DataInicioVigencia: new Data(this.formatarDataEnvio(dadosPagamento.dataInicialPgto)),
          DataFimVigencia: dadosPagamento.dataFinalPgto
            ? new Data(this.formatarDataEnvio(dadosPagamento.dataFinalPgto))
            : '',
        },
        Lotacao: '',
        Processo: dadosPagamento.processoPgto,
      },
      RepresentanteLegal:
        dadosPagamento.radioPgto === 'representanteLegal'
          ? {
              Nome: dadosPagamento.nomeRepresentanteLegal,
              NumeroProcesso: dadosPagamento.processoRepLegal,
              NumeroRepresentante: dadosPagamento.codigoRepresentanteLegal,
              Tipo: dadosPagamento.tipoRepresentanteLegal,
              DataFim: new Data(this.formatarDataEnvio(dadosPagamento.dataFimRepLegal)),
              DataInicio: new Data(this.formatarDataEnvio(dadosPagamento.dataInicioRepLegal)),
              DataVencimento: new Data(
                dadosPagamento.dataVencimentoRepLegal
                  ? this.formatarDataEnvio(dadosPagamento.dataVencimentoRepLegal)
                  : '00/00/00000'
              ),
            }
          : null,
      Verbas: (this.formPensaoAlimenticia.get('dadosPagamento').get('verbasRemovidas') as FormArray)
        ? this.montarVerbas()
        : [],
    };
  }

  somenteLetras(event) {
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122) ||
      event.charCode === 32
    );
  }
  montarVerbas(): Verba[] {
    const verbas = this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('verbasRemovidas') as FormArray;
    return verbas.value.map((verba: Parametro) => {
      return {
        EmpresaFilial: Number(this.localStorage.FilialAtual),
        Matricula: Number(this.formPensaoAlimenticia.get('matricula').value),
        CodigoVerba: verba.Codigo,
        Transacao: verba.Transacao.toUpperCase(),
      };
    });
  }

  novoPensionista(): void {
    this.limparCampos();
    this.etapa = 0;
    this.pensionistaSelecionado = null;

    let chavesUnicas: string[] = ['TIPO_CONTA', 'SEXO'];

    const campoChave = new CampoChave();

    this.pensaoAlimenticiaService
      .testeParamentro(campoChave.montarlistaParamentros(chavesUnicas))
      .subscribe(
        (data) => {},
        (error) => {
          // this.spinner.hide();
          this.validaErro.retornoErro(error);
        }
      );
  }

  limparCampos(): void {
    this.etapa = 0;
    this.formPensaoAlimenticia.reset({
      matricula: this.formPensaoAlimenticia.get('matricula').value,
      nomeFuncionario: this.formPensaoAlimenticia.get('nomeFuncionario').value,
      vinculo: this.formPensaoAlimenticia.get('vinculo').value,
      pensionista: '',
      informacaoBasica: {
        codigo: '',
        nomePensionista: '',
        tipoPensao: '', //, Validators.min(1)
        cpf: '',
        identidade: '',
        orgaoExpedidor: '',
        ufIdentidade: '',
        dataNascimento: '',
        logradouro: '',
        numeroLogradouro: '',
        complementoEndereco: '',
        bairro: '',
        cidade: '',
        ufEndereco: '',
        cep: '',
        ddd: '',
        telefone: '',
      },
      dadosPagamento: {
        lotacao: '',
        dataInicialPgto: '',
        dataFinalPgto: '',
        processoPgto: '',
        radioPgto: '',
        banco: '',
        agencia: '',
        operacao: '',
        conta: '',
        digitoVerificador: '',
        codigoRepresentanteLegal: '',
        tipoRepresentanteLegal: '',
        nomeRepresentanteLegal: '',
        dataInicioRepLegal: '',
        dataFimRepLegal: '',
        dataVencimentoRepLegal: '',
        processoRepLegal: '',
        operacaoRepLegal: '',
        bancoRepLegal: '',
        agenciaRepLegal: '',
        contaRepLegal: '',
        tipoCalculo: '',
        percentual: '',
        qtdSalarioFamilia: '',
        valor: '',
        baseCalculo: '',
        radioDecimoTerceiro: '',
        radioFerias: '',
        radioRescisao: '',
        trct: '',
        fgts: '',
        verbaDesconto: this.verbaDesconto,
        transacao: '',
        verbasRemovidas: [],
      },
    });
    this.verbaNome = '';

    this.listaVerba = [];
    const listaverbasRemovidas = this.formPensaoAlimenticia
      .get('dadosPagamento')
      .get('verbasRemovidas') as FormArray;
    listaverbasRemovidas.clear();
  }

  recebeAnexos(evento) {
    console.log('anexos carregados', this.anexosCarregados, evento);

    this.anexosCarregados = true;
  }

  maskConta(): void {
    const conta =
      this.formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
        ? this.formPensaoAlimenticia.get('dadosPagamento').get('contaRepLegal').value
        : this.formPensaoAlimenticia.get('dadosPagamento').get('conta').value;

    if (conta.length >= 4) {
      this.temErro = false;
      if (conta.length >= 9) {
        this.contaMask = '0'.repeat(14) + '-0';
      } else {
        this.tipoCarregamento
          ? (this.contaMask = '0'.repeat(conta.length - 1) + '-0')
          : (this.contaMask = '0'.repeat(conta.length) + '-0');
      }
    } else {
      this.contaMask = '0000-0';
    }
    this.tipoCarregamento = false;
  }

  formatarDataEnvio(data: string): string {
    if (!data) return;
    let dataSplit = data.split('-');
    const dataFormatada = `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
    return dataFormatada;
  }

  formatarData(data: string): string {
    var dateFormat =
      /^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\d|2[0-8])[- /.]02)[- /.]\d{4}|29[- /.]02[- /.](\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$/;

    if (data.match(dateFormat)) {
      let dataSplit = data.split('/');
      const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
      return dataFormatada;
    } else return '';
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.limparCampos();
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

export interface PensaoAlimento {
  Sigla?: string;
  Obs?: 'S' | 'N';
  Arquivos: any[];
  NomePensionista: string;
  NomeFuncionario: string;
  Matricula: number;
  Digito?: number;
  CodigoPensao: number;
  EmpresaFilial: number;
  NumeroOrdem: number;
  CPF: string;
  TipoPensao: number;
  DataNascimento: Data;
  Documentos: Documentos;
  DadosBancarios: DadosBancarios;
  InformacoesPagamento: InformacaoPagamento;
  Endereco: Endereco;
  Telefone: Telefone;
  Flags: Flag;
  DadosPagamento: DadosPagamento;
  RepresentanteLegal: PensaoRepresentanteLegal;
  Verbas: Verba[];
}

interface Documentos {
  NumeroRg: string;
  OrgaoEmissorRg: string;
  UfRg: string;
}

interface DadosPagamento {
  PeriodoDesconto: PeriodoDesconto;
  Lotacao: string;
  Processo: string;
  Operacao?: string;
}

interface PeriodoDesconto {
  DataInicioVigencia: Data;
  DataFimVigencia: Data | string;
}

interface DadosBancarios {
  BancoAgencia: string;
  Banco: string;
  Agencia: string;
  ContaCorrente: string;
  Operacao: string;
}

interface InformacaoPagamento {
  TipoCalculo: string;
  Percentual: number;
  QuantidadeSalarioFamilia: number;
  Valor?: number;
  Direito13Salario: string;
  DireitoFerias: string;
  DireitoRescisao: string;
  porcentagemTRCT: number;
  PorcentagemFGTS: number;
  Transacao: number;
  AuxilioReclusao?: string;
  Desconto: Desconto;
}

interface Desconto {
  Transacao: number;
  Verba: number;
}

interface Flag {
  F01: number;
  F02: number;
  F03: number;
  F04: number;
  F05: number;
}

interface PensaoRepresentanteLegal {
  Nome: string;
  NumeroRepresentante: number;
  DataInicio: Data;
  DataFim: Data;
  DataVencimento: Data;
  Tipo: number;
  NumeroProcesso: string;
  F01?: number;
  F02?: number;
  F03?: number;
  F04?: number;
  F05?: number;
  DadosBancarios?: DadosBancarios;
}

interface Verba {
  EmpresaFilial: number;
  Matricula: number;
  CodigoPensionista?: number;
  CodigoVerba: number;
  Transacao: string;
  VerbaAnterior?: number;
  TransacaoAnterior?: string;
  Sigla: string;
  NomeVerba?: string;
}

interface informacaoBasicaPensao {
  Nome: string;
  Numero: number;
}
