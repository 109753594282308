<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<form [formGroup]="formPensaoAlimenticia" #pensaoAlimenticia="ngForm">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Pensão Alimentícia</h3>
    </div>
    <div class="card-body">
      <div class="row align-items-end">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              mask="0*"
              maxlength="9"
              (keyup)="alterarMatricula()"
              (keyup.enter)="buscarColaborador(this.formPensaoAlimenticia.get('matricula').value)"
              formControlName="matricula"
              placeholder="Matrícula"
              max="9"
            />
            <span class="input-group-append">
              <button
                type="button"
                (click)="buscarColaborador(this.formPensaoAlimenticia.get('matricula').value)"
                [disabled]="carregandoPensionista"
                class="input-group-text bg-blue border-blue"
              >
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
          <span class="form-text" *ngIf="carregandoPensionista"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>
        <div class="col-md-7 form-group">
          <label class="font-weight-semibold">Nome do Funcionário</label>
          <input
            class="form-control"
            type="text"
            formControlName="nomeFuncionario"
            placeholder="Nome do Funcionário"
            maxlength="80"
            [ngClass]="
              (temErro || formPensaoAlimenticia.get('nomeFuncionario').touched) &&
              formPensaoAlimenticia.get('nomeFuncionario').status === 'INVALID'
                ? 'is-invalid'
                : ''
            "
          />
        </div>
        <div class="col-md-3 form-group">
          <label class="font-weight-semibold">Vínculo</label>
          <input
            class="form-control"
            type="text"
            formControlName="vinculo"
            placeholder="Vínculo"
            [ngClass]="
              (temErro || formPensaoAlimenticia.get('vinculo').touched) &&
              formPensaoAlimenticia.get('vinculo').status === 'INVALID'
                ? 'is-invalid'
                : ''
            "
          />
        </div>

        <!-- Seleção Pensionista-->
        <div
          *ngIf="
            formPensaoAlimenticia.get('matricula').value !== '' &&
            formPensaoAlimenticia.get('nomeFuncionario').value !== ''
          "
          class="col form-group"
        >
          <label class="font-weight-semibold">Pensionista</label>
          <select
            #selectPensionista
            class="form-control"
            (change)="selecionarPensionista()"
            formControlName="pensionista"
            [ngClass]="
              (temErro || formPensaoAlimenticia.get('pensionista').touched) &&
              formPensaoAlimenticia.get('pensionista').status === 'INVALID'
                ? 'is-invalid'
                : ''
            "
          >
            <option *ngIf="listaPensionista && listaPensionista.length > 0" value="">
              Selecione...
            </option>
            <option *ngIf="listaPensionista && listaPensionista.length === 0" value="">
              Não possui pensionista
            </option>
            <option *ngFor="let pensionista of listaPensionista" [value]="pensionista.CPF">
              {{ pensionista.NomePensionista }}
            </option>
          </select>
        </div>

        <div
          *ngIf="
            formPensaoAlimenticia.get('matricula').value !== '' &&
            formPensaoAlimenticia.get('nomeFuncionario').value !== ''
          "
          class="col-md-auto col-sm-12 form-group d-flex align-items-end"
        >
          <button type="button" (click)="novoPensionista()" class="btn btn-primary col-12">
            <i class="fa fa-plus mr-2"></i> Novo pensionista
          </button>
        </div>
        <div
          *ngIf="
            formPensaoAlimenticia.get('matricula').value !== '' &&
            formPensaoAlimenticia.get('nomeFuncionario').value !== ''
          "
          class="form-group col-md-auto col-sm-12"
        >
          <button type="button" (click)="limparCampos()" class="btn btn-warning col-12 px-2">
            <i class="fa fas fa-eraser mr-2"></i> Limpar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--Informações Pensionista-->
  <div
    *ngIf="
      formPensaoAlimenticia.get('matricula').value !== '' &&
      formPensaoAlimenticia.get('nomeFuncionario').value !== ''
    "
    class="card"
  >
    <div class="card-header">
      <div class="col-12">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <h3 class="card-title">Informações do Pensionista</h3>
        </div>
      </div>
    </div>

    <div class="card-body wizard">
      <div class="steps clearfix pb-3">
        <ul role="tablist">
          <li
            role="tab"
            [ngClass]="{ current: etapa === 0, done: etapa > 0 }"
            class="first"
            aria-disabled="false"
            aria-selected="true"
          >
            <a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0" class=""
              ><span class="current-info audible">current etapa: </span
              ><span [ngClass]="{ pointer: etapa > 0 }" class="number">1</span> Dados Pessoais</a
            >
          </li>
          <li
            role="tab"
            [ngClass]="{
              current: etapa === 1,
              disabled: etapa < 1,
              done: etapa > 1
            }"
            aria-disabled="true"
          >
            <a id="steps-uid-0-t-1" aria-controls="steps-uid-0-p-1"
              ><span [ngClass]="{ pointer: etapa > 1 }" class="number">2</span>
              Dados Pagamento
            </a>
          </li>
        </ul>
      </div>

      <!--Dados Pessoais-->

      <div
        *ngIf="etapa === 0"
        formGroupName="informacaoBasica"
        [style.display]="true ? 'block' : 'none'"
        id="steps-uid-0-p-0"
        role="tabpanel"
        aria-labelledby="steps-uid-0-h-0"
        class="body current"
        aria-hidden="false"
      >
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h5 class="">Dados Pessoais</h5>
        </div>
        <div class="row align-items-start pessoal">
          <div *ngIf="pensionistaSelecionado" class="col-md-1 form-group">
            <label class="font-weight-semibold">Código</label>
            <input
              class="form-control"
              type="text"
              formControlName="codigo"
              placeholder="Cód."
              [ngClass]="
                (temErro || formPensaoAlimenticia.get('informacaoBasica').get('codigo').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('codigo').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                temErro &&
                formPensaoAlimenticia.get('informacaoBasica').get('codigo').hasError('required')
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div
            [ngClass]="{
              'col-md-8': pensionistaSelecionado,
              'col-md-9': !pensionistaSelecionado
            }"
            class="form-group"
          >
            <label class="font-weight-semibold">Nome do Pensionista</label>
            <input
              class="form-control"
              type="text"
              formControlName="nomePensionista"
              placeholder="Nome do Pensionista"
              maxlength="60"
              (keypress)="somenteLetras($event)"
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('informacaoBasica').get('nomePensionista').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('nomePensionista').status ===
                  'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('nomePensionista')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('nomePensionista')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('nomePensionista').touched)
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Tipo de Pensão</label>
            <select
              class="form-control"
              type="text"
              formControlName="tipoPensao"
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('informacaoBasica').get('tipoPensao').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('tipoPensao').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let tipo of listaTipoPensao" value="{{ tipo.Codigo }}">
                {{ tipo.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('tipoPensao')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('tipoPensao')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('tipoPensao').touched)
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">CPF</label>
            <input
              class="form-control"
              type="text"
              formControlName="cpf"
              placeholder="CPF"
              mask="000.000.000-00"
              (keyup)="verificaCPFExiste()"
              [ngClass]="
                (temErro || formPensaoAlimenticia.get('informacaoBasica').get('cpf').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('cpf').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia.get('informacaoBasica').get('cpf').hasError('required')) ||
                (formPensaoAlimenticia.get('informacaoBasica').get('cpf').hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('cpf').touched)
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Identidade</label>
            <input
              class="form-control"
              type="text"
              (input)="removerAcentos($event)"
              formControlName="identidade"
              placeholder="Identidade"
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('informacaoBasica').get('identidade').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('identidade').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('identidade')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('identidade')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('identidade').touched)
              "
            >
              Campo obrigatório
            </span>
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('identidade')
                    .hasError('pattern')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('identidade')
                  .hasError('pattern') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('identidade').touched)
              "
            >
              Caracteres especiais não são permitidos
            </span>
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Órgão Expedidor</label>
            <input
              class="form-control expedidor"
              type="text"
              formControlName="orgaoExpedidor"
              maxLength="5"
              pattern="[A-Za-z]+$"
              placeholder="Órgão expedidor"
              (keypress)="somenteLetras($event)"
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('informacaoBasica').get('orgaoExpedidor').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('orgaoExpedidor').status ===
                  'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('orgaoExpedidor')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('orgaoExpedidor')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('orgaoExpedidor').touched)
              "
            >
              Campo obrigatório
            </span>
            <!-- <span class="form-text text-danger"
              *ngIf="temErro || formPensaoAlimenticia.get('informacaoBasica').get('orgaoExpedidor').hasError('pattern') && formPensaoAlimenticia.get('informacaoBasica').get('orgaoExpedidor').touched">
              Campo só permite letras
            </span> -->
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">UF</label>
            <select
              class="form-control"
              type="text"
              formControlName="ufIdentidade"
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('informacaoBasica').get('ufIdentidade').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('ufIdentidade').status ===
                  'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            >
              <option value="" disabled selected hidden>UF</option>
              <option *ngFor="let uf of listaUf" value="{{ uf.SiglaUf }}">
                {{ uf.SiglaUf }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('ufIdentidade')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('ufIdentidade')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('ufIdentidade').touched)
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Data de Nascimento</label>
            <input
              class="form-control"
              formControlName="dataNascimento"
              type="date"
              min="1900-01-01"
              max="9999-12-31"
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('informacaoBasica').get('dataNascimento').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('dataNascimento').status ===
                  'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('dataNascimento')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('dataNascimento')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('dataNascimento').touched)
              "
            >
              Campo obrigatório
            </span>
            <span
              class="form-text text-danger"
              *ngIf="
                formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('dataNascimento')
                  .hasError('maxDate') ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('dataNascimento')
                  .hasError('min') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('dataNascimento').touched)
              "
            >
              Data inválida
            </span>
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Telefone </label>
            <input
              class="form-control"
              type="text"
              formControlName="telefone"
              placeholder="Telefone"
              mask
              telefoneMask
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('informacaoBasica').get('telefone').touched) &&
                formPensaoAlimenticia.get('informacaoBasica').get('telefone').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('telefone')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('telefone')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('telefone').touched)
              "
            >
              Campo obrigatório
            </span>
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('informacaoBasica')
                    .get('telefone')
                    .hasError('pattern')) ||
                (formPensaoAlimenticia
                  .get('informacaoBasica')
                  .get('telefone')
                  .hasError('pattern') &&
                  formPensaoAlimenticia.get('informacaoBasica').get('telefone').touched)
              "
            >
              Telefone inválido
            </span>
          </div>
          <div class="row col-md-12">
            <div class="col-md-3 form-group" *ngIf="pensionistaSelecionado">
              <label for="">&nbsp;</label>
              <button
                [ngClass]="{
                  'btn-outline-primary': pensionistaSelecionado.Obs === 'N',
                  'btn-primary': pensionistaSelecionado.Obs === 'S'
                }"
                type="button"
                class="btn form-control"
                data-toggle="modal"
                data-target="#observacoesPAlimenticiaModal"
                (click)="modalObservacoes.abrirModal(pensionistaSelecionado)"
              >
                Observações
              </button>
            </div>
            <div class="col-md-7">
              <app-anexo
                [label]="'Anexos da Pensão'"
                [pagina]="'012'"
                [matricula]="formPensaoAlimenticia.get('matricula').value"
                [enviarSeparadamente]="true"
                [compChave]="formPensaoAlimenticia.get('informacaoBasica').get('cpf').value"
                (listaAnexoCadastro)="recebeAnexos($event)"
                [permiteExclusao]="
                  this.formPensaoAlimenticia.get('pensionista').value === '' && !cpfExiste
                "
              >
              </app-anexo>
              <span class="form-text" *ngIf="!anexosCarregados"
                >Carregando anexos <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
              ></span>
            </div>
          </div>

          <fieldset class="col-md-12 mt-2">
            <legend>
              <h5>Endereço/Contato <small>(opcional)</small></h5>
            </legend>
            <div class="row">
              <div class="col-md-2 form-group">
                <div class="d-flex justify-content-between">
                  <label>CEP</label>
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    class="btn-link text-primary"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Não sabe seu CEP?"
                  >
                    <i class="fa fa-envelope-open-o"></i>
                  </a>
                </div>
                <input
                  class="form-control"
                  (keyup)="onCepChange()"
                  mask="00000-000"
                  type="text"
                  formControlName="cep"
                  placeholder="CEP"
                  [ngClass]="
                    (temErro || formPensaoAlimenticia.get('informacaoBasica').get('cep').touched) &&
                    formPensaoAlimenticia.get('informacaoBasica').get('cep').status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />

                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia.get('informacaoBasica').get('cep').hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
                <span class="form-text" *ngIf="loadingCep">
                  Carregando
                  <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
                </span>
              </div>

              <div class="col-md-5 form-group">
                <label class="font-weight-semibold">Logradouro</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="logradouro"
                  placeholder="Logradouro"
                  maxlength="40"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('informacaoBasica').get('logradouro').touched) &&
                    formPensaoAlimenticia.get('informacaoBasica').get('logradouro').status ===
                      'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('informacaoBasica')
                      .get('logradouro')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div class="col-md-2 form-group">
                <label class="font-weight-semibold">Número</label>
                <input
                  class="form-control"
                  type="text"
                  mask="0*"
                  formControlName="numeroLogradouro"
                  placeholder="Número"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('informacaoBasica').get('numeroLogradouro')
                        .touched) &&
                    formPensaoAlimenticia.get('informacaoBasica').get('numeroLogradouro').status ===
                      'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('informacaoBasica')
                      .get('numeroLogradouro')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div class="col-md-3 form-group">
                <label class="font-weight-semibold">
                  Complemento
                  <small>(opcional)</small>
                </label>

                <input
                  class="form-control"
                  type="text"
                  formControlName="complementoEndereco"
                  placeholder="Complemento"
                  maxlength="20"
                  (input)="toUppercase($event)"
                  (keypress)="removerAcentos($event)"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('informacaoBasica').get('complementoEndereco')
                        .touched) &&
                    formPensaoAlimenticia.get('informacaoBasica').get('complementoEndereco')
                      .status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('informacaoBasica')
                      .get('complementoEndereco')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('informacaoBasica')
                        .get('complementoEndereco')
                        .hasError('pattern')) ||
                    (formPensaoAlimenticia
                      .get('informacaoBasica')
                      .get('complementoEndereco')
                      .hasError('pattern') &&
                      formPensaoAlimenticia.get('informacaoBasica').get('complementoEndereco')
                        .touched)
                  "
                >
                  Caracteres especiais não são permitidos
                </span>
              </div>

              <div class="col-md-3 form-group">
                <label class="font-weight-semibold">Bairro</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="bairro"
                  placeholder="Bairro"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('informacaoBasica').get('bairro').touched) &&
                    formPensaoAlimenticia.get('informacaoBasica').get('bairro').status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia.get('informacaoBasica').get('bairro').hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div class="col-md-2 form-group">
                <label class="font-weight-semibold">UF</label>
                <select
                  class="form-control"
                  type="text"
                  formControlName="ufEndereco"
                  placeholder="UF"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('informacaoBasica').get('ufEndereco').touched) &&
                    formPensaoAlimenticia.get('informacaoBasica').get('ufEndereco').status ===
                      'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                >
                  <option value="" disabled selected hidden>UF</option>
                  <option *ngFor="let uf of listaUf" value="{{ uf.SiglaUf }}">
                    {{ uf.SiglaUf }}
                  </option>
                </select>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('informacaoBasica')
                      .get('ufEndereco')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div class="col-md-3 form-group">
                <label class="font-weight-semibold">Cidade</label>
                <select
                  class="form-control"
                  type="text"
                  formControlName="cidade"
                  placeholder="Cidade"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('informacaoBasica').get('cidade').touched) &&
                    formPensaoAlimenticia.get('informacaoBasica').get('cidade').status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let municipio of listaMunicipio" value="{{ municipio.Nome }}">
                    {{ municipio.Nome }}
                  </option>
                </select>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia.get('informacaoBasica').get('cidade').hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <scroll-top></scroll-top>

      <div
        *ngIf="etapa === 1"
        formGroupName="dadosPagamento"
        [style.display]="etapa === 1 ? 'block' : 'none'"
        id="steps-uid-0-p-1"
        role="tabpanel"
        aria-labelledby="steps-uid-0-h-1"
        class="body"
        aria-hidden="true"
      >
        <h4 class="mb-4">Dados Pagamento</h4>
        <div class="col-md-3 form-group" *ngIf="pensionistaSelecionado">
          <label for="">&nbsp;</label>
        </div>

        <!--Dados Pagamento-->
        <div class="row">
          <div class="col col-md-4 form-group">
            <label class="font-weight-semibold">Data Inicial</label>
            <input
              class="form-control"
              type="date"
              formControlName="dataInicialPgto"
              max="9999-12-31"
              (keyup)="
                verificaData(
                  formPensaoAlimenticia.get('dadosPagamento').get('dataInicialPgto'),
                  formPensaoAlimenticia.get('dadosPagamento').get('dataFinalPgto')
                )
              "
              [ngClass]="
                ((temErro &&
                  formPensaoAlimenticia.get('dadosPagamento').get('dataInicialPgto').status ===
                    'INVALID') ||
                  formPensaoAlimenticia.get('dadosPagamento').get('dataInicialPgto').touched) &&
                formPensaoAlimenticia.get('dadosPagamento').get('dataInicialPgto').status ===
                  'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('dadosPagamento')
                    .get('dataInicialPgto')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('dadosPagamento')
                  .get('dataInicialPgto')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('dadosPagamento').get('dataInicialPgto').touched)
              "
            >
              Campo obrigatório
            </span>
            <span
              class="form-text text-danger"
              *ngIf="
                formPensaoAlimenticia
                  .get('dadosPagamento')
                  .get('dataInicialPgto')
                  .hasError('min') ||
                (formPensaoAlimenticia
                  .get('dadosPagamento')
                  .get('dataInicialPgto')
                  .hasError('max') &&
                  formPensaoAlimenticia.get('dadosPagamento').get('dataInicialPgto').touched)
              "
            >
              Data inválida
            </span>
          </div>
          <div class="col col-md-4 form-group">
            <label class="font-weight-semibold">Data Final <small>(opcional)</small></label>
            <input
              class="form-control"
              type="date"
              formControlName="dataFinalPgto"
              max="9999-12-31"
              (keyup)="
                verificaData(
                  formPensaoAlimenticia.get('dadosPagamento').get('dataInicialPgto'),
                  formPensaoAlimenticia.get('dadosPagamento').get('dataFinalPgto')
                )
              "
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('dadosPagamento').get('dataFinalPgto').touched) &&
                formPensaoAlimenticia.get('dadosPagamento').get('dataFinalPgto').status ===
                  'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formPensaoAlimenticia.get('dadosPagamento').get('dataFinalPgto').hasError('min') ||
                (formPensaoAlimenticia.get('dadosPagamento').get('dataFinalPgto').hasError('max') &&
                  formPensaoAlimenticia.get('dadosPagamento').get('dataFinalPgto').touched)
              "
            >
              Data inválida
            </span>
          </div>
          <div class="col-md-4 form-group" *ngIf="clientePublico">
            <label class="font-weight-semibold">Número do Processo</label>
            <input
              class="form-control"
              type="text"
              formControlName="processoPgto"
              placeholder="Número do Processo"
              (input)="toUppercase($event)"
              (keyup)="removerAcentos($event)"
              maxlength="11"
              [ngClass]="
                (temErro ||
                  formPensaoAlimenticia.get('dadosPagamento').get('processoPgto').touched) &&
                formPensaoAlimenticia.get('dadosPagamento').get('processoPgto').status === 'INVALID'
                  ? 'is-invalid'
                  : ''
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('dadosPagamento')
                    .get('processoPgto')
                    .hasError('required')) ||
                (formPensaoAlimenticia
                  .get('dadosPagamento')
                  .get('processoPgto')
                  .hasError('required') &&
                  formPensaoAlimenticia.get('dadosPagamento').get('processoPgto').touched)
              "
            >
              Campo obrigatório
            </span>
            <span
              class="form-text text-danger"
              *ngIf="
                (temErro &&
                  formPensaoAlimenticia
                    .get('dadosPagamento')
                    .get('processoPgto')
                    .hasError('pattern')) ||
                (formPensaoAlimenticia
                  .get('dadosPagamento')
                  .get('processoPgto')
                  .hasError('pattern') &&
                  formPensaoAlimenticia.get('dadosPagamento').get('processoPgto').touched)
              "
            >
              Caracteres especiais não são permitidos
            </span>
          </div>

          <fieldset class="col-md-12 mt-2">
            <legend>
              <h5>Conta Corrente para Crédito</h5>
            </legend>
            <div class="row">
              <div class="col-md-12 form-group">
                <label class="font-weight-semibold">Quem Recebe?</label><br />
                <input
                  id="titular"
                  (change)="onRadioPgtoChange()"
                  type="radio"
                  formControlName="radioPgto"
                  value="titular"
                />
                &nbsp; <label for="titular">Titular</label> &nbsp; &nbsp;
                <input
                  id="representanteLegal"
                  (click)="onRadioPgtoChange()"
                  (change)="onRadioPgtoChange()"
                  type="radio"
                  formControlName="radioPgto"
                  value="representanteLegal"
                />&nbsp;
                <label for="representanteLegal">Representante Legal</label>
                <app-representante-legal
                  (listaRepresentantes)="buscarListaRepresentantes($event)"
                  (selecionouRepresentante)="selecionarRepresentante($event)"
                ></app-representante-legal>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('radioPgto')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <!--Campos Titular-->
              <div
                class="col-md-3 form-group"
                *ngIf="formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'titular'"
              >
                <label class="font-weight-semibold">Banco</label>
                <select
                  class="form-control"
                  type="text"
                  (change)="buscarAgencias($event)"
                  formControlName="banco"
                  [ngClass]="
                    (temErro || formPensaoAlimenticia.get('dadosPagamento').get('banco').touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('banco').status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let banco of listaBanco" value="{{ banco.Codigo }}">
                    {{ banco.Nome }}
                  </option>
                </select>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('banco')
                        .hasError('required')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('banco')
                      .hasError('required') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('banco').touched)
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div
                class="col-md-3 form-group"
                *ngIf="formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'titular'"
              >
                <label class="font-weight-semibold">Operação</label>
                <select
                  class="form-control"
                  type="text"
                  formControlName="operacao"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('dadosPagamento').get('operacao').touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('operacao').status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let operacao of listaOperacao" value="{{ operacao.Codigo }}">
                    {{ operacao.Codigo }} - {{ operacao.Descricao }}
                  </option>
                </select>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('operacao')
                        .hasError('required')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('operacao')
                      .hasError('required') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('operacao').touched)
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div
                class="col-md-2 form-group"
                *ngIf="formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'titular'"
              >
                <label class="font-weight-semibold">Agência</label>
                <select
                  class="form-control"
                  formControlName="agencia"
                  [ngClass]="
                    (temErro &&
                      formPensaoAlimenticia.get('dadosPagamento').get('agencia').invalid) ||
                    (formPensaoAlimenticia.get('dadosPagamento').get('agencia').touched &&
                      formPensaoAlimenticia.get('dadosPagamento').get('agencia').invalid)
                      ? 'is-invalid'
                      : null
                  "
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let agencia of listaAgencias" value="{{ agencia['CodAgencia'] }}">
                    {{ agencia['CodAgencia'] }} ({{ agencia['NomeAgencia'] }})
                  </option>
                </select>

                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('agencia')
                        .hasError('required')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('agencia')
                      .hasError('required') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('agencia').touched)
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div
                class="col-md-4 form-group"
                *ngIf="formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'titular'"
              >
                <label class="font-weight-semibold">Número da Conta</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="conta"
                  placeholder="Número da Conta"
                  mask="0*"
                  maxlength="13"
                  [ngClass]="
                    temErro &&
                    formPensaoAlimenticia.get('dadosPagamento').get('conta').touched &&
                    formPensaoAlimenticia.get('dadosPagamento').get('conta').status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('conta')
                        .hasError('required')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('conta')
                      .hasError('required') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('conta').touched)
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <!--Campos Representante Legal-->

              <div
                class="col-md-1 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Código</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="codigoRepresentanteLegal"
                  placeholder="Cod."
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('dadosPagamento').get('codigoRepresentanteLegal')
                        .touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('codigoRepresentanteLegal')
                      .status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('codigoRepresentanteLegal')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>
              <div
                class="col-md-1 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Tipo</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="tipoRepresentanteLegal"
                  placeholder="Tipo"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('dadosPagamento').get('tipoRepresentanteLegal')
                        .touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('tipoRepresentanteLegal')
                      .status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('tipoRepresentanteLegal')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>
              <div
                class="col-md-10 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Nome do Representante Legal</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="nomeRepresentanteLegal"
                  placeholder="Nome do Representante Legal"
                  maxlength="80"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('dadosPagamento').get('nomeRepresentanteLegal')
                        .touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('nomeRepresentanteLegal')
                      .status === 'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('nomeRepresentanteLegal')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>
              <div
                class="col-md-3 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Banco</label>
                <select
                  class="form-control"
                  type="text"
                  (change)="buscarOperacao()"
                  formControlName="bancoRepLegal"
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let banco of listaBanco" value="{{ banco.Codigo }}">
                    {{ banco.Nome }}
                  </option>
                </select>
              </div>

              <div
                class="col-md-3 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Operação</label>
                <select class="form-control" type="text" formControlName="operacaoRepLegal">
                  <option value="">Selecione...</option>
                  <option
                    *ngFor="let operacao of listaOperacao"
                    value="{{ operacao.Codigo.trim() }}"
                  >
                    {{ operacao.Codigo }} - {{ operacao.Descricao }}
                  </option>
                </select>
              </div>

              <div
                class="col-md-3 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Agência</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="agenciaRepLegal"
                  placeholder="Agência"
                  mask="0*"
                  maxlength="5"
                />
              </div>

              <div
                class="col-md-3 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Número da Conta</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="contaRepLegal"
                  placeholder="Número da Conta"
                />
              </div>
              <div
                class="col-md-4 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Data Início</label>
                <input
                  class="form-control"
                  type="date"
                  formControlName="dataInicioRepLegal"
                  min="1900-01-01"
                  max="9999-12-31"
                  [ngClass]="
                    (formPensaoAlimenticia.get('dadosPagamento').get('dataInicioRepLegal')
                      .touched &&
                      formPensaoAlimenticia.get('dadosPagamento').get('dataInicioRepLegal')
                        .status === 'INVALID') ||
                    (formPensaoAlimenticia.get('dadosPagamento').get('dataInicioRepLegal')
                      .status === 'INVALID' &&
                      temErro)
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('dataInicioRepLegal')
                      .hasError('required') &&
                    formPensaoAlimenticia.get('dadosPagamento').get('dataInicioRepLegal').touched
                  "
                >
                  Campo obrigatório
                </span>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('dataInicioRepLegal')
                      .hasError('min') ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('dataInicioRepLegal')
                      .hasError('max') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('dataInicioRepLegal').touched)
                  "
                >
                  Data inválida
                </span>
              </div>
              <div
                class="col-md-4 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <label class="font-weight-semibold">Data Fim</label>
                <input
                  class="form-control"
                  formControlName="dataFimRepLegal"
                  type="date"
                  min="1900-01-01"
                  max="9999-12-31"
                  [ngClass]="
                    (formPensaoAlimenticia.get('dadosPagamento').get('dataFimRepLegal').touched &&
                      formPensaoAlimenticia.get('dadosPagamento').get('dataFimRepLegal').status ===
                        'INVALID') ||
                    (formPensaoAlimenticia.get('dadosPagamento').get('dataFimRepLegal').status ===
                      'INVALID' &&
                      temErro)
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('dataFimRepLegal')
                      .hasError('required') &&
                    formPensaoAlimenticia.get('dadosPagamento').get('dataFimRepLegal').touched
                  "
                >
                  Campo obrigatório
                </span>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('dataFimRepLegal')
                      .hasError('min') ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('dataFimRepLegal')
                      .hasError('max') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('dataFimRepLegal').touched)
                  "
                >
                  Data inválida
                </span>
              </div>
              <div
                class="col-md-4 form-group"
                *ngIf="
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal' &&
                  clientePublico
                "
              >
                <label class="font-weight-semibold">Número do Processo</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="processoRepLegal"
                  placeholder="Número do Processo"
                  (input)="toUppercase($event)"
                  (keyup)="removerAcentos($event)"
                  maxlength="11"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('dadosPagamento').get('processoRepLegal')
                        .touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('processoRepLegal').status ===
                      'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('processoRepLegal')
                        .hasError('required')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('processoRepLegal')
                      .hasError('required') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('processoRepLegal').touched)
                  "
                >
                  Campo obrigatório
                </span>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('processoRepLegal')
                        .hasError('pattern')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('processoRepLegal')
                      .hasError('pattern') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('processoRepLegal').touched)
                  "
                >
                  Caracteres especiais não são permitidos
                </span>
              </div>

              <div
                class="col-12"
                *ngIf="
                  formPensaoAlimenticia.get('dadosPagamento').get('nomeRepresentanteLegal').value !=
                    '' &&
                  formPensaoAlimenticia.value.dadosPagamento.radioPgto === 'representanteLegal'
                "
              >
                <app-anexo
                  [label]="'Anexos do Representante Legal'"
                  [pagina]="'012'"
                  [matricula]="formPensaoAlimenticia.get('matricula').value"
                  [nomeUsuario]="
                    formPensaoAlimenticia.get('dadosPagamento').get('nomeRepresentanteLegal').value
                  "
                  [enviarSeparadamente]="true"
                  [compChave]="
                    formPensaoAlimenticia.get('dadosPagamento').get('codigoRepresentanteLegal')
                      .value
                  "
                >
                </app-anexo>
              </div>
            </div>
          </fieldset>

          <!--Informações de Pagamento (determinação do processo)-->

          <fieldset class="col-md-12 mt-2">
            <legend>
              <h5>Informações do Pagamento (Determinação do Processo)</h5>
            </legend>
            <div class="row col-md-12">
              <div class="col-md-3 form-group">
                <label class="font-weight-semibold">Tipo de Cálculo</label>
                <select
                  class="form-control"
                  type="text"
                  formControlName="tipoCalculo"
                  (change)="onTipoCalculoChange()"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('dadosPagamento').get('tipoCalculo').touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('tipoCalculo').status ===
                      'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                >
                  <option value="">Selecione...</option>
                  <option
                    *ngFor="let tipoCalculo of listaTipoCalculo"
                    value="{{ tipoCalculo.Codigo }}"
                  >
                    {{ tipoCalculo.Descricao }}
                  </option>
                </select>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('tipoCalculo')
                        .hasError('required')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('tipoCalculo')
                      .hasError('required') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('tipoCalculo').touched)
                  "
                >
                  Campo obrigatório
                </span>
              </div>
              <div
                *ngIf="
                  this.formPensaoAlimenticia.get('dadosPagamento').get('tipoCalculo').value !== 'I'
                "
                class="pr-2 pl-2 form-group"
              >
                <label class="font-weight-semibold">Porcentagem</label>
                <!--Percentual-->
                <input
                  class="form-control"
                  mask="0*"
                  suffix=" %"
                  placeholder="Porcentagem"
                  type="text"
                  formControlName="percentual"
                  [ngClass]="
                    (temErro ||
                      formPensaoAlimenticia.get('dadosPagamento').get('percentual').touched) &&
                    formPensaoAlimenticia.get('dadosPagamento').get('percentual').status ===
                      'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('percentual')
                        .hasError('required')) ||
                    (formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('percentual')
                      .hasError('required') &&
                      formPensaoAlimenticia.get('dadosPagamento').get('percentual').touched)
                  "
                >
                  Campo obrigatório
                </span>
              </div>
              <ng-container
                *ngIf="
                  this.formPensaoAlimenticia.get('dadosPagamento').get('tipoCalculo').value === 'I'
                "
              >
                <div class="pl-2 pr-2 form-group">
                  <label class="font-weight-semibold">Quantidade salário Família</label>
                  <!--Quantidade Salário Família-->
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Quantidade"
                    formControlName="qtdSalarioFamilia"
                    [ngClass]="
                      (temErro ||
                        formPensaoAlimenticia.get('dadosPagamento').get('qtdSalarioFamilia')
                          .touched) &&
                      formPensaoAlimenticia.get('dadosPagamento').get('qtdSalarioFamilia')
                        .status === 'INVALID'
                        ? 'is-invalid'
                        : ''
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formPensaoAlimenticia.get('dadosPagamento').get('qtdSalarioFamilia')
                          .invalid) ||
                      (formPensaoAlimenticia.get('dadosPagamento').get('qtdSalarioFamilia')
                        .invalid &&
                        formPensaoAlimenticia.get('dadosPagamento').get('qtdSalarioFamilia')
                          .touched)
                    "
                  >
                    {{
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('qtdSalarioFamilia')
                        .hasError('required')
                        ? 'Campo obrigatório'
                        : 'O valor têm que ser maior que zero.'
                    }}
                  </span>
                </div>
                <div class="pl-2 pr-2 form-group">
                  <label class="font-weight-semibold">Valor</label>
                  <!--Valor-->
                  <input
                    class="form-control"
                    type=""
                    placeholder="Valor"
                    currencyMask
                    [formControlName]="'valor'"
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                    [ngClass]="
                      (temErro ||
                        formPensaoAlimenticia.get('dadosPagamento').get('valor').touched) &&
                      formPensaoAlimenticia.get('dadosPagamento').get('valor').status === 'INVALID'
                        ? 'is-invalid'
                        : ''
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formPensaoAlimenticia.get('dadosPagamento').get('valor').invalid) ||
                      (formPensaoAlimenticia.get('dadosPagamento').get('valor').invalid &&
                        formPensaoAlimenticia.get('dadosPagamento').get('valor').touched)
                    "
                  >
                    {{
                      formPensaoAlimenticia.get('dadosPagamento').get('valor').hasError('required')
                        ? 'Campo obrigatório'
                        : 'O valor têm que ser maior que zero.'
                    }}
                  </span>
                </div>
              </ng-container>
              <div class="col-md-auto form-group">
                <label class="font-weight-semibold">Verba de Desconto</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="verbaDesconto"
                  [ngClass]="
                    temErro &&
                    formPensaoAlimenticia.get('dadosPagamento').get('verbaDesconto').status ===
                      'INVALID'
                      ? 'is-invalid'
                      : ''
                  "
                />
                <span
                  class="form-text text-danger"
                  *ngIf="
                    temErro &&
                    formPensaoAlimenticia
                      .get('dadosPagamento')
                      .get('verbaDesconto')
                      .hasError('required')
                  "
                >
                  Campo obrigatório
                </span>
              </div>

              <div class="col-md-12 form-group d-flex align-items-center">
                <button
                  data-toggle="modal"
                  data-target="#verbasModal"
                  type="button"
                  class="btn btn-primary"
                  (click)="limparVerbas()"
                >
                  Base de cálculo
                </button>
              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="pl-2 pr-2 form-group">
                  <label class="font-weight-semibold">Recebe rescisão? </label><br />
                  <input id="simRescisao" type="radio" formControlName="radioRescisao" value="S" />
                  &nbsp; <label for="simRescisao">Sim</label> &nbsp; &nbsp;
                  <input
                    id="naoRescisao"
                    type="radio"
                    formControlName="radioRescisao"
                    value="N"
                  />&nbsp;
                  <label for="naoRescisao">Não</label>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('radioRescisao')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="pl-2 pr-2 form-group">
                  <label class="font-weight-semibold">Recebe 13º Salário? </label>
                  <br />
                  <input id="sim13" type="radio" formControlName="radioDecimoTerceiro" value="S" />
                  &nbsp; <label for="sim13">Sim</label> &nbsp; &nbsp;
                  <input
                    id="nao13"
                    type="radio"
                    formControlName="radioDecimoTerceiro"
                    value="N"
                  />&nbsp;
                  <label for="nao13">Não</label>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('radioDecimoTerceiro')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="pl-2 pr-2 form-group">
                  <label class="font-weight-semibold">Recebe Férias?</label><br />
                  <input id="simFerias" type="radio" formControlName="radioFerias" value="S" />
                  &nbsp; <label for="simFerias">Sim</label> &nbsp; &nbsp;
                  <input
                    id="naoFerias"
                    type="radio"
                    formControlName="radioFerias"
                    value="N"
                  />&nbsp;
                  <label for="naoFerias">Não</label>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('radioFerias')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>

                <div
                  class="col-md-2 form-group"
                  *ngIf="formPensaoAlimenticia.value.radioRescisao === 'S'"
                >
                  <div class="mt-1">
                    <label class="font-weight-semibold mt-1"
                      >%TRCT
                      <a
                        data-trigger="hover"
                        data-toggle="popover"
                        data-placement="right"
                        title="Termo de rescisão do contrato de trabalho"
                      >
                        <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                      </a>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="trct"
                      placeholder="TRCT"
                      [ngClass]="
                        (temErro ||
                          formPensaoAlimenticia.get('dadosPagamento').get('trct').touched) &&
                        formPensaoAlimenticia.get('dadosPagamento').get('trct').status === 'INVALID'
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        temErro &&
                        formPensaoAlimenticia.get('dadosPagamento').get('trct').hasError('required')
                      "
                    >
                      Campo obrigatório
                    </span>
                  </div>
                </div>
                <div
                  class="col-md-2 form-group"
                  *ngIf="formPensaoAlimenticia.value.radioRescisao === 'S'"
                >
                  <div class="mt-1">
                    <label class="font-weight-semibold mt-1"
                      >%FGTS
                      <a
                        data-trigger="hover"
                        data-toggle="popover"
                        data-placement="right"
                        title="Fundo de Garantia do Tempo de Serviço"
                      >
                        <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                      </a>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="fgts"
                      placeholder="FGTS"
                      [ngClass]="
                        (temErro ||
                          formPensaoAlimenticia.get('dadosPagamento').get('fgts').touched) &&
                        formPensaoAlimenticia.get('dadosPagamento').get('fgts').status === 'INVALID'
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        temErro &&
                        formPensaoAlimenticia.get('dadosPagamento').get('fgts').hasError('required')
                      "
                    >
                      Campo obrigatório
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="card-footer wizard">
      <div class="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li *ngIf="etapa > 0 || etapa > 1" class="disabled" aria-disabled="true">
            <button type="button" class="btn btn-primary" role="menuitem" (click)="voltarEtapa()">
              <i class="icon-arrow-left13 mr-2"></i> Anterior
            </button>
          </li>

          <li *ngIf="etapa < 1" aria-hidden="false" aria-disabled="false">
            <button
              type="button"
              (click)="anexosCarregados ? submitPensao() : ''"
              class="btn btn-primary"
              role="menuitem"
            >
              {{ anexosCarregados ? 'Próximo' : '' }}
              <i
                class="ml-2"
                [ngClass]="anexosCarregados ? 'icon-arrow-right14' : 'fa fa-refresh fa-spin'"
              ></i>
            </button>
          </li>
          <li *ngIf="etapa === 1" aria-hidden="true">
            <button type="button" (click)="submitPensao()" class="btn btn-primary" role="menuitem">
              Submeter <i class="icon-arrow-right14 ml-2"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>

<div
  class="modal fade"
  id="verbasModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="verbasModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="verbasModalLabel">Remover verbas da base</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="text-center alert-warning p-2 m-3 d-flex flex-column align-items-center rounded">
        <i class="fa fa-exclamation-circle fa-2x my-2"></i>
        <p class="text-center">
          Por favor, lembre-se de submeter o formulário para que suas alterações entrem em vigor.
        </p>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <div class="input-group">
            <input
              class="form-control"
              [(ngModel)]="verbaNome"
              type="text"
              placeholder="Pesquise pelo nome ou código da verba"
              (keyup.enter)="buscarVerbas()"
            />
            <span class="input-group-append">
              <button
                type="button"
                [disabled]="loadingVerbas"
                (click)="buscarVerbas()"
                class="input-group-text bg-blue border-blue"
              >
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </div>
        <div
          *ngIf="loadingVerbas"
          style="min-height: 10vh"
          class="d-flex justify-content-center align-items-center"
        >
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <div *ngIf="mostrarVerbas">
          <div class="d-flex justify-content-between align-items-center mb-3 pb-3 border-bottom">
            <h5 class="mb-0">Listagem de Verbas</h5>
            <div class="d-flex">
              <button
                class="btn btn-sm btn-outline-primary ml-auto"
                (click)="desmarcarTodasVerbas()"
              >
                <i class="fa fa-filter pr-2"></i>
                <span>Limpar filtros</span>
              </button>
              <button
                class="btn btn-sm btn-outline-primary ml-2 mr-auto mr-md-0"
                (click)="marcarTodasVerbas()"
              >
                <i class="fa fa-download pr-2"></i>
                <span>Selecionar Todos</span>
              </button>
            </div>
          </div>
          <div style="max-height: 30vh; overflow: auto">
            <table style="height: 50px" class="table table-striped table-hover mb-0">
              <thead class="bg-teal-400">
                <tr>
                  <th class="text-center"></th>
                  <th class="text-center">Código</th>
                  <th class="text-center">Transação</th>
                  <th class="text-center">Nome</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="listaVerba && listaVerba.length > 0">
                  <tr *ngFor="let verba of listaVerba">
                    <td class="row-md-1" style="text-align: center">
                      <input
                        id="{{ verba.Codigo }}"
                        type="checkbox"
                        [value]="verba.Codigo + '-' + verba.Transacao"
                        (change)="onVerbaCheckChange($event)"
                        [checked]="listaVerbaRemovida(verba.Codigo)"
                      />
                    </td>
                    <td class="row-md-1" style="text-align: center">
                      <label for="{{ verba.Codigo }}">
                        {{ verba.Codigo }}
                      </label>
                    </td>
                    <td class="row-md-1" style="text-align: center">
                      <label for="{{ verba.Transacao }}">
                        {{ verba.Transacao }}
                      </label>
                    </td>
                    <td class="row-md-1" style="text-align: left">
                      <label for="{{ verba.Codigo }}">
                        {{ verba.Descricao }}
                      </label>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
              <ng-container *ngIf="listaVerba.length === 0 && verbaNaBase === false">
                <tr>
                  <td colspan="5" class="text-center">Nenhuma verba encontrada</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="listaVerba.length === 0 && verbaNaBase === true">
                <tr>
                  <td colspan="5" class="text-center">Verba já na base</td>
                </tr>
              </ng-container>
            </table>

            <!-- <div *ngFor="let verba of listaVerba" class="col-md-12 form-group d-flex align-items-center">
            <input id="{{verba.Codigo}}" type="checkbox" value="{{verba.Codigo}}" (change)="onVerbaCheckChange($event)"
              [checked]="listaVerbaRemovida.includes(verba.Codigo)" />
              <label
                for="{{verba.Codigo}}"
                class="font-weight-semibold m-0 pl-1">
                {{verba.Codigo}} - {{verba.Descricao}}
              </label>
          </div> -->
          </div>
        </div>
        <div class="mt-4 ng-star-inserted">
          <div class="d-flex justify-content-between pt-3 pb-1 border-top">
            <h5 class="">Verbas removidas</h5>
          </div>
          <div style="max-height: 30vh; overflow: auto">
            <table style="height: 50px" class="table table-striped table-hover mb-0">
              <thead class="bg-teal-400">
                <tr>
                  <th class="text-center"></th>
                  <th class="text-center">Código</th>
                  <th class="text-center">Transação</th>
                  <th class="text-center">Nome</th>
                  <th class="text-center">Ação</th>
                </tr>
              </thead>
              <tbody id="verbasRemovidas">
                <ng-container
                  *ngIf="
                    formPensaoAlimenticia.get('dadosPagamento').get('verbasRemovidas').value
                      .length > 0;
                    else listaVazia
                  "
                >
                  <tr
                    *ngFor="
                      let verbaRemovida of formPensaoAlimenticia
                        .get('dadosPagamento')
                        .get('verbasRemovidas').value;
                      let i = index
                    "
                  >
                    <td class="row-md-1" style="text-align: center">
                      <input
                        id="removido-{{ verbaRemovida.Codigo }}"
                        type="checkbox"
                        [value]="verbaRemovida.Codigo + '-' + verbaRemovida.Transacao"
                        (change)="onVerbaCheckChange($event)"
                        checked
                      />
                    </td>
                    <td class="row-md-1" style="text-align: center">
                      <label for="{{ verbaRemovida.Codigo }}">
                        {{ verbaRemovida.Codigo }}
                      </label>
                    </td>
                    <td class="row-md-1" style="text-align: center">
                      <input
                        type="text text-center"
                        [(ngModel)]="verbaRemovida.Transacao"
                        maxlength="2"
                        pattern="^(?:SS|\d{2})$ "
                        *ngIf="modoEdicaoIndex === i; else inputTransacao"
                      />
                      <ng-template #inputTransacao>
                        <label for="{{ verbaRemovida.Transacao }}">
                          {{ verbaRemovida.Transacao.toUpperCase() }}
                        </label>
                      </ng-template>
                    </td>
                    <td class="row-md-1" style="text-align: left">
                      <label for="{{ verbaRemovida.Codigo }}">
                        {{ verbaRemovida.Descricao }}
                      </label>
                    </td>
                    <td class="row-md-1" style="text-align: center">
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        [ngClass]="
                          modoEdicaoIndex === i ? 'btn-outline-success' : 'btn-outline-primary'
                        "
                        (click)="modoEdicao(i, verbaRemovida.Transacao)"
                      >
                        <i
                          [ngClass]="modoEdicaoIndex === i ? 'fa fa-check' : 'fa fa-edit'"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
                <ng-template #listaVazia>
                  <tr>
                    <td colspan="5" class="text-center">Nenhuma verba selecionada</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
          <!-- <div
              *ngFor="let verba of formPensaoAlimenticia.get('dadosPagamento').get('verbasRemovidas').value"
              class="col-md-12 form-group d-flex align-items-center"
            >
                <input
                  id="removido{{verba}}"
                  type="checkbox"
                  value="{{verba}}"
                  (change)="onVerbaCheckChange($event)"
                    checked
                />
                <label for="removido{{verba}}" class="font-weight-semibold m-0 pl-1">{{verba}} -
                  {{buscarNomeVerba(verba)}}
                </label>

            </div>
          </div> -->
        </div>

        <div class="modal-footer mt-1"></div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="observacoesPAlimenticiaModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        [colaborador]="{ Matricula: formPensaoAlimenticia.get('matricula').value }"
        tipo="012"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>
